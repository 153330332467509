import { MobileViewProps } from './screen-display.types';
import { useBreakpointValue } from '@chakra-ui/react';

/**
 * Wrapper component for Chakra's [useBreakpointValue()](https://chakra-ui.com/docs/hooks/use-breakpoint-value) hook.
 * Returns children when `useBreakpointValue()` returns `true` for `base` only.
 * */
export const MobileView: React.FC<MobileViewProps> = ({ children }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return <>{isMobile ? children : null}</>;
};
