import { BaseButtonProps } from '../button/button-types';
import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
  forwardRef,
} from '@chakra-ui/react';
import { ThemeTypings } from '../../../../../types/theming.types';
import React from 'react';

interface IconButtonProps extends BaseButtonProps {
  'aria-label': ChakraIconButtonProps['aria-label'];
  icon: React.ReactElement;
  iconBoxSize?: ThemeTypings['sizes'];
  shape?: 'round' | 'square';
}

export const IconButton = forwardRef<IconButtonProps, 'button'>(
  (props, ref) => {
    const {
      disabled,
      icon,
      iconBoxSize,
      shape = 'square',
      size,
      type,
      variant,
      ...rest
    } = props;

    return (
      <ChakraIconButton
        {...rest}
        aria-label={props['aria-label']}
        icon={React.cloneElement(icon, {
          boxSize: iconBoxSize ? iconBoxSize : 4,
        })}
        isDisabled={disabled}
        padding="0"
        ref={ref}
        shape={shape}
        size={size ? size : { base: 'sm', md: 'lg' }}
        type={type}
        variant={variant}
      />
    );
  }
);

IconButton.displayName = 'IconButton';
