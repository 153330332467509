import { AddBrandUserForm } from './add-brand-user-form';
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  MenuItem,
  useDisclosure,
} from '../../../../../index';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const AddBrandUserMobile = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);
  const { t } = useTranslation('add-user');

  return (
    <>
      <MenuItem onClick={onOpen}>
        <Button as="div" w="100%">
          {t('triggerButton.label')}
        </Button>
      </MenuItem>

      <Dialog id="add-user-mobile-view" isOpen={isOpen} onClose={onClose}>
        <DialogHeader />

        <DialogBody>
          <AddBrandUserForm onClose={onClose} firstFieldRef={firstFieldRef} />
        </DialogBody>
      </Dialog>
    </>
  );
};
