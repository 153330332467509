import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { DescriptionDefinitionProps } from './description-list.types';
import React from 'react';

export const Dd: React.FC<DescriptionDefinitionProps> = ({
  children,
  ...rest
}) => {
  const styles = useMultiStyleConfig('DescriptionList');

  return (
    <Box __css={styles.details} as="dd" {...rest}>
      {children}
    </Box>
  );
};
