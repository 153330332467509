import { Dispatch, createContext, useContext, useReducer } from 'react';
import { analyticsMiddleware } from 'middleware';
import { storageKey } from './storage-key';
import AppReducer, { initialState } from 'reducers';

const compose =
  (...funcs) =>
  (x) =>
    funcs.reduceRight((composed, f) => f(composed), x);

export const LeapStorage = window.localStorage;

export const replacer = (_key: string, value: unknown) =>
  typeof value === 'undefined' ? null : value;
const reviver = (_key: string, value: unknown) =>
  value === null ? undefined : value;

// exported for testing
export function initCustomReducerState(
  defaultState: any,
  localStorageState: any
) {
  const mergedState = {
    ...defaultState,
  };
  Object.keys(mergedState).forEach((key) => {
    if (localStorageState && localStorageState[key]) {
      if (typeof mergedState[key] === 'string') {
        mergedState[key] = localStorageState[key];
      } else {
        mergedState[key] = {
          ...mergedState[key],
          ...localStorageState[key],
        };
      }
    }
  });
  return mergedState;
}

export function bootstrapState() {
  const appLocalStorageValue = LeapStorage.getItem(storageKey);
  const localStorageState = appLocalStorageValue
    ? JSON.parse(appLocalStorageValue, reviver)
    : {};
  return initCustomReducerState(initialState, localStorageState);
}

export const CustomReducer = (
  reducer: typeof AppReducer,
  defaultState: typeof initialState,
  middleware: any[]
): [typeof initialState, Dispatch<any>] => {
  const [state, dispatch] = useReducer<typeof AppReducer>(
    reducer,
    defaultState
  );

  const disp = (action: any) => {
    if (['CLEAN', 'LOGOUT'].indexOf(action.type) > -1) {
      LeapStorage.removeItem(storageKey);
    }
    dispatch(action);
  };

  // Middleware configuration
  if (middleware && middleware.length > 0) {
    const middlewareAPI = {
      getState: () => state,
      dispatch: (action) => disp(action),
    };

    const middlewareChain = middleware.map((md) => md(middlewareAPI));
    const dispatchWithMiddleware = compose(...middlewareChain)(disp);

    if (
      process.env.REACT_APP_SERVICE_ENV !== 'production' &&
      typeof window !== 'undefined'
    ) {
      (window as any).__leapState__ = state;
    }

    return [state, dispatchWithMiddleware];
  }

  return [state, dispatch];
};

export const StateContext = createContext<[typeof initialState, Dispatch<any>]>(
  [] as any
);

interface StateProviderProps {
  reducer: any;
  initialState: any;
  restoreFromLocalStorage?: boolean;
  children?: React.ReactNode;
}

export const StateProvider = ({
  children,
  initialState,
  reducer,
}: StateProviderProps) => (
  <StateContext.Provider
    value={CustomReducer(reducer, initialState, [analyticsMiddleware])}
  >
    {children}
  </StateContext.Provider>
);

export const useStateValue = () =>
  useContext<ReturnType<typeof CustomReducer>>(StateContext);
