import { RGBAToRGB, shade } from './utils';
import { Theme } from '@emotion/react';
import {
  ThemeBreakpointKeys,
  ThemeColorsFunction,
  ThemeDefaultKeys,
  ThemeFontSizeKeys,
  ThemePalette,
  ThemePaletteKeys,
} from '../../types/styled';

const DefaultColors: ThemeColorsFunction = (key, alpha = 1, flatten) => {
  const palette: ThemePalette = {
    background: `rgba(255,255,255,${alpha})`, //rgb(255,255,255)
    foreground: `rgba(041,046,061,${alpha})`, //rgb(41,46,61)
    link: `rgba(133,165,255,${alpha})`, //rgb(133,165, 255)
    accent: `rgba(173,198,255,${alpha})`, //rgb(173, 198, 255)
    highlight: `rgba(250,173,020,${alpha})`, //rgb(250,173,20)
    success: `rgba(146,179,005,${alpha})`, //rgb(146,179,5)
    warning: `rgba(250,219,020,${alpha})`, //rgb(250,219,20)
    danger: `rgba(255,120,117,${alpha})`, //rgb(255,120,117)
    darkGray: `rgba(112, 112, 112,${alpha})`, //rgba(112, 112, 112, 1)
    dividerGray: `rgba(217, 217, 217, ${alpha})`,
    helperText: `rgba(041,046,061, ${0.55})`, //rgb(41,46,61)`
  };
  const color = (key && palette[key]) ?? palette.foreground;
  return flatten ? RGBAToRGB(color, [255, 255, 255]) : color;
};

export const baseTheme: Theme = {
  breakpoint: (breakpoint: ThemeBreakpointKeys) => {
    const breakpoints = {
      xs: '576px',
      sm: '768px',
      md: '992px',
      lg: '1200px',
      xl: '1400px',
    };
    return breakpoints[breakpoint];
  },
  button: {
    main: {
      background: {
        default: DefaultColors('link'),
        disabled: DefaultColors('foreground', 0.15),
      },
      border: {
        default: 'transparent',
        disabled: DefaultColors('foreground', 0.25),
      },
      text: {
        default: DefaultColors('background'),
        disabled: DefaultColors('background', 0.25),
      },
      hover: DefaultColors('link', 0.5),
      radius: '2rem',
    },
  },
  colorsDeprecated: (key: ThemePaletteKeys, alpha = 1, flatten?: boolean) =>
    DefaultColors(key, alpha, flatten),
  defaults: (defaultKey: ThemeDefaultKeys) => {
    const attributes = {
      radius: '1px',
      padding: '0.5rem',
      margin: '0.5rem',
      height: '2.625',
      shadow: `box-shadow: 5px 5px 10px ${DefaultColors(
        'shadow'
      )}, -2px -2px 10px ${DefaultColors('black')};`,
    };
    return attributes[defaultKey];
  },
  font: (fontSize: ThemeFontSizeKeys) => {
    const attributes = {
      xxs: '0.2rem',
      xs: '0.4rem',
      sm: '0.6rem',
      md: '0.875rem',
      lg: '1.8rem',
      xl: '2rem',
      xxl: '2.8rem',
    };
    return attributes[fontSize];
  },
  shade: shade,
  title: {
    1: '2.375',
    2: '1.875',
    3: '1.5',
    4: '1.25',
    5: '1',
  },
};
