import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { tableAnatomy as parts } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle(({ theme }) => {
  return {
    container: {
      borderRadius: 'lg',
      ...theme.styleTokens.defaultBorder,
      '> table': {
        border: 'none',
      },
      '> * + table': {
        borderTop: '1px solid',
        borderTopColor: 'neutral.200',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        th: {
          '&:first-of-type': {
            borderTopLeftRadius: '0',
          },
          '&:last-of-type': {
            borderTopRightRadius: '0',
          },
        },
      },
    },
    table: {
      borderCollapse: 'unset',
      borderRadius: 'lg',
    },
    td: {
      whiteSpace: 'normal',
    },
    th: {
      letterSpacing: 'normal',
      textTransform: 'none',
      '&:first-of-type': {
        borderTopLeftRadius: 'lg',
      },
      '&:last-of-type': {
        borderTopRightRadius: 'lg',
      },
    },
  };
});

const sizes = {
  sm: definePartsStyle(({ theme }) => {
    return {
      td: {
        height: '3.25rem',
        px: '6',
        py: '4',
        ...theme.textStyles.labelMediumRegular,
      },
      th: {
        height: '2.75rem',
        px: '6',
        py: '4',
        ...theme.textStyles.labelSmallMedium,
      },
    };
  }),
};

const simpleVariant = definePartsStyle({
  table: {
    border: '1px solid',
    borderColor: 'neutral.200',
    borderSpacing: 0,
  },
  tbody: {
    tr: {
      '&:last-of-type': {
        td: {
          borderBottomWidth: 0,
        },
      },
    },
  },
  td: {
    borderColor: 'transparent',
    borderBottomColor: 'neutral.200',
    color: 'text.secondary',
    a: {
      color: 'text.secondary',
    },
  },
  tfoot: {
    borderCollapse: 'collapse',
    tr: {
      td: {
        borderTop: '1px solid',
        borderColor: 'neutral.200',
        borderBottom: '0',
        borderLeft: '0',
        borderRight: '0',
      },
    },
  },
  th: {
    bg: 'neutral.50',
    borderColor: 'transparent',
    borderBottomColor: 'neutral.200',
    color: 'text.secondary',
  },
});

const variants = {
  simple: simpleVariant,
};

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'sm',
    variant: 'simple',
  },
});
