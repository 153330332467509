import { AnalyticsProps } from './analytics.types';
import {
  Box,
  Heading,
  Subheading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from 'bam';
import { Helmet } from 'react-helmet-async';
import { LookerAnalyticsTab } from 'types/looker-analytics.types';
import { Navigate, useParams } from 'react-router';
import { Link as ReactRouterLink } from 'react-router-dom';
import { RetailReportEmbed } from './components/retail-report-embed';
import { eventTracker } from 'analytics/analytics-utils';
import React from 'react';

export const Analytics: React.FC<AnalyticsProps> = ({
  tabs = [],
  pageTitle = '',
  pageSubtitle = '',
}) => {
  const { '*': splat } = useParams();

  const effectiveTabs = tabs.filter(
    (tab: LookerAnalyticsTab): tab is LookerAnalyticsTab & { embed: string } =>
      !!tab.embed
  );
  const selectedIndex = effectiveTabs.findIndex((tab) => tab.path === splat);
  if (selectedIndex === -1 && effectiveTabs.length > 0) {
    return <Navigate to={`${tabs[0].path}`} replace={true} />;
  }

  const handleTabClick = (event) => {
    const tabIndex = event.currentTarget.dataset.index;
    eventTracker({
      category: 'ANALYTICS',
      data: tabIndex,
      type: 'SET_ANALYTICS_TAB',
    });
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Box margin="0 0 2rem">
        <Heading>{pageTitle}</Heading>
        <Subheading>{pageSubtitle}</Subheading>
      </Box>

      <Box>
        <Tabs w="100%" align="center" defaultIndex={0} index={selectedIndex}>
          <TabList>
            {effectiveTabs.map((tab, index) => {
              return (
                <Tab
                  key={`analytics-tab-${index}`}
                  as={ReactRouterLink}
                  to={`${tab.path}`}
                  onClick={handleTabClick}
                >
                  {tab.tabTitle}
                </Tab>
              );
            })}
          </TabList>

          <TabPanels>
            {effectiveTabs.map((tab, index) => {
              return (
                <TabPanel key={`tab-panel-${index}`}>
                  <RetailReportEmbed
                    embed={tab.embed}
                    height={tab.height}
                    id={`tab-panel-${index}`}
                  />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};
