import {
  StyleFunctionProps,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const highContrast = defineStyle(({ colorScheme: c }: StyleFunctionProps) => {
  return {
    backgroundColor: `${c}.600`,
    color: 'textWhite.primary',
  };
});

const lowContrast = defineStyle(({ colorScheme: c }: StyleFunctionProps) => {
  const neutralStyles = {
    color: `${c}.600`,
    backgroundColor: `${c}.100`,
  };
  switch (c) {
    case 'neutral':
      return neutralStyles;
    case 'primary':
    case 'error':
    case 'warning':
    case 'success':
      return {
        color: `${c}.600`,
        backgroundColor: `${c}.50`,
      };
  }
  return neutralStyles;
});

const sm = defineStyle(({ theme, variant }: StyleFunctionProps) => {
  return {
    py: '1',
    px: theme.pxToRem(6),
    height: theme.pxToRem(20),
    ...theme.textStyles.labelSmallMedium,
    fontWeight: variant === 'highContrast' ? 'regular' : 'medium',
  };
});

const lg = defineStyle(({ theme, variant }: StyleFunctionProps) => {
  return {
    py: theme.pxToRem(5),
    px: '2',
    height: '6',
    ...theme.textStyles.labelMediumMedium,
    fontWeight: variant === 'highContrast' ? 'regular' : 'medium',
  };
});

export const notificationTheme = defineStyleConfig({
  baseStyle: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'full',
    textTransform: 'none',
  },
  variants: {
    highContrast,
    lowContrast,
  },
  sizes: {
    sm,
    lg,
  },
  defaultProps: {
    size: 'lg',
    variant: 'highContrast',
  },
});
