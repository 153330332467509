import { AppInfo } from 'pages/admin/app-info';
import { ProtectedRoute } from '../ProtectedRoute';
import { Route } from 'react-router-dom';
import { RouteLoadingFallback } from 'routes/route-loading-fallback';
import { Routes } from '../Routes';
import { lazy } from 'react';

const AdminAnalyticsRouter = lazy(() => import('./admin-analytics-router'));
const BrandsRouter = lazy(() => import('./brands-router'));
const GetRouteForRole = lazy(() => import('./get-route-for-role'));
const SpacesRouter = lazy(() => import('./spaces-router'));
const StoresRouter = lazy(() => import('./stores-router'));
const UsersRouter = lazy(() => import('./users-router'));
const AdminInsightsRouter = lazy(() => import('./admin-insights-router'));
const Account = lazy(() => import('../../pages/admin/account'));
const LeapbotAdminRouter = lazy(() => import('./leapbot-admin-router'));

export const AdminRouter = () => {
  return (
    <RouteLoadingFallback>
      <Routes>
        <Route path={'account'} element={<Account />} />
        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ subject: 'admin:locations', action: 'get' }}
            />
          }
        >
          <Route path="spaces/*" element={<SpacesRouter />} />
        </Route>
        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ subject: 'admin:stores', action: 'get' }}
            />
          }
        >
          <Route path="stores/*" element={<StoresRouter />} />
        </Route>
        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ subject: 'admin:users', action: 'get' }}
            />
          }
        >
          <Route path="users/*" element={<UsersRouter />} />
        </Route>
        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ subject: 'admin:brands', action: 'get' }}
            />
          }
        >
          <Route path="brands/*" element={<BrandsRouter />} />
        </Route>
        <Route path="analytics/*" element={<AdminAnalyticsRouter />} />
        <Route path="insights/*" element={<AdminInsightsRouter />} />
        <Route path="*" element={<ProtectedRoute flag="appInfoPage" />}>
          <Route path="app_info/*" element={<AppInfo />} />
        </Route>
        <Route path="*" element={<ProtectedRoute flag="leapbotAdmin" />}>
          <Route path="leapbotAdmin/*" element={<LeapbotAdminRouter />} />
        </Route>
        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ subject: 'admin:brands', action: 'get' }}
            />
          }
        >
          <Route index element={<GetRouteForRole />} />
        </Route>
      </Routes>
    </RouteLoadingFallback>
  );
};
