import Joi from '@hapi/joi';

export const phone = Joi.string()
  .optional()
  .allow('')
  .pattern(/^\d+$/)
  .messages({
    'string.pattern.base': `Phone number must be a numeric value.`,
  })
  .label('Phone number');
