import { Box, BoxProps, forwardRef, useStyleConfig } from '@chakra-ui/react';
import { LeapCardLayoutVariant } from './leap-card.types';
import React from 'react';

interface LeapCardProps extends BoxProps {
  children?: React.ReactNode;
  variant?: LeapCardLayoutVariant;
}

/**
 * LeapCard (fka 'Card') is a wrapper for box, that handles only the container
 * for a card, and does not assume anything about the layout inside
 * the card. Not to be mistaken for [Card](https://chakra-ui.com/docs/components/card)
 * from Chakra UI which was released after development of this component.
 */
export const LeapCard = forwardRef<LeapCardProps, 'div'>(
  ({ variant, ...rest }, ref) => {
    const styles = useStyleConfig('LeapCard', { variant });
    return <Box {...rest} ref={ref} __css={styles} />;
  }
);

LeapCard.displayName = 'LeapCard';
