import { Icon, IconType } from '.';
import { ThemePalette } from '../../types/styled';
import { css, keyframes, withTheme } from '@emotion/react';
import { types } from 'actions';
import { useStateValue } from 'providers';
import styled from '@emotion/styled';

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Contents = styled.div`
  margin-top: 0.25rem;
`;

const IconBox = styled.div`
  width: 2rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
`;

const CloseX = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1px;
  top: 1px;
  width: 0.75rem;
  height: 0.75rem;
  line-height: 0.65rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-weight: 600;
  margin: 1.5rem 0.75rem 0;
  ${({ theme: { font } }) => `
    font-size: ${font('md')};
  `}
`;

const Title = styled.div`
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const MessageContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10000;
  min-width: 100%;
  max-width: 100%;
  ${({ theme: { defaults } }) => `
    padding: ${defaults('padding')};
    @media only screen and (min-width: 48rem) {
      min-width: 24rem;
      max-width: 24rem;
    }
  `}
`;

interface MessageProps {
  fade?: number | string;
}

const Message = styled.div<MessageProps>`
  ${({ fade, theme: { colorsDeprecated, defaults } }) => css`
    visibility: visible;
    opacity: 1;
    ${fade
      ? css`
          animation: ${fade}s ${fadeOut} ease-out;
        `
      : null}
    position: relative;
    background: ${colorsDeprecated('background')};
    padding: 1rem;
    border: 1px solid ${colorsDeprecated('text')};
    border-radius: ${defaults('radius')};
    color: ${colorsDeprecated('foreground')};
    margin: 0.5rem;
    font-size: 0.875rem;
    box-shadow: 0px 1px 3px 0px ${colorsDeprecated('foreground', 0.25)};
    display: flex;
    &.danger,
    &.error {
      border-color: ${colorsDeprecated('danger')};
      background: ${colorsDeprecated('danger', 0.15, true)};
    }
    &.success {
      border-color: ${colorsDeprecated('success')};
      background: ${colorsDeprecated('success', 0.15, true)};
    }
    &.warning {
      background: ${colorsDeprecated('warning', 0.15, true)};
      border-color: ${colorsDeprecated('warning')};
    }
  `}
`;

const IconContainerComponent = ({ type }) => {
  let icon: IconType = 'checkCircle';
  let color: keyof ThemePalette = 'foreground';

  switch (type) {
    case 'danger':
    case 'error':
      icon = 'xCircle';
      color = 'danger';
      break;
    case 'success':
      color = 'success';
      break;
    case 'warning':
      icon = 'info';
      color = 'warning';
      break;
    default:
      break;
  }

  return (
    <IconBox>
      <Icon fill={color} icon={icon} />
    </IconBox>
  );
};

const IconContainer = withTheme(IconContainerComponent);

interface MessagesProps {
  /**
   * override the clear function internal to this component
   * the component will call this function instead of using global state
   */
  onClearMessage?: (message: any) => void;
  messages: any[];
}

export const Messages = ({ messages, onClearMessage }: MessagesProps) => {
  const [, dispatch] = useStateValue();
  const clearMessage = onClearMessage
    ? onClearMessage
    : (message) => {
        dispatch({
          type: types.REMOVE_MESSAGE,
          data: message,
        });
      };

  return (
    <MessageContainer>
      {messages.map((m, i) => {
        if (m.ephemeral) {
          setTimeout(() => clearMessage(m), m.ephemeral);
        }
        return (
          <Message
            key={i}
            className={m.type}
            fade={m.ephemeral ? m.ephemeral / 1000 + 1 : undefined}
          >
            <CloseX onClick={() => clearMessage(m)}>
              <Icon icon={'close'} width={16} height={16} />
            </CloseX>
            <IconContainer type={m.type} />
            <Contents>
              {m.title && <Title>{m.title}</Title>}
              {m.message}
            </Contents>
          </Message>
        );
      })}
    </MessageContainer>
  );
};

export { InlineMessage } from './InlineMessage';
