import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const parts = ['container', 'icon'];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts);

const baseStyleContainer = defineStyle(
  ({ theme, disabled, hasIcon, isCollapsed }) => {
    return {
      alignItems: 'center',
      alignSelf: 'stretch',
      backgroundColor: 'base.white',
      border: 'none',
      borderRadius: 'md',
      color: disabled ? 'text.disabled' : 'text.secondary',
      cursor: disabled ? 'not-allowed' : 'pointer',
      display: 'flex',
      flex: '1 1 2.75rem',
      fontSize: 'md',
      fontWeight: 'medium',
      gap: 3,
      height: '2.75rem',
      justifyContent: 'flex-start',
      m: 0,
      overflow: 'hidden',
      outline: 'inherit',
      position: 'relative',
      paddingLeft: hasIcon || isCollapsed ? 3 : 12,
      paddingRight: 3,
      py: 2,
      whiteSpace: 'nowrap',
      width: '100%',
      _visited: {
        color: disabled ? 'text.disabled' : 'text.secondary',
      },
      _hover: {
        backgroundColor: disabled ? 'base.white' : 'primary.100',
        color: disabled ? 'text.disabled' : 'primary.600',
      },
      _active: {
        textDecoration: 'none',
      },
      _focusVisible: {
        ...theme.styleTokens.focusVisible,
      },
      '&.active': {
        color: 'primary.600',
      },
      '.nav-item > &.active': {
        backgroundColor: 'primary.50',
      },
      '.sub-nav-item > &': {
        display: 'block',
        lineHeight: 'none',
        overflow: 'hidden',
        py: '.875rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    };
  }
);

const baseStyleIcon = defineStyle(() => {
  return {
    'a:hover + &': {
      color: 'icon.secondary',
    },
    '.active + &': {
      color: 'primary.500',
    },
  };
});

const baseStyle = definePartsStyle((props) => ({
  container: baseStyleContainer(props),
  icon: baseStyleIcon(),
}));

export const navLinkTheme = defineMultiStyleConfig({
  baseStyle,
});
