import { IllustrationProps } from '../illustration.types';

export const NotAuthorizedIllustration = ({
  height = '100%',
  width = '100%',
  transform,
}: IllustrationProps) => {
  return (
    <svg
      fill="none"
      height={height}
      transform={transform}
      viewBox="0 0 566 566"
      width={width}
    >
      <path
        d="M141.41 97.8139C141.41 97.8139 226.503 162.386 355.865 41.6469C470.752 -65.5809 564.559 103.6 565.216 184.618C566.066 289.569 450.328 373.544 506.495 442.476C562.662 511.409 395.108 625.24 304.804 526.727C192.47 404.18 162.037 503.75 98.0067 503.75C52.0518 503.75 -42.2989 389.563 21.4151 304.611C75.029 233.126 45.7891 209.401 31.6291 184.618C11.2038 148.875 59.7118 51.859 141.41 97.8139Z"
        fill="#C7D7FE"
      />
      <path
        d="M520.878 53.7969C520.878 55.3855 520.808 56.9506 520.67 58.5006C519.434 72.5044 512.764 85.4676 502.088 94.6137C491.412 103.76 477.578 108.361 463.551 107.433C449.524 106.504 436.417 100.12 427.04 89.6463C417.662 79.1729 412.759 65.4437 413.38 51.3993C414.001 37.3548 420.097 24.112 430.363 14.5074C440.628 4.90269 454.247 -0.300096 468.302 0.0133868C482.356 0.32687 495.729 6.1317 505.557 16.1845C515.384 26.2374 520.883 39.7388 520.878 53.7969Z"
        fill="#A4BCFD"
      />
      <path
        d="M473.889 57.9143C470.419 60.613 465.569 65.9875 467.035 77.9464C465.408 82.5883 459.625 91.4554 459.625 95.6958C459.625 99.9363 452.685 100.709 451.914 99.5494C451.143 98.3896 450.758 83.7429 446.517 81.0442C442.276 78.3456 443.047 66.0088 443.047 66.0088C443.047 66.0088 444.589 61.3822 439.577 58.2982C434.565 55.2142 431.073 47.8889 435.522 43.6481C439.963 39.4072 428.012 36.7086 426.469 33.6246C425.814 32.3151 423.301 29.3837 420.633 26.4613C426.401 16.6924 435.098 8.98768 445.492 4.44C449.432 8.55551 452.84 13.8237 451.915 19.3598C452.069 22.0585 463.781 26.2993 458.044 32.0805C452.3 37.8636 444.505 45.961 458.044 48.6582C471.576 51.3609 477.359 55.2145 473.889 57.9143Z"
        fill="#3538CD"
      />
      <path
        d="M504.686 15.3139C501.802 21.3974 494.569 26.3012 490.468 26.3012C485.842 21.2878 475.818 28.2291 474.878 20.1328C474.33 15.4142 479.712 8.08894 484.3 2.78442C491.949 5.3795 498.916 9.66167 504.686 15.3139Z"
        fill="#3538CD"
      />
      <path
        d="M520.67 58.5006C519.522 71.8128 513.445 84.2167 503.629 93.2826C501.848 91.5784 500.344 89.5817 500.877 87.9856C502.033 84.5158 506.737 77.1906 505.927 72.5644C505.172 68.2465 509.158 55.185 520.67 58.5006Z"
        fill="#3538CD"
      />
      <path
        d="M433.122 325.763C431.4 336.385 428.55 346.792 424.619 356.809C399.012 422.407 330.314 463.501 258.019 451.69C218.106 445.156 182.421 423.041 158.807 390.207C135.194 357.372 125.585 316.504 132.092 276.587C132.423 274.562 132.808 272.52 133.211 270.532C149.522 190.733 226.09 137.411 307.195 150.659C331.396 154.571 354.288 164.295 373.906 178.996C380.353 183.809 386.401 189.134 391.992 194.919C408.596 211.997 420.965 232.729 428.108 255.452C435.251 278.175 436.968 302.255 433.122 325.762V325.763Z"
        fill="#6172F3"
      />
      <path
        d="M424.621 356.809C395.577 375.507 361.279 392.801 323.467 407.103C284.965 421.657 247.156 431.489 212.502 436.64C131.07 448.781 67.1178 435.214 52.7082 397.099C39.2138 361.499 72.4047 313.279 133.211 270.533C132.809 272.52 132.423 274.562 132.092 276.587C130.877 284 130.218 291.494 130.119 299.006C95.0853 326.056 76.8532 352.548 84.0404 371.551C91.0281 390.027 120.697 397.89 162.903 395.661C204.165 393.488 257.41 381.602 313.142 360.533C361.343 342.288 403.612 320.111 435.086 297.834C477.221 268.046 500.074 238.072 492.186 217.161C484.076 195.695 445.377 188.54 391.992 194.92C386.401 189.135 380.353 183.811 373.906 178.997C448.633 170.361 506.232 184.475 519.807 220.374C534.352 258.771 494.687 311.781 424.621 356.809Z"
        fill="#3538CD"
      />
    </svg>
  );
};
