import { Messages } from 'components/Message';
import { useStateValue } from 'providers';
import React from 'react';

export const AppMessages: React.FC = () => {
  const [
    {
      messages: { messages },
    },
  ] = useStateValue();

  return <Messages messages={messages} />;
};
