import { ConnectShopifyAdapter } from '../components';
import { Heading, LeapCard, LeapCardContent, Text } from 'bam';
import { useTranslation } from 'react-i18next';
import React from 'react';

const Adapters: React.FC = () => {
  const { t } = useTranslation('adapters');

  return (
    <>
      <Heading as="h1">{t('heading')}</Heading>

      <Text>{t('message')}</Text>

      <LeapCard maxW="32rem">
        <LeapCardContent>
          <ConnectShopifyAdapter />
        </LeapCardContent>
      </LeapCard>
    </>
  );
};

export default Adapters;
