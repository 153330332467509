import { LDContext } from 'launchdarkly-js-client-sdk';

export function buildAdminRetailUserIdentify(sessionId: string, session: any) {
  const adminUserObject: LDContext = {
    anonymous: false,
    email: session.email,
    key: sessionId,
    kind: 'user',
  };

  return adminUserObject;
}
