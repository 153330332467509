export const makeCamelCased = (string: string): string =>
  string
    .replace('-', ' ')
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) {
        return '';
      }
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });

export const camelCaseToSnakeCase = (string: string): string => {
  // from https://stackoverflow.com/questions/54246477/how-to-convert-camelcase-to-snake-case-in-javascript
  return string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};
