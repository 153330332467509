import { getBrand } from '../api/brand/get-brand';
import { types } from 'actions/types';

export const fetchBrand = async () => {
  const response = await getBrand();

  return {
    type: types.FETCH_BRAND_SUCCESS,
    category: 'BRAND',
    data: response,
  };
};
