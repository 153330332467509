export const LONG_TEXT_VALUE = 10000;
export const SHORT_TEXT_VALUE = 1000;
import Joi from '@hapi/joi';

export const optionalLongTextValue = Joi.string()
  .allow('')
  .allow(null)
  .max(LONG_TEXT_VALUE);

export const optionalShortTextValue = Joi.string()
  .allow('')
  .allow(null)
  .max(SHORT_TEXT_VALUE);

export const requiredLongTextValue = Joi.string()
  .required()
  .max(LONG_TEXT_VALUE);

export const requiredShortTextValue = Joi.string()
  .required()
  .max(SHORT_TEXT_VALUE);
