import { AlertCircle, AlertTriangle, CheckCircle, InfoCircle } from 'bam/icons';
import {
  AlertIcon as ChakraAlertIcon,
  AlertIconProps as ChakraAlertIconProps,
} from '@chakra-ui/react';
import { assertNever } from 'utils/types/assertNever';
import { useAlertContext } from 'bam/components/feedback/alert/alert-context';
import React from 'react';

type AlertIconProps = ChakraAlertIconProps;

export const AlertIcon: React.FC<AlertIconProps> = (props) => {
  const { status } = useAlertContext();
  let icon = <InfoCircle boxSize="6" />;
  switch (status) {
    case 'error':
      icon = <AlertCircle boxSize="6" />;
      break;
    case 'info':
      icon = <InfoCircle boxSize="6" />;
      break;
    case 'success':
      icon = <CheckCircle boxSize="6" />;
      break;
    case 'warning':
      icon = <AlertTriangle boxSize="6" />;
      break;
    case undefined:
      icon = <InfoCircle boxSize="6" />;
      break;

    default:
      assertNever(status);
  }
  return <ChakraAlertIcon {...props}>{icon}</ChakraAlertIcon>;
};
