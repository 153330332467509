import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  borderColor: 'neutral.300',
  borderWidth: '0 0 1px',
});

export const pageSectionTheme = defineStyleConfig({
  baseStyle,
});
