import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(['container', 'pulser', 'label']);

const baseStyle = definePartsStyle({
  container: {
    position: 'relative',
  },
  pulser: {
    bg: 'primary.700',
    borderRadius: 'full',
    position: 'absolute',
    '&:nth-of-type(1)': {
      animationDelay: '0s',
    },
    '&:nth-of-type(2)': {
      animationDelay: '-.1s',
    },
    '&:nth-of-type(3)': {
      animationDelay: '-.2s',
    },
    '&:nth-of-type(4)': {
      animationDelay: '-.3s',
    },
    '&:nth-of-type(5)': {
      animationDelay: '-.4s',
    },
    '&:nth-of-type(6)': {
      animationDelay: '-.5s',
    },
    '&:nth-of-type(7)': {
      animationDelay: '-.6s',
    },
    '&:nth-of-type(8)': {
      animationDelay: '-.7s',
    },
  },
});

const sizes = {
  sm: definePartsStyle({
    container: {
      h: 6, // 24px
      w: 6, // 24px
    },
    pulser: {
      h: '.1875rem', // 3px
      w: '.1875rem', // 3px
      '&:nth-of-type(1)': {
        left: '0.65625rem',
      },
      '&:nth-of-type(2)': {
        left: '1.0925rem',
        top: '0.22rem',
      },
      '&:nth-of-type(3)': {
        left: '1.3125rem',
        top: '0.65625rem',
      },
      '&:nth-of-type(4)': {
        left: '1.0925rem',
        top: '1.0925rem',
      },
      '&:nth-of-type(5)': {
        left: '0.65625rem',
        top: '1.3125rem',
      },
      '&:nth-of-type(6)': {
        left: '0.22rem',
        top: '1.0925rem',
      },
      '&:nth-of-type(7)': {
        top: '0.65625rem',
      },
      '&:nth-of-type(8)': {
        left: '0.22rem',
        top: '0.22rem',
      },
    },
  }),
  md: definePartsStyle({
    container: {
      h: 16, // 64px
      w: 16, // 64px
    },
    pulser: {
      h: 2, // 8px
      w: 2, // 8px
      '&:nth-of-type(1)': {
        left: '1.75rem',
      },
      '&:nth-of-type(2)': {
        left: '2.914rem',
        top: '0.586rem',
      },
      '&:nth-of-type(3)': {
        left: '3.5rem',
        top: '1.75rem',
      },
      '&:nth-of-type(4)': {
        left: '2.914rem',
        top: '2.914rem',
      },
      '&:nth-of-type(5)': {
        left: '1.75rem',
        top: '3.5rem',
      },
      '&:nth-of-type(6)': {
        left: '0.586rem',
        top: '2.914rem',
      },
      '&:nth-of-type(7)': {
        top: '1.75rem',
      },
      '&:nth-of-type(8)': {
        left: '0.586rem',
        top: '0.586rem',
      },
    },
  }),
  lg: definePartsStyle({
    container: {
      h: 24, // 96px
      w: 24, // 96px
    },
    pulser: {
      h: 3, // 12px
      w: 3, // 12px
      '&:nth-of-type(1)': {
        left: '2.625rem',
      },
      '&:nth-of-type(2)': {
        left: '4.371rem',
        top: '0.879rem',
      },
      '&:nth-of-type(3)': {
        left: '5.25rem',
        top: '2.625rem',
      },
      '&:nth-of-type(4)': {
        left: '4.371rem',
        top: '4.371rem',
      },
      '&:nth-of-type(5)': {
        left: '2.625rem',
        top: '5.25rem',
      },
      '&:nth-of-type(6)': {
        left: '0.879rem',
        top: '4.371rem',
      },
      '&:nth-of-type(7)': {
        top: '2.625rem',
      },
      '&:nth-of-type(8)': {
        left: '0.879rem',
        top: '0.879rem',
      },
    },
  }),
};

export const spinnerTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'sm',
  },
});
