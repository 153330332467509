import { LeapStorage, replacer } from './stateProvider';
import { initialState } from 'reducers';
import { removeKeys } from 'utils';
import copy from 'fast-copy';

export const storeData = (
  key: string,
  data: typeof initialState,
  blockList: string[] = []
) => {
  // deep copy to not have side effects on actual storage
  const deepCopyData = copy(data);
  const dataToSave = removeKeys(blockList, deepCopyData);
  dataToSave.global.loading = false;

  LeapStorage.setItem(key, JSON.stringify({ ...dataToSave }, replacer));
};
