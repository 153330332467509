import '@fontsource/inter/variable.css';
import { ChakraProvider } from '@chakra-ui/react';
import { GlobalStyles } from 'theme/GlobalStyles';
import { ThemeProvider as Theme } from '@emotion/react';
import { bamTheme } from 'bam';
import { leapLightTheme } from 'theme/leapLightTheme';
import React from 'react';

interface ThemeProviderProps {
  children?: React.ReactNode;
}

/*
 * `leapLightTheme` supports legacy pre BAM/Chakra/UI Prep styled-components/emotion based components.
 * */
export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return (
    <ChakraProvider theme={bamTheme} resetCSS={false}>
      <Theme theme={leapLightTheme}>
        <GlobalStyles />
        {children}
      </Theme>
    </ChakraProvider>
  );
};
