import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
} from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(['container', 'icon', 'label']);

const baseStyle = definePartsStyle({
  container: {
    cursor: 'pointer',
    flex: 1,
    px: 4,
    py: 3,
    '&[data-checked]': {
      backgroundColor: 'primary.600',
      _hover: {
        backgroundColor: 'primary.600',
      },
    },
    _hover: {
      backgroundColor: 'primary.100',
    },
    _focusVisible: {
      outlineColor: 'primary.300',
      outlineOffset: 0,
      outlineStyle: 'solid',
      outlineWidth: '0.125rem',
    },
    '&:first-of-type': {
      borderBottomLeftRadius: 'lg',
      borderTopLeftRadius: 'lg',
    },
    '&:last-of-type': {
      borderBottomRightRadius: 'lg',
      borderTopRightRadius: 'lg',
    },
    '& + &': {
      borderLeft: '1px solid',
      borderLeftColor: 'neutral.300',
    },
  },
  icon: {
    alignSelf: 'center',
    display: 'inline-flex',
    flexShrink: 0,
    mr: 2,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 'none',
    whiteSpace: 'nowrap',
    _checked: {
      color: 'textWhite.primary',
    },
  },
});

export const buttonSelectButtonTheme: ComponentStyleConfig =
  defineMultiStyleConfig({
    baseStyle,
  });
