import { ErrorTemplate } from 'bam';
import { Magnascope } from 'bam/illustrations';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const NotFound: React.FC = () => {
  const { t } = useTranslation('error');

  return (
    <ErrorTemplate
      image={<Magnascope />}
      message={t('404.message')}
      subtitle={t('404.subtitle')}
      title={t('404.title')}
    />
  );
};
