import { types } from 'actions';

const Brand = (state, action) => {
  switch (action.type) {
    case types.GET_CREATE_NEW_STORE_DATA_IN_PROGRESS:
      return {
        ...state,
        fetchInProgress: true,
      };
    case types.GET_CREATE_NEW_STORE_DATA_FAILED:
    case types.GET_CREATE_NEW_STORE_DATA_SUCCESS:
      return {
        ...state,
        fetchInProgress: false,
      };
    case types.GET_BRAND_SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          ...action.data.data,
        },
        isComplete: action.type,
      };
    case types.GET_BRAND_STORES_SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          storeData: action.data || [],
        },
        isComplete: action.type,
      };
    case types.GET_BRAND_USERS_SUCCESS:
      return {
        ...state,
        show: {
          ...state.show,
          users: action?.data?.users || [],
        },
        isComplete: action.type,
      };
    case types.CREATE_BRAND_SUCCESS:
      return {
        ...state,
        isComplete: action.type,
        show: {
          ...action.data,
        },
      };
    case types.UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        show: action.data.response,
        isComplete: action.type,
      };
    case types.CLEAN_BRAND_HISTORY:
      return {
        ...state,
        isComplete: action.type,
      };
    case types.SET_BRAND_PAGE_LENGTH:
      return {
        ...state,
        pageLength: action.length,
      };
    case types.SET_BRAND_PAGINATION_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current_page: action.currentPage,
        },
      };
    case types.SET_BRAND_PAGINATION_LIMIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          limit: action.limit,
        },
      };
    case types.SET_BRAND_SORT:
      return {
        ...state,
        sort: action.sort,
      };
    case types.CLEAR_BRAND_INDEX:
      return {
        ...state,
        index: [],
      };
    case types.SAVE_BRAND_ASSET_SUCCESS:
    case types.DELETE_BRAND_ASSET_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default Brand;
