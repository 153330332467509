import { LeapUseQueryOptions, useQuery } from 'api/useQuery';
import { Schemas } from 'types/leap-api';
import { getBrand } from 'api/brand/get-brand';

export function useGetBrand(options?: LeapUseQueryOptions) {
  const enabled = options && 'enabled' in options ? options.enabled : true;
  const queryResult = useQuery<Schemas['BrandResponseV2'], Error>(
    ['brand'],
    () => getBrand(),
    {
      enabled,
      onSuccessEvent: options?.onSuccessEvent,
    }
  );

  return queryResult;
}
