import { DescriptionListProps } from './description-list.types';
import { VStack } from '@chakra-ui/react';
import React from 'react';

/**
 * DescriptionList is a Chakra implementation of the native `<dl/>` element.
 *
 * ```tsx
 *  <DescriptionList>
 *    <Dt>John Lennon</Dt>
 *    <Dd>rhythm guitar</Dd>
 *    <Dt>Paul McCartney</Dt>
 *    <Dd>bass</Dd>
 *    <Dt>George Harrison</Dt>
 *    <Dd>lead guitar</Dd>
 *    <Dt>Ringo Starr</Dt>
 *    <Dd>Drums</Dd>
 *  </DescriptionList>
 * ```
 * */
export const DescriptionList: React.FC<DescriptionListProps> = ({
  align = 'flex-start',
  children,
  spacing = 2,
  ...rest
}) => {
  return (
    <VStack
      align={align}
      as="dl"
      my={0}
      spacing={spacing}
      // __css={styles.container}
      {...rest}
    >
      {children}
    </VStack>
  );
};
