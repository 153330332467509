import { DialogProps } from './dialog.types';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

/**
 * Dialog is a wrapper for [Chakra UI Modal](https://chakra-ui.com/docs/components/modal/usage)
 *
 * ```tsx
 * const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen}>Open Modal</Button>

      <Dialog
        scrollBehavior="inside"
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        id="example-id-is-required"
      >
        <DialogHeader featuredIcon={<Lightning02 />}>Dialog Title</DialogHeader>
        <DialogBody>
          <Text textStyle="subheadingRegular">
            Decide where your cloud lives. Maybe he lives right in here. Isn&#39;t it fantastic that you can change your mind and create all these happy things? It takes dark in order to show light. Remember how free clouds are. They just lay around in the sky all day long. See. We take the corner of the brush and let it play back-and-forth.
          </Text>
        </DialogBody>

        <DialogFooter>
          <Button variant="tertiary" mr="auto">
            Link to thing
          </Button>
          <Button variant="secondary" mr="2">
            Button Label
          </Button>
          <Button variant="primary" onClick={onClose}>
            Button Label
          </Button>
        </DialogFooter>
      </Dialog>
 * ```
 */
export const Dialog: React.FC<DialogProps> = ({ children, ...rest }) => {
  return (
    <Modal
      {...rest}
      blockScrollOnMount={process.env.NODE_ENV !== 'test'}
      trapFocus={process.env.NODE_ENV !== 'test'}
    >
      <ModalOverlay />
      <ModalContent bg="cardsDialog.primaryLight">{children}</ModalContent>
    </Modal>
  );
};
