import { AddBrandUserFormButtons } from './add-brand-user-form-buttons';
import {
  AddBrandUserTypes,
  defaultBrandUserValues,
} from './add-brand-user.types';
import { Alert, AlertDescription, Input, Stack } from '../../../../../index';
import { Controller, useForm } from 'react-hook-form';
import { addBrandUserSchema } from './add-brand-user.schema';
import { joiResolver } from '@hookform/resolvers/joi';
import { usePostBrandUsers } from '../../../../../../hooks/api/brand/users/use-post-brand-users';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface AddUserFormProps {
  onClose: () => void;
  firstFieldRef?: React.RefObject<any>;
}

export const AddBrandUserForm: React.FC<AddUserFormProps> = ({
  onClose,
  firstFieldRef,
}) => {
  const { error, mutateAsync } = usePostBrandUsers();
  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<AddBrandUserTypes & { apiCreateUserError?: void }>({
    mode: 'onSubmit',
    resolver: joiResolver(addBrandUserSchema),
    defaultValues: { ...defaultBrandUserValues },
  });
  const { t } = useTranslation('add-user');

  const setCreateUserError = (error: Error | null) => {
    if (error) {
      setError('apiCreateUserError', {
        type: 'serverError',
        message: error?.message
          ? error.message
          : t('errorMessageTemplates.serverErr', { ns: 'error' }),
      });
    }
  };

  const handleFormSubmit = async (formInputs: AddBrandUserTypes) => {
    await mutateAsync(formInputs);
    setCreateUserError(error);
    closeForm();
  };

  const closeForm = () => {
    clearErrors();
    reset();
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing={4}>
        {errors.apiCreateUserError ? (
          <Alert status="error">
            <AlertDescription>
              {errors.apiCreateUserError.message}
            </AlertDescription>
          </Alert>
        ) : null}
        <Controller
          control={control}
          name="full_name"
          render={({ field }) => (
            <Input
              errorText={errors?.full_name?.message}
              id="full_name"
              invalid={!!errors?.full_name}
              label={t(`fullName.label`)}
              placeholder={t(`fullName.placeholder`)}
              type="text"
              {...field}
              ref={firstFieldRef ?? field.ref}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              errorText={errors?.email?.message}
              id="email"
              invalid={!!errors?.email}
              label={t(`email.label`)}
              placeholder={t(`email.placeholder`)}
              type="email"
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="phone_number"
          render={({ field }) => (
            <Input
              errorText={errors.phone_number?.message}
              id="phone"
              invalid={!!errors.phone_number}
              label={t(`phone.label`)}
              optional
              placeholder={t(`phone.placeholder`)}
              type="tel"
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <Input
              errorText={errors?.full_name?.message}
              id="title"
              invalid={!!errors.title}
              label={t(`title.label`)}
              optional
              placeholder={t(`title.placeholder`)}
              type="text"
              {...field}
            />
          )}
        />

        <AddBrandUserFormButtons
          onClick={closeForm}
          isSubmitting={isSubmitting}
        />
      </Stack>
    </form>
  );
};
