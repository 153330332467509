import { types } from 'actions';
const Integrations = (state, action) => {
  switch (action.type) {
    case types.CONNECT_SHOPIFY_REDIRECT:
      window.location = action.data.redirect;
      break;
    case types.LAUNCH_SHOPIFY:
    case types.LAUNCH_EXCEL:
    case types.LAUNCH_UPLOAD:
    default:
      return state;
  }
};

export default Integrations;
