import {
  BarChartSquare02,
  Building02,
  ChartBreakoutSquare,
  CheckSquare,
  Home02,
  MarkerPin01,
  Stars02,
  Users02,
} from '../../../../icons';
import { Can } from 'providers';
import { Nav } from '../nav/nav';
import { NavItem } from '../nav-item/nav-item';
import { NavList } from '../nav-list/nav-list';
import { SubNavItem } from '../sub-nav-item/sub-nav-item';
import {
  formatAnalyticsPath,
  formatAnalyticsTitle,
  showNavOption,
} from 'utils/analytics-nav-menu';
import { useBrandStatus } from 'hooks/use-brand-status';
import { useFlags } from '../../../../../showroom/use-flags';
import { useGetBrand } from 'hooks/api/brand/use-get-brand';
import { useIsBrandOnboarding } from 'hooks/use-is-brand-onboarding';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const BrandUsersNavigation: React.FC = () => {
  const { t } = useTranslation('primary-template');
  const { analyticsPages, brandInsightsPages, dataPlus } = useFlags();

  const { hasStoresNotOnboarding, isContractSigned, isShopifyConnected } =
    useBrandStatus();
  const isBrandOnboarding = useIsBrandOnboarding();

  const { data: brandResponse } = useGetBrand();

  return (
    <Nav>
      <NavList>
        <NavItem icon={<Home02 />} label={t('sidebar.home')} to="/" />

        <Can I="get" a="brand:users">
          <NavItem
            icon={<Users02 />}
            label={t('sidebar.users')}
            to="/brand/users"
          />
        </Can>

        {brandResponse?.brand.available_spaces_count &&
        brandResponse.brand.available_spaces_count > 0 ? (
          <NavItem
            icon={<MarkerPin01 />}
            label={t('sidebar.spaces')}
            notification={brandResponse.brand.available_spaces_count}
            notificationLabel={t('sidebar.spacesNotification', {
              count: brandResponse.brand.available_spaces_count,
            })}
            to="/brand/spaces"
          />
        ) : null}

        {hasStoresNotOnboarding ? (
          <NavItem
            icon={<Building02 />}
            label={t('sidebar.stores')}
            to="/brand/stores"
          >
            {brandResponse?.stores?.map((store) => {
              const effectiveLabel =
                store.salesforce_market && store.salesforce_sub_market
                  ? `${store.salesforce_market} - ${store.salesforce_sub_market}`
                  : store.space_address
                  ? store.space_address
                  : null;
              if (effectiveLabel) {
                return (
                  <SubNavItem
                    key={store.salesforce_store_id}
                    label={effectiveLabel}
                    to={`/brand/stores/${store.code}`}
                  />
                );
              } else {
                return null;
              }
            })}
          </NavItem>
        ) : null}

        {isBrandOnboarding ? (
          <NavItem
            icon={<CheckSquare />}
            label={t('sidebar.tasks')}
            to="/brand/tasks"
          />
        ) : null}

        {!isContractSigned && isShopifyConnected ? (
          <NavItem
            icon={<ChartBreakoutSquare />}
            label={t('sidebar.omnichannelFitness')}
            to="/brand/omnichannel_fitness"
          />
        ) : null}

        {isContractSigned && hasStoresNotOnboarding ? (
          <NavItem icon={<BarChartSquare02 />} label={t('sidebar.analytics')}>
            {analyticsPages && Array.isArray(analyticsPages)
              ? analyticsPages.map((page) =>
                  showNavOption(page, true) ? (
                    <SubNavItem
                      disabled={!hasStoresNotOnboarding}
                      key={page.title}
                      label={formatAnalyticsTitle(page.title)}
                      to={formatAnalyticsPath(page.path, 'BRAND')}
                    />
                  ) : null
                )
              : null}
            {brandInsightsPages ? (
              <SubNavItem
                label={t('sidebar.myInsights')}
                to="/brand/analytics/insights"
              />
            ) : null}
          </NavItem>
        ) : null}

        {dataPlus === 'show' || dataPlus === 'enabled' ? (
          <NavItem
            disabled={dataPlus === 'show'}
            icon={<Stars02 />}
            label={t('sidebar.dataPlus')}
            to="/brand/data_plus"
          />
        ) : null}
      </NavList>
    </Nav>
  );
};
