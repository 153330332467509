import { Box, keyframes } from '@chakra-ui/react';
import { LeapLogoType } from 'bam/logos';
import { LoadingProps } from './loading.types';
import { motion } from 'framer-motion';
import React from 'react';

const animationKeyframes = keyframes`
  0% { transform: scale(0.8); }
  50% { transform: scale(1); }
  100% { transform: scale(0.8); }
`;

const animation = `${animationKeyframes} 4s infinite linear both`;

/**
 * Primary loading indicator that displays a pulsing Leap logo. Use this to
 * indicate 'page' level loading.
 * */
export const Loading: React.FC<LoadingProps> = ({
  bgColor,
  cover,
  loading,
}) => {
  if (!loading) {
    return null;
  }

  return (
    <Box
      alignItems="center"
      backgroundColor={cover ? 'base.white' : 'transparent'}
      bottom={0}
      display="flex"
      height="100vh"
      justifyContent="center"
      left={0}
      pointerEvents="none"
      position="fixed"
      right={0}
      top={0}
      width="100vw"
      zIndex="overlay"
    >
      <Box
        as={motion.div}
        animation={animation}
        backgroundColor={bgColor ? bgColor : 'transparent'}
      >
        <LeapLogoType aria-busy color="primary.600" role="alert" />
      </Box>
    </Box>
  );
};
