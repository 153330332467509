import { LDContext } from 'launchdarkly-js-client-sdk';

export function buildBrandUserIdentify(sessionId: string, session: any) {
  const brandUserObject: LDContext = {
    anonymous: false,
    email: session.email,
    key: sessionId,
    kind: 'user',
    custom: {
      handle: session.brands[0].handle ?? '',
    },
  };

  return brandUserObject;
}
