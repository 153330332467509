import { Message } from 'reducers/messages';
import { types } from './types';

export function addMessage({ title, message, ephemeral, type }: Message) {
  return {
    type: types.ADD_MESSAGE,
    data: {
      ephemeral,
      message,
      title,
      type,
    },
  };
}
