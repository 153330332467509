import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/react';
import { selectAnatomy as parts } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const $bg = cssVar('select-bg');

const baseStyleField = defineStyle(({ theme }) => {
  return {
    appearance: 'none',
    bg: $bg.reference,
    color: 'text.primary',
    width: '100%',
    ...theme.textStyles.labelLargeRegular,
    [$bg.variable]: 'base.white',
    '> option, > optgroup': {
      bg: $bg.reference,
    },
  };
});

const baseStyleIcon = defineStyle({
  color: 'icon.primaryDark',
});

const baseStyle = definePartsStyle((props) => ({
  field: baseStyleField(props),
  icon: baseStyleIcon,
}));

const sizes = {
  sm: definePartsStyle(({ theme }) => {
    return {
      field: {
        ...theme.styleTokens.fieldSizeSmall,
      },
    };
  }),
  lg: definePartsStyle(({ theme }) => {
    return {
      field: {
        ...theme.styleTokens.fieldSizeLarge,
        py: 2,
      },
    };
  }),
};

const variantOutline = definePartsStyle(({ theme }) => {
  return {
    field: {
      ...theme.styleTokens.defaultBorder,
      _disabled: {
        ...theme.styleTokens.disabledInput,
      },
      _focusVisible: {
        border: 'none',
        boxShadow: 'none',
        ...theme.styleTokens.focusVisible,
      },
      _invalid: {
        boxShadow: 'none',
        ...theme.styleTokens.invalidBorder,
      },
    },
  };
});

const variants = {
  outline: variantOutline,
};

export const singleSelectTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
});
