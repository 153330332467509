import {
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  forwardRef,
  useBreakpointValue,
} from '@chakra-ui/react';
import { SimpleInputProps } from './simple-input.types';

/**
 * Simple input contains the input element and the ability to add left and right `InputElements`.
 * It is useful for search boxes, etc, for when you need an input that is not part of an overall form with
 * labels, hint text, etc. If you do need those things, see regular `Input`.
 *
 * ```tsx
 * <SimpleInput name="test" id="test" placeholder="Enter something" />
 * ```
 */
export const SimpleInput = forwardRef<SimpleInputProps, 'div'>(
  (
    {
      autoComplete,
      disableClickLeftElement,
      disableClickRightElement,
      flex,
      id,
      leftElement,
      mb,
      ml,
      mr,
      mt,
      name,
      onChange,
      onBlur,
      placeholder,
      rightElement,
      size,
      type,
      value,
      ...rest
    },
    ref
  ) => {
    const breakpointSize = useBreakpointValue({
      base: 'sm',
      md: 'lg',
    });

    const effectiveSize = size ? size : breakpointSize ? breakpointSize : 'sm';

    return (
      <InputGroup
        flex={flex}
        mb={mb}
        ml={ml}
        mr={mr}
        mt={mt}
        size={effectiveSize}
      >
        {leftElement ? (
          <InputLeftElement
            pointerEvents={disableClickLeftElement ? 'none' : undefined}
          >
            {leftElement}
          </InputLeftElement>
        ) : null}
        <ChakraInput
          {...rest}
          autoComplete={autoComplete}
          focusBorderColor="primary.300"
          id={id}
          name={name ? name : id}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          ref={ref}
          type={type}
          value={value}
        />
        {rightElement ? (
          <InputRightElement
            pointerEvents={disableClickRightElement ? 'none' : undefined}
          >
            {rightElement}
          </InputRightElement>
        ) : null}
      </InputGroup>
    );
  }
);

SimpleInput.displayName = 'SimpleInput';
