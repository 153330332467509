import {
  StyleFunctionProps,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const baseStyle = defineStyle({
  alignItems: 'center',
  borderRadius: '3xl',
  display: 'inline-flex',
  justifyContent: 'center',
  gap: '1',
  textTransform: 'none',
  whiteSpace: 'nowrap',
});

const highContrast = defineStyle(({ colorScheme: c }: StyleFunctionProps) => {
  const informationStyles = {
    backgroundColor: 'base.white',
    border: '1px solid',
    borderColor: 'neutral.600',
    color: 'text.primary',
  };
  if (c === 'information') {
    return informationStyles;
  }
  return {
    backgroundColor: `${c}.600`,
    color: 'textWhite.primary',
  };
});

const lowContrast = defineStyle(({ colorScheme: c }: StyleFunctionProps) => {
  const informationStyles = {
    backgroundColor: 'neutral.25',
    border: '1px solid',
    borderColor: 'neutral.300',
    color: 'text.disabled',
  };
  const neutralStyles = {
    color: `${c}.600`,
    backgroundColor: `${c}.100`,
  };
  switch (c) {
    case 'information':
      return informationStyles;
    case 'neutral':
      return neutralStyles;
    case 'primary':
    case 'error':
    case 'feature':
    case 'warning':
    case 'success':
      return {
        color: `${c}.600`,
        backgroundColor: `${c}.50`,
      };
  }
  return neutralStyles;
});

const sm = defineStyle(({ theme }: StyleFunctionProps) => {
  return {
    py: '0.125rem', // no token for this small
    px: '0.375rem', // no token for this
    height: theme.pxToRem(18),
    ...theme.textStyles.labelSmallMedium,
  };
});

const lg = defineStyle(({ theme, variant }: StyleFunctionProps) => {
  return {
    py: '1',
    px: '2',
    height: '6',
    ...theme.textStyles.labelMediumMedium,
    fontWeight: variant === 'highContrast' ? 'regular' : 'medium',
  };
});

const xl = defineStyle(({ theme, variant }: StyleFunctionProps) => {
  return {
    py: '3',
    px: theme.pxToRem(18),
    height: '10',
    gap: theme.pxToRem(6),
    ...theme.textStyles.labelLargeMedium,
    fontWeight: variant === 'highContrast' ? 'regular' : 'medium',
  };
});

export const badgeTheme = defineStyleConfig({
  baseStyle: baseStyle,
  variants: {
    highContrast,
    lowContrast,
  },
  sizes: {
    sm,
    lg,
    xl,
  },
  defaultProps: {
    size: 'lg',
    variant: 'highContrast',
  },
});
