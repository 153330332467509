import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers([
    'progressSteps',
    'step',
    'stepItemStack',
    'stepCircle',
    'stepDivider',
    'stepLabel',
    'stepLabelStack',
  ]);

const baseProgressSteps = defineStyle({
  spacing: '0',
});

const baseStep = defineStyle({
  spacing: '4',
  cursor: 'pointer',
});

const baseStepItemStack = defineStyle({
  spacing: '0',
  alignItems: 'center',
});

const baseStepCircle = defineStyle({
  cursor: 'pointer',
  border: '0',
});

const baseStepDivider = defineStyle({
  borderWidth: '1px',
  borderStyle: 'dashed',
  height: '0',
});

const baseStepLabelStack = defineStyle({
  spacing: '0.5',
  alignItems: 'start',
});

const baseStyle = definePartsStyle({
  progressSteps: baseProgressSteps,
  step: baseStep,
  stepCircle: baseStepCircle,
  stepItemStack: baseStepItemStack,
  stepDivider: baseStepDivider,
  stepLabelStack: baseStepLabelStack,
});

export const progressStepsTheme = defineMultiStyleConfig({
  baseStyle,
});
