import { ListItem } from '@chakra-ui/react';
import { NavLink } from '../nav-link/nav-link';
import { type SubNavItemProps } from './sub-nav-item.types';
import React from 'react';

export const SubNavItem: React.FC<SubNavItemProps> = ({
  disabled,
  label,
  to,
}) => {
  return (
    <ListItem className="sub-nav-item" mb={0}>
      <NavLink disabled={disabled} label={label} to={to} />
    </ListItem>
  );
};
