import { cssVar } from '@chakra-ui/theme-tools';
import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const $bg = cssVar('tooltip-bg');
const $fg = cssVar('tooltip-fg');
const $arrowBg = cssVar('popper-arrow-bg');

const baseStyle = defineStyle({
  bg: $bg.reference,
  color: $fg.reference,
  [$bg.variable]: 'colors.neutral.700',
  [$fg.variable]: 'colors.base.white',
  [$arrowBg.variable]: $bg.reference,
  px: '2',
  py: '1',
  borderRadius: 'base',
  fontWeight: 'medium',
  fontSize: 'sm',
  boxShadow: 'md',
  maxW: 'xs',
  zIndex: 'tooltip',
});

export const tooltipTheme = defineStyleConfig({
  baseStyle,
});
