import { Schemas } from 'types/leap-api';
import { useStateValue } from 'providers';

export type UserRoleGrouping = Schemas['UserRoleGrouping'] | 'ANONYMOUS';

/**
 * Returns the value of `user_role_grouping` from the User object.
 * @returns {UserRoleGrouping}
 */
export const useUserRoleGrouping = (): UserRoleGrouping => {
  const [
    {
      session: { user_role_grouping },
    },
  ] = useStateValue();

  if (user_role_grouping) {
    return user_role_grouping;
  }

  return 'ANONYMOUS';
};
