import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/styled-system';
import { formAnatomy as parts } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const $fg = cssVar('form-control-color');

const baseStyleRequiredIndicator = defineStyle({
  marginStart: '1',
  [$fg.variable]: 'colors.error.500',
  color: $fg.reference,
});

const baseStyleHelperText = defineStyle((props) => {
  return {
    color: props.isDisabled ? 'text.disabled' : 'text.secondary', // no idea why _disabled doesn't work here
    mt: 1,
    ...props.theme.textStyles.labelSmallRegular,
  };
});

const baseStyle = definePartsStyle((props) => {
  return {
    requiredIndicator: baseStyleRequiredIndicator,
    helperText: baseStyleHelperText(props),
  };
});

export const formControlTheme = defineMultiStyleConfig({
  baseStyle,
});
