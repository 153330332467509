import { defineStyleConfig } from '@chakra-ui/react';

export const leapCardTheme = defineStyleConfig({
  baseStyle: {
    background: 'cardsDialog.primaryLight',
    borderRadius: 'lg',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'neutral.300',
  },
  variants: {
    vertical: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
    },
    horizontal: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
});
