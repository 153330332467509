import { jitsu } from './jitsu';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'analytics/react-ga';

export const PageViewTracker = () => {
  const location = useLocation();
  useEffect(() => {
    jitsu.track('pageview', location);
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return null;
};
