import { DashboardLayoutTypes } from './dashboard-layout.types';
import { DashboardLayout as Flex } from './dashboard-layout';

export const DashboardLayoutContent: React.FC<DashboardLayoutTypes> = ({
  children,
  ...rest
}) => {
  return (
    <Flex direction="column" flex="3 3 74%" {...rest}>
      {children}
    </Flex>
  );
};
