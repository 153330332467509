import moment from 'moment';

export const JWTDecode = (token) => {
  var [, base64Url] = token.split('.');
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const tokenIsInvalid = (token, url) => {
  // Ignore the token for unit tests
  if (process.env.NODE_ENV === 'test') {
    return false;
  }

  // mock data requests do not carry a token
  if (!url || url.endsWith('.json')) {
    return false;
  }

  let decodedToken;
  try {
    decodedToken = JWTDecode(token);
  } catch {
    decodedToken = null;
  }

  return (
    !decodedToken ||
    !decodedToken.exp ||
    !moment.isMoment(moment.unix(decodedToken.exp)) ||
    moment().isAfter(moment.unix(decodedToken.exp))
  );
};
