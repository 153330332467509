import { Loading } from 'bam';
import React, { Suspense } from 'react';

interface RouteLoadingFallbackProps {
  children?: React.ReactNode;
}

export const RouteLoadingFallback: React.FC<RouteLoadingFallbackProps> = ({
  children,
}) => <Suspense fallback={<Loading loading={true} />}>{children}</Suspense>;
