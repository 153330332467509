import {
  Box,
  Link,
  useBreakpointValue,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { NavLinkProps } from './nav-link.types';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import { closeSidebar } from 'actions';
import { useNavigationContext } from '../navigation-provider';
import { useStateValue } from 'providers';
import React from 'react';

export const NavLink: React.FC<NavLinkProps> = ({
  activeSection,
  as,
  disabled,
  icon,
  label,
  to,
}) => {
  const currentBreakpoint = useBreakpointValue({
    base: 'base',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
  });
  const { isCollapsed } = useNavigationContext();
  const [, dispatch] = useStateValue();
  const styles = useMultiStyleConfig('NavLink', {
    disabled: !to || disabled,
    hasIcon: icon,
    isCollapsed,
  });

  const sizedIcon = icon
    ? React.cloneElement(icon, {
        boxSize: 6,
      })
    : undefined;

  const handleLinkClick = () => {
    // On smaller screens, close the sidebar when a nav item is clicked
    if (currentBreakpoint === 'base' || currentBreakpoint === 'sm') {
      dispatch(closeSidebar());
    }
  };

  const linkChildren = (
    <>
      {sizedIcon ? <Box __css={styles.icon}>{sizedIcon}</Box> : null}

      {label}
    </>
  );

  if (as) {
    return (
      <Box as={as} __css={styles.container}>
        {linkChildren}
      </Box>
    );
  }

  if (!to || disabled) {
    return (
      <Box aria-disabled={disabled} __css={styles.container}>
        {linkChildren}
      </Box>
    );
  }

  return (
    <Link
      as={ReactRouterNavLink}
      className={activeSection ? 'active' : undefined}
      end={false}
      noOfLines={1}
      onClick={handleLinkClick}
      sx={styles.container}
      to={to}
    >
      {linkChildren}
    </Link>
  );
};
