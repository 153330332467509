import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { DescriptionTermProps } from './description-list.types';
import React from 'react';

export const Dt: React.FC<DescriptionTermProps> = ({ children, ...rest }) => {
  const styles = useMultiStyleConfig('DescriptionList');

  return (
    <Box __css={styles.term} as="dt" {...rest}>
      {children}
    </Box>
  );
};
