import { types } from './types';

export const clearLoading = () => {
  return {
    type: types.CLEAR_LOADING,
  };
};

export const closeSidebar = () => {
  return {
    type: types.CLOSE_SIDEBAR,
  };
};

export const loading = () => {
  return {
    type: types.LOADING,
  };
};

export const openSidebar = () => {
  return {
    type: types.OPEN_SIDEBAR,
  };
};

export const toggleHelpMessage = (message, show, position) => {
  return {
    type: types.TOGGLE_HELP_MODAL,
    show: show,
    message: message,
    position: position,
  };
};
