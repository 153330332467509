import { AppMessages } from './components/app-messages';
import { BrowserRouter } from 'react-router-dom';
import { CookieConsent, ScrollToTop, Text, Title } from './components';
import { EndcapProvider } from './showroom/endcap/endcap-provider';
import { FeatureFlag } from './utils/feature-flag';
import { HelpMessage } from './components/_deprecated/HelpTip';
import { MapsProvider } from './providers/maps-provider';
import { NavigationProvider } from './bam/templates/primary/components/navigation-provider';
import { PageViewTracker } from './analytics/page-view-tracker';
import { PersistSearchParams } from 'hooks/use-persist-search-params';
import { Progress } from '@chakra-ui/react';
import { Router } from './routes';
import { Suspense, lazy, useEffect } from 'react';
import { apiClient } from './api/apiClient';
import { renderServerError } from './pages/error/server-error';
import { useCoreWebVitals } from './perf/useCoreWebVitals';
import { useEffectOnce } from 'react-use';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useStateValue } from './providers';
import { useTranslation } from 'react-i18next';
import { useUserRoleGrouping } from './hooks/use-user-role-grouping';
import ErrorBoundary from './components/ErrorBoundary';

const Endcap = lazy(() => import('./showroom/endcap/endcap'));

type Session = {
  brands?: any[];
  email?: string;
  roles?: string[];
  token?: string;
  user_hash?: string;
};

export const App: React.FC = () => {
  const { t } = useTranslation('translation');
  const [{ session }, dispatch]: [{ session: Session }, any] = useStateValue();

  const userRoleType = useUserRoleGrouping();

  useEffectOnce(() => {
    if (session.token && userRoleType === 'ANONYMOUS') {
      window.location.href = '/logout';
    }
  });

  useCoreWebVitals();

  useEffect(() => {
    apiClient.setAuthToken(session.token);
  }, [session.token]);

  useEffect(() => {
    dispatch({ type: 'REMOVE_ALL_MESSAGES' });
    window.Intercom('boot', {
      app_id: process.env.INTERCOM_APP_ID,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.Intercom('boot', {
      app_id: process.env.INTERCOM_APP_ID,
      custom_launcher_selector: '#helpWidget',
      email: session.email
        ? (session.email as string).toLowerCase()
        : undefined,
      token: null,
      user_hash: session.user_hash,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.Intercom]);

  useEffect(() => {
    const isBrandUser =
      session?.roles?.includes('Brand: Editor') ||
      session?.roles?.includes('Brand: Owner');
    const company =
      isBrandUser && session?.email?.indexOf('@leapinc.co') === -1
        ? {
            name: session?.brands?.[0].name,
            id: session?.brands?.[0].id,
          }
        : null;
    window.Intercom('update', {
      app_id: process.env.INTERCOM_APP_ID,
      email: session.email
        ? (session.email as string).toLowerCase()
        : undefined,
      token: null,
      user_hash: session.user_hash,
      company,
    });
  }, [session.brands, session.email, session.roles, session.user_hash]);

  const flags = useFlags();

  return (
    <BrowserRouter>
      <MapsProvider>
        <NavigationProvider>
          <EndcapProvider flags={flags}>
            <ErrorBoundary styledError={renderServerError}>
              <PersistSearchParams
                params={[
                  'code',
                  'hmac',
                  'host',
                  'shop',
                  'state',
                  'timestamp',
                  'utm_source',
                ]}
                skip={[
                  'brand_ids',
                  'currentPage',
                  'limit',
                  'order',
                  'orderBy',
                  'response_id',
                  'state',
                  'store_codes',
                  'timestamp',
                ]}
              />
              <ScrollToTop />
              <Router />
              <HelpMessage />
              <CookieConsent
                buttonValue={t('cookieConsent.buttonLabel')}
                cookieName={t('cookieConsent.cookieName')}
              >
                <Title level={4}>{t('cookieConsent.header')}</Title>
                <Text>{t('cookieConsent.message')}</Text>
              </CookieConsent>
              <AppMessages />
              <FeatureFlag
                flag="endcap"
                enabled={
                  <Suspense fallback={<Progress />}>
                    <Endcap />
                  </Suspense>
                }
              />
            </ErrorBoundary>
          </EndcapProvider>
        </NavigationProvider>
      </MapsProvider>
      <PageViewTracker />
    </BrowserRouter>
  );
};
