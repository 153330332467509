import {
  ComponentStyleConfig,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const baseStyle = defineStyle({
  appearance: 'none',
  bg: 'transparent',
  border: 'none',
  cursor: 'pointer',
  h: 6,
  outline: 'none',
  p: 0,
  w: 6,
});

export const closeButtonTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle: baseStyle,
});
