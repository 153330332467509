import { DialogBodyProps } from './dialog.types';
import { ModalBody, forwardRef } from '@chakra-ui/react';

const sizeToPaddingX = {
  sm: '4',
  md: '6',
};

/**
 * DialogBody is a wrapper for [Chakra UI Modal Body](https://chakra-ui.com/docs/components/modal/usage)
 */
export const DialogBody = forwardRef<DialogBodyProps, 'div'>((props, ref) => {
  const { children, size, ...rest } = props;
  return (
    <ModalBody
      {...rest}
      ref={ref}
      px={size ? sizeToPaddingX : { base: '4', md: '6' }}
    >
      {children}
    </ModalBody>
  );
});
