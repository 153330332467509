import { Schemas } from 'types/leap-api';
import { apiClient } from 'api/apiClient';

export const postBrandUsers = async (
  user: Schemas['UserCreateRequest']
): Promise<Schemas['CreateUsersResponse']> => {
  const userData = {
    ...user,
    display_name: user.full_name,
  };
  return await apiClient.callFetch('/brand/users', {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(userData),
  });
};
