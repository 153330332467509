import { Analytics } from 'pages/retail/analytics/analytics';
import { LookerAnalyticsPage } from 'types/looker-analytics.types';
import { Navigate, Route } from 'react-router-dom';
import { ProtectedRoute } from '../ProtectedRoute';
import { RouteLoadingFallback } from 'routes/route-loading-fallback';
import { Routes } from '../Routes';
import { lazy } from 'react';
import { useFlags } from '../../showroom/use-flags';
import { useUserRoles } from 'hooks/use-user-roles';

const Account = lazy(() => import('../../pages/admin/account'));
const RetailInsightsRouter = lazy(() => import('./retail-insights-router'));

export const RetailRouter = () => {
  const { marketManagerAppAccess, storeLeaderAppAccess } = useFlags();
  const { userRoles } = useUserRoles();
  let analyticsPages: LookerAnalyticsPage[] = [];

  if (userRoles.includes('Retail: Store Leader') && storeLeaderAppAccess) {
    analyticsPages = storeLeaderAppAccess;
  }
  if (userRoles.includes('Retail: Market Manager') && marketManagerAppAccess) {
    analyticsPages = marketManagerAppAccess;
  }
  if (
    userRoles.includes('Corporate: Customer Success') &&
    marketManagerAppAccess
  ) {
    analyticsPages = marketManagerAppAccess;
  }

  const handleIndexRoute = (analyticsPages: LookerAnalyticsPage[]) => {
    if (analyticsPages.length > 0) {
      return (
        <Navigate to={analyticsPages[0].path ?? ''} state={{ replace: true }} />
      );
    }

    return <Navigate to="/retail/insights/active" state={{ replace: true }} />;
  };

  return (
    <RouteLoadingFallback>
      <Routes>
        <Route path="insights/*" element={<RetailInsightsRouter />} />
        <Route index element={handleIndexRoute(analyticsPages)} />

        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ subject: 'admin:brands:analytics', action: 'get' }}
            />
          }
        >
          <Route path={'account'} element={<Account />} />
        </Route>

        {analyticsPages.map((page, index) => {
          if (page.tabs && page.tabs.length === 0) {
            return null;
          }
          return (
            <Route
              key={`analytic-page-${index}`}
              path={page.path}
              element={
                <Analytics
                  tabs={page.tabs}
                  pageTitle={page.title}
                  pageSubtitle={page.subtitle}
                />
              }
            />
          );
        })}
      </Routes>
    </RouteLoadingFallback>
  );
};
