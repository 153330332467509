import { Box, Heading as ChakraHeading, forwardRef } from '@chakra-ui/react';
import { HeadingProps } from './heading.types';

/**
 * Sizes and variants as defined by [BAM Design System](https://www.figma.com/file/BOiwVLO9uF9CPdDDkykzyr/BAM-Design-System?node-id=33727%3A61436) for Heading.
 * */
export const Heading = forwardRef<HeadingProps, 'h2'>((props, ref) => {
  const { as, children, size, textStyle, variant = 'bold', ...rest } = props;

  if (textStyle) {
    return (
      <Box {...rest} as={as ? as : 'h3'} ref={ref} textStyle={textStyle}>
        {children}
      </Box>
    );
  } else {
    return (
      <ChakraHeading
        {...rest}
        as={as}
        ref={ref}
        size={size ? size : { base: 'sm', md: 'lg' }}
        variant={variant}
      >
        {children}
      </ChakraHeading>
    );
  }
});

Heading.displayName = 'Heading';
