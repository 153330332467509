import {
  TabList as ChakraTabList,
  TabListProps,
  forwardRef,
} from '@chakra-ui/react';
import { cx } from '@chakra-ui/shared-utils';
import { useTabsSelectorPrefix } from './tabs';

export const TabList = forwardRef<TabListProps, 'div'>(
  ({ children, className, ...rest }, ref) => {
    const { selectorPrefix } = useTabsSelectorPrefix();
    return (
      <ChakraTabList
        {...rest}
        className={cx(
          className,
          selectorPrefix ? `${selectorPrefix}-tabList` : null
        )}
        ref={ref}
      >
        {children}
      </ChakraTabList>
    );
  }
);

TabList.displayName = 'TabList';
