import { apiClient } from 'api/apiClient';

/*
 * This endpoint makes a GET request that does multiple things: makes a request to portal API
 * to create the Shopify and Klayvio adapters, redirects to Shopify to retrieve the adapter token and
 * make app permission request. User must affirm for adapter to be connected.
 * */
export const getBrandAdapterShopifyAuthorize = async (
  instance: string
): Promise<any> => {
  return await apiClient.callFetch(
    `/brand/adapter/oauth/shopify/authorize?instance=${instance}&redirect_uri=${document.location.href}%3Fadapter%3Dshopify`,
    {},
    true
  );
};
