import { Schemas } from 'types/leap-api';

export type BrandProfileSections = keyof Pick<
  Schemas['BrandProfile'],
  | 'financial_health_assessment'
  | 'hiring_training'
  | 'integrations'
  | 'marketing'
  | 'overview'
  | 'product_merchandising'
  | 'social'
  | 'store_design'
  | 'store_operations'
  | 'visual_merchandising'
>;

interface OverviewEdit {
  section: 'overview';
  data?: Schemas['BrandOverview'];
}

interface VisualMerchEdit {
  section: 'visual_merchandising';
  data?: Schemas['VisualMerchandising'];
}

interface ProductMerchEdit {
  section?: 'product_merchandising';
  data?: Schemas['ProductMerchandising'];
}

interface StoreDesignEdit {
  section?: 'store_design';
  data?: Schemas['StoreDesign'];
}

interface MarketingEdit {
  section?: 'marketing';
  data?: Schemas['Marketing'];
}

interface StoreOperationsEdit {
  section?: 'store_operations';
  data?: Schemas['StoreOperations'];
}

interface IntegrationsEdit {
  section?: 'integrations';
  data?: Schemas['Integrations'];
}

interface SocialEdit {
  section?: 'social';
  data?: Schemas['Social'];
}

interface ContactsEdit {
  section?: 'contacts';
  data?: Schemas['User'][];
}

export type EditState =
  | OverviewEdit
  | VisualMerchEdit
  | ProductMerchEdit
  | StoreDesignEdit
  | StoreOperationsEdit
  | MarketingEdit
  | IntegrationsEdit
  | SocialEdit
  | ContactsEdit;

export interface BrandProfileState {
  edit?: EditState;
}

export const brandProfileInitialState: BrandProfileState = {
  edit: undefined,
};

export type EditBrandProfileSection = Action<'EDIT_BRAND_PROFILE', EditState>;

export type ClearEditBrandProfileSection = Action<'CLEAR_EDIT_BRAND_PROFILE'>;

type BrandProfileAction =
  | EditBrandProfileSection
  | ClearEditBrandProfileSection;

const brandProfile = (
  state = brandProfileInitialState,
  action: BrandProfileAction
): BrandProfileState => {
  switch (action.type) {
    case 'EDIT_BRAND_PROFILE': {
      return {
        ...state,
        edit: {
          ...action.data,
        },
      };
    }
    case 'CLEAR_EDIT_BRAND_PROFILE': {
      return {
        ...state,
        edit: undefined,
      };
    }
    default:
      return state;
  }
};

export default brandProfile;
