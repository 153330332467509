import { ComponentWithAs } from '@chakra-ui/react';
import { IconProps } from '../icon-props';
import { createIcon } from '@chakra-ui/icons';
/**
 * All icons are auto generated from the raw svg files exported from untitled ui.
 * They are then processed with svgr, and using a custom template created as a Chakra Icon.
 * See [Chakra ui Icon usage](https://chakra-ui.com/docs/components/icon/usage) and [svgr](https://react-svgr.com/) for more.
 * Do not update these icon files manually, as your change will be lost the next time the icons are overwritten.
 * @see https://chakra-ui.com/docs/components/icon/usage for more documentation
 * @see https://react-svgr.com/ for more on the tool used to generate the components
 * @see svgr-icon-template.js for the template
 */
export const ChevronRightDouble: ComponentWithAs<'svg', IconProps> = createIcon(
  {
    displayName: 'ChevronRightDouble',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
      <>
        <path
          fill="none"
          d="M6 17L11 12L6 7M13 17L18 12L13 7"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
  }
);
export default ChevronRightDouble;
