import {
  AlertDescription as ChakraAlertDescription,
  AlertDescriptionProps as ChakraAlertDescriptionProps,
  forwardRef,
} from '@chakra-ui/react';

type AlertDescriptionProps = ChakraAlertDescriptionProps;

/**
 * Dispaly text content inside of an `Alert`
 * see [Chakra Alert](https://chakra-ui.com/docs/components/alert/usage) for more.
 *
 * ```tsx
 * <Alert status="warning">
 *   <AlertDescription>
 *    The quick brown fox jumped over the lazy dog?!
 *   </AlertDescription>
 * </Alert>
 * ```
 */
export const AlertDescription = forwardRef<AlertDescriptionProps, 'div'>(
  (props, ref) => {
    return <ChakraAlertDescription {...props} ref={ref} />;
  }
);
