import { AnyMongoAbility, defineAbility } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { useStateValue } from './stateProvider';
import React, { createContext, useContext } from 'react';

interface AbilityProviderProps {
  children: React.ReactNode;
  mockAbilities?: any;
}

export const AbilityContext = createContext<AnyMongoAbility | null>(null);

export const defineAbilitiesFor = (permissions) => {
  return defineAbility((can) => {
    permissions &&
      permissions.forEach((permission) => {
        const splitPermission = permission.split('.');
        can(splitPermission[1], splitPermission[0]);
      });
  });
};

export const AbilityProvider: React.FC<AbilityProviderProps> = ({
  children,
  mockAbilities,
}) => {
  const [
    {
      session: { permissions },
    },
  ] = useStateValue();

  const abilities = defineAbilitiesFor(
    mockAbilities ? mockAbilities : permissions
  );

  return (
    <AbilityContext.Provider value={abilities}>
      {children}
    </AbilityContext.Provider>
  );
};

export const Can = createContextualCan(AbilityContext.Consumer);

export const useAbility = () => useContext(AbilityContext);
