import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const parts = ['container', 'notification'];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts);

const baseStyleContainer = defineStyle(({ isCollapsed }) => {
  return {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mb: 0,
    position: 'relative',
    width: isCollapsed ? 12 : '100%',
  };
});

const baseStyleNotification = defineStyle(({ isCollapsed }) => {
  return {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    ml: isCollapsed ? '0' : 'auto',
    position: 'absolute',
    right: isCollapsed ? '-0.375rem' : 3,
    top: isCollapsed ? '-0.375rem' : 3,
  };
});

const baseStyle = definePartsStyle((props) => ({
  container: baseStyleContainer(props),
  notification: baseStyleNotification(props),
}));

export const navItemTheme = defineMultiStyleConfig({
  baseStyle,
});
