import { Anchor } from 'components/navigation/Link';
import { StyledTitle } from 'components/typography/Titles';
import styled from '@emotion/styled';

export const CardRow = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  * {
    flex: 1;
    min-height: 20rem;
    margin: 1rem;
  }
`;

export const LearnMoreLink = styled(Anchor)`
  font-size: 0.875rem;
  line-height: 1;
  white-space: nowrap;
`;

export const Card = styled.div`
  border-radius: 0.75rem;
  max-width: 95vw;
  padding: 1.125rem 0.875rem;
  overflow: hidden;
  position: relative;

  & + & {
    margin: 1rem 0 0;
  }

  ${StyledTitle} {
    margin-bottom: 0.33333333em;
  }

  ${LearnMoreLink} {
    display: block;
    margin-left: -0.875rem;
    margin-right: -0.875rem;
    padding-bottom: 0.125rem;
    padding-top: 1.25rem;
    text-align: center;
    width: calc(1.75rem + 100%);

    ${({ theme: { colorsDeprecated } }) => `
      border-top: 1px solid ${colorsDeprecated('foreground', 0.3)};
    `}
  }

  ${({ theme: { breakpoint, colorsDeprecated } }) => `
    background-color: ${colorsDeprecated('chrome')};
    box-shadow: 6px 6px 54px 0px ${colorsDeprecated('black', 0.05)};
    @media only screen and (min-width: ${breakpoint('sm')}) {
      max-width: 100vw; 
      padding: 1.125rem 1.25rem;

      ${LearnMoreLink} {
        border-top: none;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 1.25rem;
        text-align: left;
        top: 1.125rem;
        width: auto;
      }
    } 

    @media only screen and (min-width: ${breakpoint('md')}) {
      padding: 1.5rem 2rem;
    }
  `}
`;
