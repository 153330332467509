import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { listAnatomy as parts } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle(({ theme }) => ({
  container: {
    ml: 2,
    my: 0,
    pl: 2,
  },
  item: {
    color: 'text.primary',
    mb: 2,
    ...theme.textStyles.paragraphRegular,
    '&:last-of-type': {
      mb: 0,
    },
  },
}));

export const listTheme = defineMultiStyleConfig({ baseStyle });
