import {
  ComponentStyleConfig,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const baseStyle = defineStyle(({ theme }) => {
  return {
    color: 'text.primary',
    fontFamily: 'InterVariable, sans-serif',
    verticalAlign: 'top',
    ...theme.textStyles.labelLargeRegular,
    _placeholder: {
      ...theme.styleTokens.placeholder,
    },
  };
});

const sizes = {
  sm: defineStyle(({ theme }) => {
    return {
      borderRadius: 'lg',
      height: 20,
      minHeight: 20,
      padding: 2,
      ...theme.textStyles.paragraphRegular,
    };
  }),
  lg: defineStyle(({ theme }) => {
    return {
      borderRadius: 'lg',
      height: '7.5rem',
      minHeight: '7.5rem',
      padding: 2,
      ...theme.textStyles.paragraphRegular,
    };
  }),
};

const variantOutline = defineStyle(({ theme }) => {
  return {
    ...theme.styleTokens.defaultBorder,
    _disabled: {
      ...theme.styleTokens.disabledInput,
      _placeholder: {
        color: 'text.disabled',
      },
    },
    _focusVisible: {
      border: 'none',
      boxShadow: 'none',
      padding: '0.5625rem', // fix that annoying shift on focus
      ...theme.styleTokens.focusVisible,
      _placeholder: {
        color: 'text.disabled',
      },
    },
    _invalid: {
      boxShadow: 'none',
      ...theme.styleTokens.invalidBorder,
      _disabled: {
        borderColor: 'neutral.300',
      },
    },
  };
});

const variants = {
  outline: variantOutline,
};

export const textareaTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    variant: 'outline',
  },
});
