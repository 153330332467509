import { cssVar, defineStyle } from '@chakra-ui/styled-system';
import { textStyles } from './text-styles';

const $borderColor = cssVar('chakra-colors-chakra-border-color');
const $placeholderColor = cssVar('chakra-colors-chakra-placeholder-color');
const $bodyBg = cssVar('chakra-colors-chakra-body-bg');
const $bodyText = cssVar('chakra-colors-chakra-body-text');

/**
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/styles.ts
 */
export const globalStyles = defineStyle({
  body: {
    [$bodyBg.variable]: 'colors.base.white', // not sure why, but I have to preface with colors for these variable overrides instead
    [$bodyText.variable]: 'text.primary',
    fontFamily: 'body',
    m: 0,
    minHeight: '100vh',
    minWidth: '20rem',
    overflowX: 'hidden',
  },
  code: {
    backgroundColor: 'primary.50',
    color: 'primary.700',
    p: 1,
  },
  h1: {
    mt: 0,
  },
  h2: {
    mt: 0,
  },
  h3: {
    mt: 0,
  },
  h4: {
    mt: 0,
  },
  h5: {
    mt: 0,
  },
  h6: {
    mt: 0,
  },
  p: {
    letterSpacing: 'normal',
    mt: 0,
    ...textStyles.paragraphRegular,
  },
  '*, *::before, &::after': {
    [$borderColor.variable]: 'colors.neutral.300',
    wordWrap: 'break-word',
  },
  '*::placeholder': {
    [$placeholderColor.variable]: 'colors.neutral.300',
  },
});
