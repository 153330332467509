import styled from '@emotion/styled';

import { Link as OgLink } from 'react-router-dom';

export const StyledLink = styled(OgLink)`
  color: ${({ theme }) => theme.colorsDeprecated('link')};
  text-decoration: none;
  cursor: pointer;
  &:visited {
    color: ${({ theme }) => theme.colorsDeprecated('link', 0.5)};
  }
`;

export const Anchor = styled.a`
  color: ${({ theme }) => theme.colorsDeprecated('link')};
  text-decoration: none;
  cursor: pointer;
  &:visited {
    color: ${({ theme }) => theme.colorsDeprecated('link', 0.5)};
  }
`;

export const Link = ({ onClick, to, children, target }) => {
  return (
    <StyledLink onClick={onClick} to={to} target={target}>
      {children}
    </StyledLink>
  );
};
