import { JWTDecode } from 'utils';
import { SurveyStatusEnums } from '../types/Enums';
import { apiClient } from 'api/apiClient';
import { buildAdminRetailUserIdentify } from 'utils/session/build-admin-retail-user-identify';
import { buildBrandUserIdentify } from 'utils/session/build-brand-user-identify';
import { fetchBrand } from 'actions/brand';
import { getSessionUserId } from '../utils/session/get-session-user-id';
import { getToken, postClientsSignup } from 'api';
import { getUser } from 'api/get-user';
import { types } from './types';

export const doAuth = async (login, ldClient, authResponse) => {
  const res = authResponse ? authResponse : await getToken(login);
  const jwt = JWTDecode(res.accessToken);

  apiClient.setAuthToken(res.accessToken);
  const sessionUserId = getSessionUserId(jwt);
  const user = await getUser();

  const isBrandUser = user.user_role_grouping === 'BRAND';

  let brandAction = undefined;

  if (isBrandUser) {
    brandAction = await fetchBrand();

    const brandUserObject = buildBrandUserIdentify(sessionUserId, user);

    await ldClient?.identify(brandUserObject);
  } else {
    const adminUserObject = buildAdminRetailUserIdentify(sessionUserId, user);

    await ldClient?.identify(adminUserObject);
  }

  const loginAction = {
    type: types.LOGIN,
    data: { ...user, ...res, ...jwt },
  };
  apiClient.setAuthToken(loginAction.data.accessToken);

  if (isBrandUser) {
    return [brandAction, loginAction];
  } else {
    return [loginAction];
  }
};

export const brandSignup = async (data, ldClient) => {
  const payload = {
    client: {
      name: data.brandName,
      contact_email: data.email,
      website: data.website,
      client_details: {
        survey_status: SurveyStatusEnums.RED,
        utm_source: data.source || 'NONE',
      },
    },
    user: {
      email: data.email,
      full_name: data.name,
      display_name: data.name,
      password: data.password,
    },
  };

  const brandClient = await postClientsSignup(payload);

  if (
    brandClient?.error?.data?.type === 'error' ||
    brandClient?.data?.type === 'error'
  ) {
    return brandClient?.error ? brandClient?.error : brandClient;
  }

  return await doAuth(data, ldClient);
};
