import {
  Menu as ChakraMenu,
  MenuProps as ChakraMenuProps,
} from '@chakra-ui/react';
import React from 'react';

type MenuProps = ChakraMenuProps;

/**
 * An accessible dropdown menu for the common dropdown menu button design pattern. Menu uses roving tabIndex for focus management.
 *
 * Wrapper for Chakra ui Menu component, [view documentation](https://chakra-ui.com/docs/components/menu). Accepts all props that the chakra component does
 *
 * ```tsx
 * <Menu {...args}>
 *  <MenuButton
 *   as={Button}
 *  >
 *   Menu
 *  </MenuButton>
 *  <MenuList>
 *    <MenuItem icon={<User02 boxSize="5" />} as={Link} to="/profile">
 *      Account
 *     </MenuItem>
 *     <MenuDivider />
 *     <MenuItem
 *       icon={<LogOut01 color="icon.error" boxSize="5" />}
 *        color="error.500"
 *        as={Link}
 *        to="/logout"
 *     >
 *       Logout
 *     </MenuItem>
 *   </MenuList>
 * </Menu>
 * ```
 */
export const Menu: React.FC<MenuProps> = (props) => {
  return <ChakraMenu {...props} />;
};
