import { Schemas } from 'types/leap-api';
import { apiClient } from 'api/apiClient';

export const getAdminBrandsReportsEmbed = async (
  embed?: string
): Promise<Schemas['LookerEmbedReportsResponse']> => {
  return await apiClient.callFetch(
    `/admin/brands/reports/embed?embed_url=${encodeURI(
      embed + '?embed_domain=' + document.location.origin
    )}`
  );
};
