import { Tabs as ChakraTabs, forwardRef } from '@chakra-ui/react';
import { TabsProps } from './tabs.types';
import { createContext } from '@chakra-ui/react-context';

/**
 * Tabs is a wrapper around Chakra's [Tabs](https://chakra-ui.com/docs/components/tabs) component.
 * Provides context for all subcomponents.
 *
 * ```tsx
 *  <Tabs>
 *    <TabList>
 *      <Tab>Foo</Tab>
 *      <Tab>Bar</Tab>
 *      <Tab>Baz</Tab>
 *    </TabList>
 *    <TabPanels>
 *      <TabPanel>Foo stuff</TabPanel>
 *      <TabPanel>Bar stuff</TabPanel>
 *      <TabPanel>Baz stuff</TabPanel>
 *    </TabPanels>
 *  </Tabs>
 * ```
 * */
const [TabsSelectorPrefixProvider, useTabsSelectorPrefix] = createContext<{
  selectorPrefix?: string;
}>({});

export { useTabsSelectorPrefix };

export const Tabs = forwardRef<TabsProps, 'div'>(
  ({ children, selectorPrefix, ...rest }, ref) => {
    return (
      <TabsSelectorPrefixProvider value={{ selectorPrefix }}>
        <ChakraTabs {...rest} ref={ref} size={{ base: 'sm', md: 'lg' }}>
          {children}
        </ChakraTabs>
      </TabsSelectorPrefixProvider>
    );
  }
);

Tabs.displayName = 'Tabs';
