import { types } from 'actions';

export const CategoryList = [
  'ACCOUNT',
  'BRAND',
  'CLIENT',
  'ERROR',
  'FILES',
  'LAUNCH',
  'LOCATION',
  'MESSAGE',
  'SHOPIFY',
  'SURVEY',
];

export const categories = (actionType) => {
  const actions = Object.keys(types);
  const categories = new Map();
  actions.map((type) => {
    const current = CategoryList.find((item) => type.match(new RegExp(item)));
    return categories.set(type, current);
  });

  return categories.get(actionType) || 'UNDEFINED';
};
