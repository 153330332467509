import {
  ComponentStyleConfig,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const baseStyle = defineStyle(({ colorScheme }) => {
  const color = colorScheme === 'white' ? 'base.white' : 'primary.700';
  const styles = {
    textDecoration: 'underline',
    color,
  };

  return {
    color,
    cursor: 'pointer',
    fontFamily: 'inherit',
    _hover: styles,
    _active: styles,
    _visited: styles,
  };
});

const variantAsButton = defineStyle({
  bg: 'none',
  border: 'none',
  padding: 0,
  outline: 'inherit',
});

const variants = {
  asButton: variantAsButton,
};

export const linkTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle,
  variants,
});
