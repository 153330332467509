import { apiClient } from 'api/apiClient';
import { buildUnauthUserIdentify } from 'utils/session/build-unauth-user-identify';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useStateValue } from 'providers';

export const Logout = () => {
  const [{ session }, dispatch] = useStateValue();
  const { isAuthenticated, logout } = useAuth0();
  const ldClient = useLDClient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isAuthenticated) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
    dispatch({ type: 'LOGOUT' });
    const unAuthUserIdentify = buildUnauthUserIdentify();
    ldClient?.identify(unAuthUserIdentify);
    apiClient.clearAuthToken();
    queryClient.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!session.token) {
      return navigate('/', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return null;
};
