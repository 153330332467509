import {
  ComponentStyleConfig,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const baseStyle = defineStyle({
  background: 'neutral.25',
  borderRadius: 'lg',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'neutral.300',
  display: 'inline-flex',
});

export const buttonSelectTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle: baseStyle,
});
