import { ThemePalette } from '../../../types/styled';
import React, { MouseEventHandler } from 'react';
import styled from '@emotion/styled';

interface ButtonWrapperProps {
  events?: boolean;
  disabled?: boolean;
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  ${({
    events,
    theme: {
      button: { main },
      colorsDeprecated,
    },
  }) => `
  box-style: border-box;
  border-radius: ${main.radius};
  height: 2rem;
  display: flex;
  align-items: stretch;
  cursor: pointer;
  align-self: space-around;
  ${events ? 'pointer-events: none;' : ''}
  &.large {
    height: 2.5rem;
    ${ButtonText}{
      font-size: 1rem;
    }
  }
  &.link {
    background: none;
    color: ${colorsDeprecated('link')};
  }
`}
`;

interface ButtonTextProps {
  events?: boolean;
}
export const ButtonText = styled.span<ButtonTextProps>`
  ${({
    events,
    theme: {
      colorsDeprecated,
      font,
      button: { main },
    },
  }) => `
    color: ${colorsDeprecated('background')};
    font-size: ${font('md')};
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    ${events ? 'pointer-events: none;' : ''}
    &.link {
      color: ${colorsDeprecated('link')};
    }
    svg {
      margin-left: 1rem;
      fill: ${main.text.default};
    }
  `}
`;

type ButtonType = 'submit' | 'reset' | 'button';

interface ButtonContainerProps {
  themeColor?: keyof ThemePalette;
  disabled?: boolean;
  type?: ButtonType;
  events?: boolean;
  disableStretch?: boolean;
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  ${({
    themeColor,
    disabled,
    disableStretch,
    theme: {
      button: { main },
      colorsDeprecated,
    },
  }) => `
    cursor: pointer;
    display: flex;
    flex: ${disableStretch ? '' : '1'};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.35rem 1rem;
    border: 1px solid transparent;
    border-radius: ${main.radius};
    outline: none;
    background-color: ${main.background.default};
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    &:hover{
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
    &:active{
      border: 1px solid ${colorsDeprecated('link')};
      box-shadow: 0px 0px 10px ${colorsDeprecated('link', 0.5)};
    }
  
    ${
      disabled
        ? `
            background-color: ${main.background.disabled};
            border: 1px solid ${main.border.disabled};
            ${ButtonText}{
              color: ${main.text.disabled};
              svg{
                fill: ${main.text.disabled};
              }
            }
            &:hover{
              background-color: ${colorsDeprecated('foreground', 0.05)};
            }
            &.link {
              background: none;
              border: 0 none;
              box-shadow: none;
              ${ButtonText}{
                color: ${colorsDeprecated('background')};
              }
            }
            `
        : `
            &.link {
            background: none;
            color: ${colorsDeprecated('link')};
            box-shadow: none;
            }
            `
    }
  
    ${themeColor ? `background-color: ${colorsDeprecated(themeColor)};` : ''}
  `}
`;

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  disableStretch?: boolean;
  icon?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  pointerEvents?: boolean;
  size?: 'large';
  themeColor?: keyof ThemePalette;
  value?: React.ReactNode;
  type?: ButtonType;
  as?: React.ElementType;
  href?: string;
}

const LinkButtonContainer = ButtonContainer.withComponent('a');

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  disableStretch,
  disabled,
  icon,
  onClick,
  pointerEvents,
  size,
  themeColor,
  value,
  as,
  href,
  type = 'button',
}) => {
  const isLink = as && as === 'a';

  const buttonContent = (
    <ButtonText events={pointerEvents} className={className}>
      {value || children}
      {icon}
    </ButtonText>
  );

  return (
    <ButtonWrapper
      disabled={disabled}
      events={pointerEvents}
      className={`${className} ${size}`}
    >
      {as && as === 'a' ? (
        <LinkButtonContainer
          type={isLink ? undefined : type}
          themeColor={themeColor}
          disabled={disabled}
          disableStretch={disableStretch}
          events={pointerEvents}
          className={className}
          as={as}
          href={href}
        >
          {buttonContent}
        </LinkButtonContainer>
      ) : (
        <ButtonContainer
          type={isLink ? undefined : type}
          themeColor={themeColor}
          disabled={disabled}
          disableStretch={disableStretch}
          events={pointerEvents}
          onClick={disabled ? undefined : onClick}
          className={className}
          as={as}
        >
          {buttonContent}
        </ButtonContainer>
      )}
    </ButtonWrapper>
  );
};
