import { Link as ChakraLink, forwardRef } from '@chakra-ui/react';
import { LinkExternal } from 'bam/icons';
import { LinkProps } from './link-types';

/**
 * Wrapper component for [Chakra link](https://chakra-ui.com/docs/components/link)
 *
 * ```tsx
 * <Link href="https://www.leapinc.co">I'm a link</Link>
 *
 * // using react router link
 * <Link as={ReactRouterLink} to="/signup">I'm a link rendered using react router under the hood!</Link>
 * ```
 *
 * To compose header link to say google maps for example
 * ```tsx
 * <Link href={`https://www.google.com/maps/place/${encode('34 Greene St New York, New York')}`} variant="headerLink">34 Greene St New York, New York</Link>
 * ```
 */
export const Link = forwardRef<LinkProps, 'a'>((props, ref) => {
  const {
    disableUnderline = false,
    lightColorText = false,
    children,
    ...rest
  } = props;
  const colorScheme = lightColorText ? 'white' : 'primary';

  return (
    <ChakraLink
      ref={ref}
      colorScheme={colorScheme}
      sx={disableUnderline ? { _hover: { textDecoration: 'none' } } : undefined}
      {...rest}
    >
      {children} {rest.isExternal ? <LinkExternal ml="1" /> : null}
    </ChakraLink>
  );
});

Link.displayName = 'Link';
