import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * used to monitor location, and if initial load has query string params
 * that should continue to be present on the query string after navigating
 * adds those back as the location changes.
 *
 * @example usePersistQueryStringParams(['utm_source]);
 */

/*
 *
 *
 *
    params.reduce((acc, param) => {
      if (searchParams.has(param)) {
        acc[param] = searchParams.get(param);
        return acc;
      }
      return acc;
    }, {})
 */

export function usePersistSearchParams(params: string[], skip: string[] = []) {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsRef = useRef(
    params.reduce((acc, param) => {
      if (searchParams.has(param)) {
        acc[param] = searchParams.get(param);
        return acc;
      }
      return acc;
    }, {})
  );
  /* eslint-disable */
  useEffect(() => {
    const hasSkipParams = skip.some((param) => searchParams.has(param));
    if (!hasSkipParams) {
      const hasAllParams = params.every((param) => searchParams.has(param));
      if (paramsRef.current && !hasAllParams) {
        setSearchParams({ ...paramsRef.current }, { replace: true });
      }
    }
  }, [params, setSearchParams]);
  /* eslint-enable */
}

interface PersistSearchParamsProps {
  params: string[];
  skip: string[];
}

/**
 * Wrapper component for the usePersistQuerystringParams
 * hook
 * @example <PersistSearchParams params={['utm_source']} />
 */
export function PersistSearchParams({
  params,
  skip,
}: PersistSearchParamsProps) {
  usePersistSearchParams(params, skip);
  return null;
}
