import { App } from './App';
import { lazy } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Offline = lazy(() => import('./pages/error/offline'));

/*
 * Handles rendering the App or Offline mode (for maintenance, etc). Controlled
 * via `app-offline` flag in LaunchDarkly. More info [here](https://gitlab.com/groups/leapinc/-/wikis/Our-Practices/Enabling-offline-mode-for-the-Leap-App).
 * */
export const AppOffline: React.FC = () => {
  const flags = useFlags();
  const params = new URLSearchParams(window.location.search);

  if (flags.appOffline.status === 'offline' && !params.get('debug_offline')) {
    return <Offline />;
  }

  return <App />;
};
