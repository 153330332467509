import { Card } from 'components';

export const HelpShopify = () => {
  return (
    <Card>
      <article dir="ltr">
        <link
          rel="stylesheet"
          href="https://static.intercomassets.com/assets/help_center-22d105e648d60ed66a3ae02c871a7c820fbe78719a11455bc4ee69df0e27414b.css"
          media="all"
        />
        <h2 id="h_23f50cd174" data-post-processed="true">
          Leap Services Shopify App
        </h2>
        <p className="no-margin">
          All automated data transfer between the Leap Platform and Brand
          ecommerce platform is handled by the <b>Leap Services</b> Shopify App.
          The app is installed by the Leap support team when the connection is
          configured.
        </p>
        <div className="intercom-container">
          <img src="https://downloads.intercomcdn.com/i/o/387167351/d0a19edffabb57ff866800e2/Integration.png" />
        </div>
        <p className="no-margin">
          <b>Figure 1</b>. The platform integration is bi-directional by
          default, but can be configured to go one-way. Leap recommends enabling
          the bi-directional integration to enable omni-merchant capabilities.
        </p>
      </article>
    </Card>
  );
};
