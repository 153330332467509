import { ComponentWithAs } from '@chakra-ui/react';
import { IconProps } from '../icon-props';
import { createIcon } from '@chakra-ui/icons';
/**
 * All icons are auto generated from the raw svg files exported from untitled ui.
 * They are then processed with svgr, and using a custom template created as a Chakra Icon.
 * See [Chakra ui Icon usage](https://chakra-ui.com/docs/components/icon/usage) and [svgr](https://react-svgr.com/) for more.
 * Do not update these icon files manually, as your change will be lost the next time the icons are overwritten.
 * @see https://chakra-ui.com/docs/components/icon/usage for more documentation
 * @see https://react-svgr.com/ for more on the tool used to generate the components
 * @see svgr-icon-template.js for the template
 */
export const Users02: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'Users02',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 4C7.567 4 6 5.567 6 7.5C6 9.433 7.567 11 9.5 11C11.433 11 13 9.433 13 7.5C13 5.567 11.433 4 9.5 4ZM4 7.5C4 4.46243 6.46243 2 9.5 2C12.5376 2 15 4.46243 15 7.5C15 10.5376 12.5376 13 9.5 13C6.46243 13 4 10.5376 4 7.5ZM15.1045 3.02273C15.3503 2.52815 15.9505 2.32646 16.445 2.57225C18.2536 3.47103 19.5 5.33904 19.5 7.5C19.5 9.66096 18.2536 11.529 16.445 12.4278C15.9505 12.6735 15.3503 12.4719 15.1045 11.9773C14.8587 11.4827 15.0604 10.8825 15.555 10.6367C16.7098 10.0628 17.5 8.87276 17.5 7.5C17.5 6.12724 16.7098 4.9372 15.555 4.36327C15.0604 4.11749 14.8587 3.51731 15.1045 3.02273ZM9.5 17C6.96123 17 4.58752 18.3253 2.78632 20.6178C2.44512 21.0521 1.81646 21.1275 1.38219 20.7863C0.94791 20.4451 0.872463 19.8165 1.21367 19.3822C3.30545 16.7198 6.21712 15 9.5 15C12.7829 15 15.6945 16.7198 17.7863 19.3822C18.1275 19.8165 18.0521 20.4451 17.6178 20.7863C17.1835 21.1275 16.5549 21.0521 16.2137 20.6178C14.4125 18.3253 12.0388 17 9.5 17ZM17.0889 16.3541C17.3166 15.851 17.9091 15.6276 18.4123 15.8553C20.0902 16.6146 21.5741 17.8393 22.7863 19.3822C23.1275 19.8165 23.0521 20.4451 22.6178 20.7863C22.1835 21.1275 21.5549 21.0521 21.2137 20.6178C20.171 19.2907 18.9327 18.2861 17.5877 17.6775C17.0846 17.4498 16.8612 16.8573 17.0889 16.3541Z"
        fill="currentColor"
      />
    </>
  ),
});
export default Users02;
