import { apiClient } from 'api/apiClient';

/*
 * This endpoint makes a GET request that does multiple things: makes a request to portal API
 * to create the Shopify and Klayvio adapters, passes an existing Shopify  adapter token and
 * make app permission request. User must affirm for adapter to be connected.
 * */
export const getBrandAdapterShopifyInstall = async (
  params: string
): Promise<any> => {
  return await apiClient.callFetch(
    `/brand/adapter/oauth/shopify/app_install?${params}`,
    { method: 'GET' },
    true
  );
};
