import { LeapUseQueryOptions, useQuery } from 'api/useQuery';
import { Schemas } from 'types/leap-api';
import { getAdminBrandsReportsEmbed } from 'api/admin/brands/reports/get-admin-brands-reports-embed';

interface UseGetAdminBrandsReportsEmbedArgs {
  embed: string | undefined;
}

export function useGetAdminBrandsReportsEmbed(
  { embed }: UseGetAdminBrandsReportsEmbedArgs,
  options?: LeapUseQueryOptions
) {
  const enabled = options && 'enabled' in options ? options.enabled : true;
  const queryResult = useQuery<Schemas['LookerEmbedReportsResponse'], Error>(
    ['adminBrandsReportsEmbed', { embed }],
    () => getAdminBrandsReportsEmbed(embed),
    {
      cacheTime: 0,
      enabled,
      onSuccessEvent: options?.onSuccessEvent,
    }
  );

  return queryResult;
}
