import { Theme } from '@emotion/react';
import { ThemePaletteKeys } from '../../../types/styled';
import React from 'react';
import styled from '@emotion/styled';

type HeaderKeys = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

interface StyledTitleProps {
  color?: ThemePaletteKeys;
  level?: keyof Theme['title'];
  size?: string;
  weight?: number;
  marginBottom?: string;
}

export const StyledTitle = styled.div<StyledTitleProps>`
  ${({
    color = 'text',
    level = 1,
    size,
    marginBottom,
    theme: { breakpoint, colorsDeprecated, title },
    weight = 500,
  }) => `
    font-size: ${size || `${title[level]}rem`};
    font-weight: ${weight};
    z-index: 1;
    color: ${colorsDeprecated(color)};
    margin: ${marginBottom ? `0 0 ${marginBottom} 0` : '0'};
    padding: 0;
    line-height: 1.25;
    text-indent: 0;
    @media only screen and (max-width: ${breakpoint('sm')}) {
      max-width: 90vw;
    }
    &.large {
      font-size: 2.5rem;
      font-weight: 500;
    }
  `}
`;

interface HighlightSpanProps {
  color?: ThemePaletteKeys;
}

export const HighlightSpan = styled.span<HighlightSpanProps>`
  ${({ color = 'text', theme: { breakpoint, colorsDeprecated } }) => `
    color: ${colorsDeprecated(color)};
    padding: 0;
    @media only screen and (max-width: ${breakpoint('sm')}) {
      background-color: transparent;
      display: block;
    }
  `}
`;

interface StyledSubTitleProps {
  color?: ThemePaletteKeys;
}
export const StyledSubTitle = styled.h3<StyledSubTitleProps>`
  ${({ color = 'text', theme: { colorsDeprecated, defaults } }) => `
    font-size: 1.6rem;
    font-weight: 300;
    z-index: 1;
    color: ${colorsDeprecated(color)};
    padding: 0 ${defaults('padding')};
    line-height: 1.8rem;
    text-indent: 0;
  `}
`;

export type TitleLevel = keyof Theme['title'];

interface TitleProps {
  className?: string;
  as?: HeaderKeys;
  children?: React.ReactNode;
  color?: ThemePaletteKeys;
  level?: TitleLevel;
  size?: string;
  weight?: number;
  marginBottom?: string;
}
export const Title: React.FC<TitleProps> = ({
  children,
  color,
  level,
  marginBottom,
  ...rest
}) => {
  return (
    <StyledTitle
      level={level}
      marginBottom={marginBottom}
      {...rest}
      color={color}
    >
      <HighlightSpan color={color}>{children}</HighlightSpan>
    </StyledTitle>
  );
};

interface SubTitleProps {
  as?: HeaderKeys;
  children?: React.ReactNode;
  color?: ThemePaletteKeys;
}
export const SubTitle: React.FC<SubTitleProps> = ({ as, children, color }) => {
  return (
    <StyledTitle as={as} color={color}>
      {color && <HighlightSpan color={color}>{children}</HighlightSpan>}
      {!color && children}
    </StyledTitle>
  );
};
