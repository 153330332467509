import { AlertProps as ChakraAlertProps } from '@chakra-ui/react';
import { createContext } from '@chakra-ui/react-context';

export interface AlertContext {
  status: Exclude<ChakraAlertProps['status'], 'loading'>;
}

export const [AlertProvider, useAlertContext] = createContext<AlertContext>({
  name: 'AlertContext',
  hookName: 'useAlertContext',
  providerName: '<Alert />',
});
