import { type NavListProps } from './nav-list.types';
import { UnorderedList } from '@chakra-ui/react';
import React from 'react';

export const NavList: React.FC<NavListProps> = ({ children }) => {
  return (
    <UnorderedList
      alignItems="stretch"
      display="flex"
      flexDirection="column"
      gap="2"
      listStyleType="none"
      m={0}
      p={0}
    >
      {children}
    </UnorderedList>
  );
};
