import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { breadcrumbAnatomy as parts } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleContainer = defineStyle({
  mb: 4,
  '& > ol': {
    m: 0,
    p: 0,
  },
  '.Template-content > &': {
    mt: '-1rem',
  },
});

const baseStyleLink = defineStyle(({ theme }) => {
  return {
    color: 'primary.600',
    ...theme.textStyles.labelSmallRegular,
    _hover: {
      color: 'primary.700',
    },
    '&[aria-current=page]': {
      color: 'text.secondary',
      _hover: {
        cursor: 'default',
        textDecoration: 'none',
      },
    },
  };
});

const baseStyleSeparator = defineStyle({
  h: 4,
  mx: 2,
});

const baseStyle = definePartsStyle((props) => {
  return {
    container: baseStyleContainer,
    link: baseStyleLink(props),
    separator: baseStyleSeparator,
  };
});

export const breadcrumbTheme = defineMultiStyleConfig({
  baseStyle,
});
