import { AddBrandUserTypes } from './add-brand-user.types';
import { email } from '../../../../../../reusable-schemas';
import { optionalLongTextValue } from '../../../../../../reusable-schemas/string';
import { phone } from '../../../../../../reusable-schemas/phone';
import Joi from '@hapi/joi';

export const addBrandUserSchema = Joi.object<AddBrandUserTypes>({
  email: email,
  full_name: Joi.string().min(2).max(200).label('Full Name').required(),
  phone_number: phone,
  title: optionalLongTextValue.label('title'),
});
