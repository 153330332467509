import { BaseButtonProps } from './button-types';
import { Button as ChakraButton, forwardRef } from '@chakra-ui/react';
import React from 'react';

interface ButtonProps extends BaseButtonProps {
  children: React.ReactNode;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
}

export const Button = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const { children, disabled, leftIcon, rightIcon, size, type, ...rest } =
    props;

  return (
    <ChakraButton
      {...rest}
      isDisabled={disabled}
      leftIcon={leftIcon}
      ref={ref}
      rightIcon={rightIcon}
      size={size ? size : { base: 'sm', md: 'lg' }}
      type={type}
    >
      {children}
    </ChakraButton>
  );
});

Button.displayName = 'Button';
