import { Tab as ChakraTab, chakra, forwardRef } from '@chakra-ui/react';
import { TabProps } from './tabs.types';
import { cx } from '@chakra-ui/shared-utils';
import { useTabsSelectorPrefix } from './tabs';

/**
 * Tab is a themed wrapper around Chakra's Tab component
 * */
export const Tab = forwardRef<TabProps, 'button'>(
  ({ children, className, disabled, leftIcon, rightIcon, ...rest }, ref) => {
    const { selectorPrefix } = useTabsSelectorPrefix();

    return (
      <ChakraTab
        {...rest}
        className={cx(
          className,
          selectorPrefix ? `${selectorPrefix}-tab` : null
        )}
        isDisabled={disabled}
        ref={ref}
      >
        {leftIcon && (
          <chakra.span
            alignSelf="center"
            display="inline-flex"
            flexShrink={0}
            mr={2}
            pointerEvents="none"
          >
            {leftIcon}
          </chakra.span>
        )}
        {children}
        {rightIcon && (
          <chakra.span
            alignSelf="center"
            display="inline-flex"
            flexShrink={0}
            ml={2}
            pointerEvents="none"
          >
            {rightIcon}
          </chakra.span>
        )}
      </ChakraTab>
    );
  }
);

Tab.displayName = 'Tab';
