import { Can, useAbility } from 'providers';
import { Loading } from 'bam';
import { NotAuthorized } from 'pages/error/not-authorized';
import { Outlet } from 'react-router-dom';
import { useFlags } from 'showroom/use-flags';

interface ProtectedRouteProps {
  flag?: string;
  protect?: {
    action: string;
    subject: string;
  };
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  flag,
  protect,
}) => {
  const ability = useAbility();
  const flags = useFlags();

  // TODO: what is the right type for this?  console.log reveals this
  // does exist, but not sure the correct type for ability
  if (ability && (ability as any).A.length < 1) {
    return <Loading loading={true} />;
  }

  if (flag) {
    return flags[flag] ? <Outlet /> : <NotAuthorized />;
  }

  return (
    <>
      <Can I={protect?.action} a={protect?.subject}>
        <Outlet />
      </Can>

      <Can I={protect?.action} not a={protect?.subject}>
        <NotAuthorized />
      </Can>
    </>
  );
};
