import { useEffect, useRef, useState } from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useStateValue } from 'providers';

import { toggleHelpMessage } from 'actions';

import { Info } from './Info';

export const HelpTipContainer = styled.span`
  cursor: help;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  align-self: center;
  margin: 0 0.25rem;
  padding: 0 0.25rem;
  width: 1.375rem;
  height: 1rem;
  max-height: 1rem;
  margin: 0;
  ${({ theme: { breakpoint } }) => `
    @media only screen and (max-width: ${breakpoint('sm')}) {
      display: none;
    }
  `}
`;

export const HelpTipMessage = styled.div`
  ${({ position, size, visible, width, theme: { colorsDeprecated } }) => `
  display: ${visible ? 'flex' : 'none'};
  max-width: 30rem;
  min-width: 10rem;
  width: ${width}rem;
  position: fixed;
  top: ${position.y - (size.height + 20)}px;
  left: ${position.x - size.width / 2}px;
  background: ${colorsDeprecated('brand')};
  color: ${colorsDeprecated('background')};
  padding: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  justify-content: flex-start;
  z-index: 999999;
  box-shadow: 0px 0px 3px 2px ${colorsDeprecated('foreground', 0.25)};
  &:after{
    align-self: center;
    border: solid;
    border-color: ${colorsDeprecated('brand')} transparent;
    border-width: 6px 6px 0 6px;
    content: "";
    position: absolute;
    bottom: -6px;
    z-index: 99;
    }
  p,b{
    color: ${colorsDeprecated('background')};
    margin-top: 0;
    padding-bottom: 0.25rem;
    margin-bottom: 0.25rem;
  }
  b {
    font-weight: 600;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0.25rem 0.5rem;
  }
  li {
    font-size: 0.875rem;
    line-height: 1.6;
    em {
      color: rgba(89,126,247, 1);
      font-style: normal;
    }
    & + & {
      margin-top: 1rem;
    }
  }
  `}
`;

export const HelpTipComponent = ({ theme: { colorsDeprecated }, tip }) => {
  const [, dispatch] = useStateValue();
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const toggleHelp = (val, e) => {
    setPosition({ x: e.clientX, y: e.clientY });
    setShow(val);
  };

  useEffect(() => {
    dispatch(toggleHelpMessage(tip, show, position));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <HelpTipContainer
      onMouseEnter={(e) => toggleHelp(true, e)}
      onMouseLeave={(e) => toggleHelp(false, e)}
    >
      <Info fill={colorsDeprecated('foreground', 0.5)} />
    </HelpTipContainer>
  );
};

export const HelpTip = withTheme(HelpTipComponent);

export const HelpMessage = () => {
  const [
    {
      global: {
        helptip: { position = {}, show, tip = '' } = { position: {}, tip: '' },
      },
    },
  ] = useStateValue();
  const [size, setSize] = useState({ width: 0, height: 0 });
  const message = useRef();

  useEffect(() => {
    if (message.current?.offsetWidth > 0)
      setSize({
        width: message.current?.offsetWidth,
        height: message.current?.offsetHeight,
      });
  }, [position]);

  useEffect(() => {}, [message]);

  if (typeof tip === 'string') {
    return (
      <HelpTipMessage
        visible={show}
        width={tip.length * 0.1}
        size={size}
        dangerouslySetInnerHTML={{ __html: tip }}
        position={position}
        ref={message}
      />
    );
  } else {
    return (
      <HelpTipMessage
        visible={show}
        width={'auto'}
        size={size}
        position={position}
        ref={message}
      >
        {tip}
      </HelpTipMessage>
    );
  }
};
