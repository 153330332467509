import {
  Box,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { type NavItemProps } from './nav-item.types';
import { NavLink } from '../nav-link/nav-link';
import { NavList } from '../nav-list/nav-list';
import { Notification } from '../../../../components/data-display/notification';
import { useLocation } from 'react-router-dom';
import { useNavigationContext } from '../navigation-provider';
import React, { ReactElement } from 'react';

export const NavItem: React.FC<NavItemProps> = ({
  children,
  disabled,
  icon,
  label,
  navLinkAs,
  notification,
  notificationLabel,
  to,
  ...rest
}) => {
  const location = useLocation();
  const { isCollapsed } = useNavigationContext();
  const styles = useMultiStyleConfig('NavItem', {
    disabled,
    hasIcon: icon,
    isCollapsed,
  });

  let effectiveTo = to;

  const childrenArray = React.Children.toArray(children).filter(Boolean);

  if (childrenArray.length > 0 && !effectiveTo) {
    const [firstChild] = childrenArray;
    effectiveTo = (firstChild as ReactElement).props.to;
  }

  let activeSection: boolean;

  if (to) {
    // if a value for `to` is passed then subnav should display for any pathname
    // that includes that value
    activeSection = to !== '/' && location.pathname.includes(to as string);
  } else {
    // if no value for `to` is passed then check that pathname matches any `to`
    // value for a subnav item.
    const subNavToValues = childrenArray.map((child) => {
      return (child as ReactElement).props.to;
    });

    activeSection = subNavToValues.some((subNavTo) =>
      location.pathname.includes(subNavTo as string)
    );
  }

  const NavLinkNotification = (
    <>
      <NavLink
        activeSection={activeSection}
        as={navLinkAs}
        disabled={disabled}
        icon={icon}
        label={label}
        to={effectiveTo}
      />

      {notification ? (
        <Box __css={styles.notification}>
          <Notification
            aria-label={notificationLabel}
            color="primary"
            size="sm"
          >
            {notification}
          </Notification>
        </Box>
      ) : null}
    </>
  );

  return (
    <>
      {isCollapsed && childrenArray.length > 0 ? (
        <Popover placement="right-start" trigger="hover">
          <PopoverTrigger>
            <ListItem className="nav-item" sx={styles.container} {...rest}>
              {NavLinkNotification}
            </ListItem>
          </PopoverTrigger>

          <PopoverContent>
            <PopoverBody>
              <NavList>{children}</NavList>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        <ListItem className="nav-item" sx={styles.container} {...rest}>
          {NavLinkNotification}

          {activeSection && childrenArray.length > 0 ? (
            <NavList>{children}</NavList>
          ) : null}
        </ListItem>
      )}
    </>
  );
};
