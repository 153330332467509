import { Outlet } from 'react-router';
import { PresentContext } from './present-context';
import { TemplateContent } from '../components/template-content';
import React from 'react';

/*
 * Used for presentation mode to display content without top and side navigation.
 * */
export const PresentTemplate: React.FC = () => {
  return (
    <PresentContext.Provider value={true}>
      <TemplateContent>
        <Outlet />
      </TemplateContent>
    </PresentContext.Provider>
  );
};

PresentTemplate.displayName = 'PresentTemplate';
