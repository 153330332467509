import { useFlagOverrideContext } from 'showroom/endcap/endcap-provider';
import type { LDFlagSet } from 'launchdarkly-js-sdk-common';

/**
 * replacement for LD useFlags hook. pulls from context, which uses
 * LD useFlags plus overrides
 */
export const useFlags = (): LDFlagSet => {
  const flagContext = useFlagOverrideContext();
  return flagContext?.flags ?? {};
};
