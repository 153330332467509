import { FormControl } from '../form-control';
import { InputProps } from './input.types';
import { SimpleInput } from '../simple-input';
import { forwardRef, useBreakpointValue } from '@chakra-ui/react';

/**
 * Input wrapper for [Chakra Form Control](https://chakra-ui.com/docs/components/form-control) component
 * and associated child components.
 */
export const Input = forwardRef<InputProps, 'input'>(
  (
    {
      autoComplete,
      disabled,
      disableClickRightElement = false,
      disableClickLeftElement = false,
      errorText,
      hintText,
      id,
      invalid,
      label,
      name,
      onBlur,
      onChange,
      optional,
      placeholder,
      required,
      rightElement,
      leftElement,
      type = 'text',
      value,
      size,
      ...rest
    },
    ref
  ) => {
    const breakpointSize = useBreakpointValue({
      base: 'sm',
      md: 'lg',
    });

    const effectiveSize = size ? size : breakpointSize ? breakpointSize : 'sm';

    return (
      <FormControl
        disabled={disabled}
        errorText={errorText}
        hintText={hintText}
        id={id}
        invalid={invalid}
        label={label}
        required={required}
        optional={optional}
        {...rest}
      >
        <SimpleInput
          autoComplete={autoComplete}
          disableClickLeftElement={disableClickLeftElement}
          disableClickRightElement={disableClickRightElement}
          id={id}
          leftElement={leftElement}
          name={name ? name : id}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          rightElement={rightElement}
          size={effectiveSize}
          type={type}
          value={value}
        />
      </FormControl>
    );
  }
);

Input.displayName = 'Input';
