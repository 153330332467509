import { IFrameProps } from './iframe.types';
import { chakra, forwardRef } from '@chakra-ui/react';

/**
 * Chakra UI wrapper for `<iframe/>`.
 * */
export const IFrame = forwardRef<IFrameProps, 'iframe'>(
  ({ height = '100%', id, src, width = '100%' }, ref) => {
    return (
      <chakra.iframe
        border="none"
        display="block"
        height={height}
        id={id}
        overflow="hidden"
        src={src}
        width={width}
        ref={ref}
      />
    );
  }
);

IFrame.displayName = 'IFrame';
