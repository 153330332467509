import { useStateValue } from 'providers';
import React, { createContext, useContext } from 'react';

interface NavigationContextData {
  /**
   * is the navigation panel collapsed or not
   */
  isCollapsed: boolean;
}

export const NavigationContext = createContext<NavigationContextData>({
  isCollapsed: false,
});

/**
 * Shortcut hook to use the Navigation context without having to import it.
 */
export const useNavigationContext = (): NavigationContextData => {
  return useContext(NavigationContext);
};

interface NavigationProps {
  children: React.ReactNode;
}

/**
 * Provider in NavigationList so that a NavigationItem knows if the nav was collapsed
 */
export const NavigationProvider: React.FC<NavigationProps> = ({ children }) => {
  const [{ global }] = useStateValue();
  return (
    <NavigationContext.Provider value={{ isCollapsed: !global.open_sidebar }}>
      {children}
    </NavigationContext.Provider>
  );
};
