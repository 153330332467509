export const shadows = {
  xs: '0 1px 2px rgba(16, 24, 40, .05)',
  sm: '0 1px 3px rgba(16, 24, 40, .1), 0 1px 2px rgba(16, 24, 40, .06)',
  md: '0 4px 8px -2px rgba(16, 24, 40, .1), 0 2px 4px -2px rgba(16, 24, 40, .06)',
  lg: '0 12px 16px -4px rgba(16, 24, 40, .08), 0 4px 6px -2px rgba(16, 24, 40, .03)',
  xl: '0 20px 24px -4px rgba(16, 24, 40, .08), 0 8px 8px -4px rgba(16, 24, 40, .03) ',
  '2xl': '0 24px 48px -12px rgba(16, 24, 40, .18)',
  '3xl': '0 32px 64px -12px rgba(16, 24, 40, .14)',
  '100': '0px 2px 8px rgba(0, 0, 0, 0.12)',
  '200': '0px 4px 12px rgba(0, 0, 0, 0.16)',
  '300': '0px 8px 16px rgba(0, 0, 0, 0.12)',
  '400': '0px 8px 32px rgba(0, 0, 0, 0.16)',
};
