import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/react';
import { modalAnatomy as parts } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleOverlay = defineStyle({
  bg: 'base.overlayBackground',
});

const baseStyleDialog = defineStyle({
  borderRadius: 'lg',
  boxShadow: '400',
});

const baseStyleCloseButton = defineStyle({
  position: 'static',
  top: 'auto',
  insetEnd: 'auto',
});

const baseStyleHeader = defineStyle({
  display: 'flex',
  alignItems: 'center',
  p: '4',
});

const baseStyleBody = defineStyle({
  px: '4',
  pt: '0',
  pb: '4',
});

const baseStyleFooter = defineStyle({
  px: '4',
  pt: '0',
  pb: '4',
});

const baseStyle = definePartsStyle(() => ({
  body: baseStyleBody,
  closeButton: baseStyleCloseButton,
  dialog: baseStyleDialog,
  footer: baseStyleFooter,
  header: baseStyleHeader,
  overlay: baseStyleOverlay,
}));

const sizes = {
  sm: {
    dialog: {
      maxW: 'sm',
    },
  },
  lg: {
    dialog: {
      maxW: 'lg',
    },
  },
  '4xl': {
    dialog: {
      maxW: '4xl',
    },
  },
};

export const modalTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'sm',
  },
});
