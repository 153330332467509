import { useGetBrand } from './api/brand/use-get-brand';

export function useIsBrandOnboarding() {
  const { data: brand } = useGetBrand({
    onSuccessEvent: () => {
      return {
        subject: 'BRAND',
        action: 'REQUESTED',
      };
    },
  });

  return !!(
    brand &&
    brand.stores &&
    brand.stores.filter(
      (store) => store.salesforce_store_stage === 'ONBOARDING'
    ).length > 0
  );
}
