import { AppInfoList } from './components/app-info-list';
import { Heading, LeapCard } from 'bam';
import { Helmet } from 'react-helmet-async';
import React from 'react';

export const AppInfo: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>App Info</title>
      </Helmet>

      <Heading>App Info</Heading>
      <LeapCard p={4}>
        <AppInfoList />
      </LeapCard>
    </>
  );
};
