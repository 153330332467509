import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { tabsAnatomy as parts } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  tablist: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
  },
  tab: {
    whiteSpace: 'nowrap',
  },
});

const sizes = {
  sm: definePartsStyle(({ theme }) => {
    return {
      tablist: {
        height: 12,
        justifyContent: 'flex-start',
      },
      tab: {
        height: 8,
        px: 3,
        py: 2,
        ...theme.textStyles.labelSmallMedium,
      },
    };
  }),
  lg: definePartsStyle(({ theme }) => {
    return {
      tablist: {
        height: 'auto',
      },
      tab: {
        height: 10,
        px: 4,
        py: '.675rem',
        ...theme.textStyles.labelLargeMedium,
      },
    };
  }),
};

const pillVariant = definePartsStyle(({ theme }) => {
  return {
    tablist: {
      gap: 2,
    },
    tab: {
      bg: 'transparent',
      borderRadius: 'lg',
      borderWidth: 0,
      cursor: 'pointer',
      color: 'text.secondary',
      _focusVisible: {
        border: 'none',
        ...theme.styleTokens.focusVisible,
      },
      _hover: {
        bg: 'primary.100',
        color: 'primary.600',
        _visited: {
          color: 'primary.600',
        },
      },
      _selected: {
        bg: 'primary.50',
        color: 'primary.600',
        _visited: {
          color: 'primary.600',
        },
      },
      _visited: {
        color: 'text.secondary',
      },
      _disabled: {
        color: 'text.disabled',
        opacity: 1,
        _hover: {
          bg: 'transparent',
          color: 'text.disabled',
        },
      },
    },
  };
});

const variants = {
  pill: pillVariant,
};

export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    variant: 'pill',
  },
});
