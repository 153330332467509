import { apiClient } from './apiClient';

export const getClient = async () => {
  return await apiClient.fetch(`/brand`);
};

export const postClientsSignup = async (data) => {
  return await apiClient.fetch({
    url: `/brands/signup`,
    options: {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
    },
  });
};
