import { BarChartSquare02 } from '../../../../icons';
import { Nav } from '../nav/nav';
import { NavItem } from '../nav-item/nav-item';
import { NavList } from '../nav-list/nav-list';
import { SubNavItem } from '../sub-nav-item/sub-nav-item';
import {
  formatAnalyticsPath,
  formatAnalyticsTitle,
  showNavOption,
} from 'utils/analytics-nav-menu';
import { useFlags } from '../../../../../showroom/use-flags';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const CorporateUsersNavigation: React.FC = () => {
  const { t } = useTranslation('primary-template');
  const { analyticsPages } = useFlags();

  return (
    <Nav>
      <NavList>
        <NavItem icon={<BarChartSquare02 />} label={t('sidebar.analytics')}>
          {analyticsPages && Array.isArray(analyticsPages)
            ? analyticsPages.map((page) =>
                showNavOption(page, true) ? (
                  <SubNavItem
                    disabled={false}
                    key={page.title}
                    label={formatAnalyticsTitle(page.title)}
                    to={formatAnalyticsPath(page.path, 'CORPORATE')}
                  />
                ) : null
              )
            : null}
        </NavItem>
      </NavList>
    </Nav>
  );
};
