import { Flag } from 'showroom/use-flags-utils';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { useManageFlags } from 'showroom/use-manage-flags';
import React, { createContext, useContext } from 'react';

interface FlagOverrides {
  flags: LDFlagSet;
  setFlag: (flag: Flag) => void;
  localFlags: LDFlagSet;
  removeFlag: (flag: string) => void;
}

export const FlagOverrideContext = createContext<FlagOverrides | undefined>(
  undefined
);

export const useFlagOverrideContext = () => {
  return useContext(FlagOverrideContext);
};

interface EndcapProviderProps {
  children: React.ReactNode;
  flags: LDFlagSet;
}

export const EndcapProvider: React.FC<EndcapProviderProps> = ({
  children,
  flags: LDFlags,
}) => {
  const { flags, setFlag, localFlags, removeFlag } = useManageFlags(LDFlags);

  return (
    <FlagOverrideContext.Provider
      value={{ flags, setFlag, localFlags, removeFlag }}
    >
      {children}
    </FlagOverrideContext.Provider>
  );
};
