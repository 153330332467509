import { AdminPresentRouter } from './admin/admin-present-router';
import { AdminRouter } from './admin';
import { AuthenticatedRoute } from './authenticated-route';
import { BrandRouter } from 'routes/brand';
import { CorporateLogin } from 'pages/auth/corporate-login';
import { CorporateRouter } from './corporate';
import { HelpShopify } from 'pages/help/help-shopify';
import { Logout } from 'pages/auth/logout';
import { Navigate, Route } from 'react-router-dom';
import { NotFound } from 'pages/error/not-found';
import { PresentTemplate, PrimaryTemplate } from 'bam';
import { RetailRouter } from 'routes/retail';
import { RouteLoadingFallback } from 'routes/route-loading-fallback';
import { Routes } from './Routes';
import { TargetedBanner } from 'pages/components/targeted-banner';

import { lazy } from 'react';
import { useUserRoleGrouping } from 'hooks/use-user-role-grouping';

const BrandDashboard = lazy(
  () => import('../pages/brand/dashboard/brand-dashboard')
);
const LoginRefit = lazy(() => import('pages/auth/login/login'));
const PasswordReset = lazy(
  () => import('pages/auth/password-reset/password-reset')
);
const SignUpRefit = lazy(() => import('pages/auth/signup/signup'));

export const Router = () => {
  const userRoleType = useUserRoleGrouping();

  const handleIndexRoute = () => {
    if (userRoleType === 'ADMIN') {
      return <Navigate to="/admin" state={{ replace: true }} />;
    } else if (userRoleType === 'RETAIL') {
      return <Navigate to="/retail" state={{ replace: true }} />;
    } else if (userRoleType === 'CORPORATE') {
      return <Navigate to="/corporate" state={{ replace: true }} />;
    } else if (userRoleType === 'BRAND') {
      return (
        <RouteLoadingFallback>
          <BrandDashboard />
        </RouteLoadingFallback>
      );
    } else {
      return <Logout />;
    }
  };

  return (
    <Routes>
      <Route element={<TargetedBanner />}>
        <Route path="/" element={<AuthenticatedRoute />}>
          <Route path="*" element={<PrimaryTemplate />}>
            <Route path="admin/*" element={<AdminRouter />} />
            <Route path="brand/*" element={<BrandRouter />} />
            <Route path="corporate/*" element={<CorporateRouter />} />
            <Route path="retail/*" element={<RetailRouter />} />
            <Route index element={handleIndexRoute()} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/present/*" element={<PresentTemplate />}>
            <Route path="admin/*" element={<AdminPresentRouter />} />
          </Route>
        </Route>

        <Route
          path="/login"
          element={
            <RouteLoadingFallback>
              <LoginRefit />
            </RouteLoadingFallback>
          }
        />
        <Route path="/internal" element={<CorporateLogin />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/signup"
          element={
            <RouteLoadingFallback>
              <SignUpRefit />
            </RouteLoadingFallback>
          }
        />
        <Route path="/help/shopify" element={<HelpShopify />} />
        <Route
          path="/password_reset"
          element={
            <RouteLoadingFallback>
              <PasswordReset />
            </RouteLoadingFallback>
          }
        />
      </Route>
    </Routes>
  );
};
