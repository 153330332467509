import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/styled-system';
import { orient } from '@chakra-ui/theme-tools';
import { sliderAnatomy as parts } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const $thumbSize = cssVar('slider-thumb-size');
const $trackSize = cssVar('slider-track-size');
const $bg = cssVar('slider-bg');

const baseStyleContainer = defineStyle((props) => {
  const { orientation } = props;

  return {
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    _disabled: {
      opacity: 0.6,
      cursor: 'default',
      pointerEvents: 'none',
    },
    ...orient({
      orientation,
      vertical: { h: '100%' },
      horizontal: { w: '100%' },
    }),
  };
});

const baseStyleTrack = defineStyle((props) => {
  const orientationStyles = orient({
    orientation: props.orientation,
    horizontal: { h: $trackSize.reference },
    vertical: { w: $trackSize.reference },
  });

  return {
    ...orientationStyles,
    overflow: 'hidden',
    borderRadius: '2xl',
    [$bg.variable]: 'colors.neutral.200',
    _dark: {
      [$bg.variable]: 'colors.whiteAlpha.200',
    },
    _disabled: {
      [$bg.variable]: 'colors.neutral.300',
      _dark: {
        [$bg.variable]: 'colors.whiteAlpha.300',
      },
    },
    bg: $bg.reference,
  };
});

const baseStyleThumb = defineStyle((props) => {
  const { orientation } = props;
  const orientationStyle = orient({
    orientation,
    vertical: {
      left: '50%',
      transform: `translateX(-50%)`,
      _active: {
        transform: `translateX(-50%) scale(1.15)`,
      },
    },
    horizontal: {
      top: '50%',
      transform: `translateY(-50%)`,
      _active: {
        transform: `translateY(-50%) scale(1.15)`,
      },
    },
  });

  return {
    ...orientationStyle,
    w: $thumbSize.reference,
    h: $thumbSize.reference,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    outline: 0,
    zIndex: 1,
    borderRadius: 'full',
    bg: 'base.white',
    boxShadow: '100',
    border: '2px solid',
    backgroundColor: 'neutral.25',
    borderColor: 'primary.600',
    transitionProperty: 'transform',
    transitionDuration: 'normal',
    _focusVisible: {
      outlineWidth: '2px',
      outlineColor: 'primary.300',
      outlineOffset: 0,
      borderWidth: 0,
    },
    _disabled: {
      bg: 'neutral.300',
    },
  };
});

const baseStyleFilledTrack = defineStyle((props) => {
  const { colorScheme: c } = props;

  return {
    width: 'inherit',
    height: 'inherit',
    [$bg.variable]: `colors.${c}.500`,
    _dark: {
      [$bg.variable]: `colors.${c}.200`,
    },
    bg: $bg.reference,
  };
});

const baseStyle = definePartsStyle((props) => ({
  container: baseStyleContainer(props),
  track: baseStyleTrack(props),
  thumb: baseStyleThumb(props),
  filledTrack: baseStyleFilledTrack(props),
}));

const sizeLg = definePartsStyle({
  container: {
    [$thumbSize.variable]: `sizes.7`,
    [$trackSize.variable]: `sizes.4`,
  },
});

const sizeMd = definePartsStyle({
  container: {
    [$thumbSize.variable]: `sizes.6`,
    [$trackSize.variable]: `sizes.3`,
  },
});

const sizeSm = definePartsStyle({
  container: {
    [$thumbSize.variable]: `sizes.5`,
    [$trackSize.variable]: `sizes.2`,
  },
});

const sizes = {
  lg: sizeLg,
  md: sizeMd,
  sm: sizeSm,
};

export const sliderTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'sm',
    colorScheme: 'primary',
  },
});
