import { Box, Text as ChakraText, forwardRef } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/system';
import { TextProps } from './text.types';

/**
 * Variants as defined by [BAM Design System](https://www.figma.com/file/BOiwVLO9uF9CPdDDkykzyr/BAM-Design-System?node-id=33727%3A61436) for Paragraph.
 **/
export const Text = forwardRef<TextProps, 'p'>((props, ref) => {
  const { as, noOfLines, textStyle, variant, ...rest } = props;

  if (textStyle) {
    const isLabel =
      textStyle === 'labelXsmallRegular' ||
      textStyle === 'labelSmallRegular' ||
      textStyle === 'labelSmallMedium' ||
      textStyle === 'labelSmallBold' ||
      textStyle === 'labelMediumRegular' ||
      textStyle === 'labelMediumMedium' ||
      textStyle === 'labelMediumBold' ||
      textStyle === 'labelLargeRegular' ||
      textStyle === 'labelLargeMedium' ||
      textStyle === 'labelLargeBold';
    // if textStyle is a label (which has line-height: 1) and text is truncated
    // via noOfLines then add some line-height so descenders don't get cut off.
    const forcedLineHeight: SystemStyleObject = {
      lineHeight: 1.2,
    };

    return (
      <Box
        {...rest}
        as={as}
        noOfLines={noOfLines}
        ref={ref}
        textStyle={textStyle}
        sx={isLabel && noOfLines ? forcedLineHeight : undefined}
      />
    );
  } else {
    return (
      <ChakraText {...rest} noOfLines={noOfLines} ref={ref} variant={variant} />
    );
  }
});

Text.displayName = 'Text';
