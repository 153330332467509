import {
  MenuList as ChakraMenuList,
  MenuListProps as ChakraMenuListProps,
  forwardRef,
} from '@chakra-ui/react';

type MenuListProps = ChakraMenuListProps;

/**
 * Wrapper for Chakra ui MenuList component, [view documentation](https://chakra-ui.com/docs/components/menu). Accepts all props that the chakra component does
 */
export const MenuList = forwardRef<MenuListProps, 'div'>((props, ref) => {
  return <ChakraMenuList {...props} ref={ref} />;
});
