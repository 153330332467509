import {
  MenuDivider as ChakraMenuDivider,
  MenuDividerProps as ChakraMenuDividerProps,
} from '@chakra-ui/react';
import React from 'react';

type MenuDividerProps = ChakraMenuDividerProps;

/**
 * Wrapper for Chakra ui MenuDivider component, [view documentation](https://chakra-ui.com/docs/components/menu). Accepts all props that the chakra component does
 */
export const MenuDivider: React.FC<MenuDividerProps> = (props) => {
  return <ChakraMenuDivider {...props} />;
};
