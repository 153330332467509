import { LookerAnalyticsPage } from 'types/looker-analytics.types';
import { UserRoleGrouping } from 'hooks/use-user-role-grouping';

function showNavOption(
  page: LookerAnalyticsPage = {},
  isContractSigned: boolean
) {
  if (!isContractSigned) {
    return false;
  }

  if (page && page.tabs && page.tabs.length > 0) {
    // ensure that all tabs have "embed" URL
    return page.tabs.every((tab) => tab.embed);
  }

  return false;
}

function formatAnalyticsPath(path: string, userRoleGrouping: UserRoleGrouping) {
  const cleanPath = path.replace('/*', '');
  if (userRoleGrouping === 'BRAND') {
    return `/brand/analytics/${cleanPath}`;
  }

  if (userRoleGrouping === 'ADMIN') {
    return `/admin/analytics/${cleanPath}`;
  }

  if (userRoleGrouping === 'CORPORATE') {
    return `/corporate/analytics/${cleanPath}`;
  }

  if (userRoleGrouping === 'RETAIL') {
    return `/retail/analytics/${cleanPath}`;
  }

  return '';
}

function formatAnalyticsTitle(title: string) {
  return title.replace('analytics', '');
}

export { showNavOption, formatAnalyticsPath, formatAnalyticsTitle };
