import { AbilityProvider, StateProvider, ThemeProvider } from './';
import { initialState as baseState, Reducer as reducers } from 'reducers';

export const GlobalProvider = ({ children, initialState = baseState }) => {
  return (
    <ThemeProvider>
      <StateProvider initialState={initialState} reducer={reducers}>
        <AbilityProvider>{children}</AbilityProvider>
      </StateProvider>
    </ThemeProvider>
  );
};
