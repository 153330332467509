import { AccordionContent } from '../Accordion';
import { ThemePaletteKeys } from '../../../types/styled';
import React from 'react';
import styled from '@emotion/styled';

interface StyledCopyProps {
  color?: ThemePaletteKeys;
  alpha?: number;
  size?: React.CSSProperties['fontSize'];
  disabled?: boolean;
  strikethrough?: boolean;
  mb?: React.CSSProperties['marginBottom'];
  bold?: boolean;
  fontStyle?: React.CSSProperties['fontStyle'];
  fontWeight?: React.CSSProperties['fontWeight'];
}

export const StyledCopy = styled.div<StyledCopyProps>`
  ${({
    size,
    color,
    alpha,
    mb,
    bold,
    fontStyle,
    fontWeight,
    theme: { colorsDeprecated },
  }) => `
    color: ${colorsDeprecated(color, alpha)};
    font-weight: ${fontWeight ? fontWeight : bold ? 600 : 200};
    z-index: 1;
    margin-bottom: ${mb ? mb : ''};
    font-size: ${size || '1rem'};
    font-style: ${fontStyle ? fontStyle : ''};
    line-height: 1.5;
    p {
      color: ${colorsDeprecated(color, alpha)};

      ${AccordionContent} & {
        margin: 0 0 1rem;

        &:last-child {
          margin: 0;
        }
      }
    }
  `}
`;

interface TextProps {
  className?: string;
  children?: React.ReactNode;
  color?: ThemePaletteKeys;
  alpha?: number;
  disabled?: boolean;
  html?: string;
  strikethrough?: boolean;
  type?: ThemePaletteKeys;
  mb?: React.CSSProperties['marginBottom'];
  size?: React.CSSProperties['fontSize'];
  as?: React.ElementType<any>;
  bold?: boolean;
  fontStyle?: React.CSSProperties['fontStyle'];
  fontWeight?: React.CSSProperties['fontWeight'];
}

export const Text: React.FC<TextProps> = ({
  children,
  color = 'text',
  alpha,
  disabled,
  html,
  strikethrough,
  fontStyle,
  type,
  size,
  bold,
  fontWeight,
  ...rest
}) => {
  if (html) {
    return (
      <StyledCopy
        dangerouslySetInnerHTML={{ __html: html }}
        disabled={disabled}
        strikethrough={strikethrough}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        color={type || color}
        alpha={alpha}
        size={size}
        bold={bold}
        {...rest}
      ></StyledCopy>
    );
  }
  return (
    <StyledCopy
      disabled={disabled}
      strikethrough={strikethrough}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      color={type || color}
      alpha={alpha}
      size={size}
      bold={bold}
      {...rest}
    >
      {children}
    </StyledCopy>
  );
};
