import { LDFlagSet } from 'launchdarkly-js-sdk-common';

export interface Flag {
  name: string;
  enabled: boolean;
}
/**
 * simple merge function
 */

export const mergeFlags = (
  ldFlags: LDFlagSet,
  localFlags: LDFlagSet,
  queryStringFlags: LDFlagSet
) => {
  const mergedFlags = {
    ...ldFlags,
    ...localFlags,
    ...queryStringFlags,
  } as LDFlagSet;

  return mergedFlags;
};
/**
 * session storage by env
 */

export const sessionStorageKey = `leap-${process.env.REACT_APP_SERVICE_ENV}-flags`;
/**
 * gathers flags in the format of `flag-<flagname>=<enabled | disabled>
 */

export const convertSearchParamFlags = (searchParams: URLSearchParams) => {
  const searchParamsObj = Object.fromEntries(searchParams);
  const flagParams = {};
  Object.keys(searchParamsObj).forEach((key) => {
    if (key.startsWith('flag-')) {
      const flagName = key.replace('flag-', '');
      flagParams[flagName] = searchParamsObj[key] === 'enabled';
    }
  });
  return flagParams;
};
