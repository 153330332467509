import {
  eventTracker,
  identify,
  setDimension,
} from 'analytics/analytics-utils';
import { intercomEvent } from 'utils';
import { types } from 'actions';

export const analyticsMiddleware = () => (next) => (action) => {
  if (!action.type) {
    return next(action);
  }
  const session = () => {
    const sessionId = Math.random().toString(36).substring(2);
    return { id: sessionId, start: new Date() };
  };

  if (action.type) {
    eventTracker(action);
    intercomEvent(action);
  }

  if (action.type === types.LOGIN) {
    identify(action);
    if (action.data.permissions.find((p) => p.match(/admin/))) {
      setDimension('brand', `leap-admin`);
      setDimension('session', session());
    }
  }

  if (action.type === types.FETCH_BRAND_SUCCESS) {
    const brand = action?.data?.brand?.handle;
    setDimension('brand', brand);
    setDimension('session', session());
  }
  return next(action);
};
