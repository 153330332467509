import { Box, Button } from '../../../../../index';
import { useTranslation } from 'react-i18next';

interface AddUserFormActionsProps {
  isSubmitting?: boolean;
  onClick?: () => void;
}

export const AddBrandUserFormButtons: React.FC<AddUserFormActionsProps> = ({
  isSubmitting,
  onClick,
}) => {
  const { t } = useTranslation('add-user');

  return (
    <Box
      justifyContent={{ base: 'center', md: 'flex-end' }}
      flexDirection={{ base: 'column', md: 'row' }}
      display="flex"
      columnGap="0.5rem"
      rowGap="0.5rem"
    >
      <Button size="sm" variant="secondary" onClick={onClick}>
        {t('actions.cancel')}
      </Button>
      <Button disabled={isSubmitting} type="submit" size="sm">
        {t('actions.add')}
      </Button>
    </Box>
  );
};
