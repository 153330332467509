import { IllustrationProps } from '../illustration.types';

export const ServerErrorIllustration = ({
  height = '100%',
  width = '100%',
  transform,
}: IllustrationProps) => {
  return (
    <svg
      fill="none"
      height={height}
      transform={transform}
      viewBox="0 0 569 421"
      width={width}
    >
      <path
        d="M165.815 68.4392C165.815 68.4392 230.005 117.151 327.59 26.0691C414.254 -54.8181 485.018 72.8038 485.513 133.92C486.155 213.089 398.848 276.436 441.218 328.435C483.587 380.435 357.192 466.303 289.072 391.99C204.332 299.547 181.375 374.657 133.074 374.657C98.408 374.657 27.2348 288.52 75.2973 224.437C115.741 170.512 93.6839 152.615 83.0023 133.92C67.5938 106.957 104.186 33.7731 165.815 68.4392Z"
        fill="#C7D7FE"
      />
      <path
        d="M423.516 176.084C426.966 177.366 430.737 177.451 434.324 176.628C449.354 173.178 501.035 164.043 492.054 204.091C490.099 212.037 459.831 256.534 502.936 272.778C506.094 273.983 509.435 274.641 512.815 274.723C526.086 274.997 552.386 271.863 563.393 260.658C576.69 247.123 562.163 253.542 562.163 253.542C562.163 253.542 511.613 275.601 493.757 252.198C491.77 249.594 490.505 245.812 490.143 242.556C489.514 236.911 492.021 229.451 495.314 222.957C499.77 214.168 521.166 174.631 474.713 163.113C464.012 160.565 448.469 158.276 428.428 166.713C416.738 171.635 419.328 174.528 423.516 176.084Z"
        fill="#A4BCFD"
      />
      <path
        d="M433.86 161.324L420.405 166.36C415.761 168.098 413.405 173.272 415.143 177.917L415.81 179.699C417.548 184.343 422.723 186.699 427.367 184.961L440.822 179.925C445.467 178.187 447.823 173.013 446.084 168.368L445.417 166.586C443.679 161.942 438.505 159.586 433.86 161.324Z"
        fill="#2D3282"
      />
      <path
        d="M351.271 175.895L306.898 192.502C304.576 193.371 303.398 195.958 304.267 198.281L304.442 198.748C305.311 201.07 307.898 202.248 310.22 201.379L354.593 184.772C356.916 183.903 358.094 181.315 357.225 178.993L357.05 178.526C356.181 176.204 353.593 175.026 351.271 175.895Z"
        fill="white"
      />
      <path
        d="M363.656 208.984L319.282 225.591C316.96 226.46 315.782 229.048 316.651 231.37L316.826 231.837C317.695 234.159 320.282 235.337 322.605 234.468L366.978 217.861C369.3 216.992 370.478 214.404 369.609 212.082L369.434 211.615C368.565 209.293 365.978 208.115 363.656 208.984Z"
        fill="white"
      />
      <path
        d="M404.623 153.314L368.66 166.773C364.015 168.512 361.659 173.686 363.397 178.331L375.676 211.138C377.414 215.782 382.589 218.138 387.233 216.4L423.197 202.94C427.841 201.202 430.197 196.028 428.459 191.383L416.18 158.576C414.442 153.931 409.268 151.575 404.623 153.314Z"
        fill="#444CE7"
      />
      <path
        d="M372.375 161.92L336.411 175.38C331.767 177.118 329.411 182.293 331.149 186.937L345.798 226.078C347.536 230.722 352.711 233.078 357.355 231.34L393.319 217.88C397.963 216.142 400.319 210.968 398.581 206.323L383.932 167.183C382.194 162.538 377.019 160.182 372.375 161.92Z"
        fill="#6172F3"
      />
      <path
        d="M378.005 153.977L377.723 154.083C374.626 155.241 373.056 158.691 374.215 161.787L394.736 216.619C395.895 219.715 399.344 221.286 402.441 220.127L402.723 220.022C405.819 218.863 407.39 215.413 406.231 212.317L385.71 157.485C384.551 154.389 381.101 152.818 378.005 153.977Z"
        fill="#2D31A6"
      />
      <path
        d="M11.2942 189.034C11.2942 189.034 83.5213 168.736 73.0351 215.5C71.2141 222.9 45.0004 260.999 76.2249 279.292C82.9296 283.22 90.6668 284.96 98.4368 284.919C112.368 284.847 134.453 282.165 144.374 272.067C157.671 258.531 143.145 264.95 143.145 264.95C143.145 264.95 101.663 282.013 80.3228 268.412C73.036 263.767 69.7331 254.775 71.7118 246.364C72.7232 242.186 74.2634 238.153 76.2949 234.365C80.9361 225.672 102.147 186.039 55.6937 174.521C44.9926 171.973 29.45 169.684 9.40938 178.121C-10.6313 186.559 11.2942 189.034 11.2942 189.034Z"
        fill="#A4BCFD"
      />
      <path
        d="M154.086 254.486L140.632 259.521C135.987 261.26 133.631 266.434 135.369 271.079L136.036 272.861C137.774 277.505 142.949 279.861 147.593 278.123L161.048 273.087C165.693 271.349 168.049 266.175 166.31 261.53L165.644 259.748C163.905 255.103 158.731 252.747 154.086 254.486Z"
        fill="#2D3282"
      />
      <path
        d="M176.819 232.349L156.878 239.812C152.233 241.55 149.877 246.724 151.616 251.369L161.231 277.06C162.969 281.705 168.143 284.061 172.788 282.323L192.729 274.86C197.373 273.121 199.729 267.947 197.991 263.302L188.376 237.611C186.637 232.966 181.463 230.61 176.819 232.349Z"
        fill="#444CE7"
      />
      <path
        d="M210.015 212.569L180.278 223.698C175.634 225.437 173.278 230.611 175.016 235.256L189.665 274.396C191.403 279.041 196.577 281.397 201.222 279.658L230.959 268.529C235.604 266.791 237.96 261.616 236.221 256.972L221.573 217.831C219.834 213.187 214.66 210.831 210.015 212.569Z"
        fill="#6172F3"
      />
      <path
        d="M216.314 204.463L216.032 204.568C212.936 205.727 211.365 209.177 212.524 212.273L233.045 267.105C234.204 270.201 237.654 271.772 240.75 270.613L241.032 270.507C244.128 269.349 245.699 265.899 244.54 262.803L224.019 207.971C222.86 204.875 219.41 203.304 216.314 204.463Z"
        fill="#2D31A6"
      />
      <path
        d="M292.249 169.531C291.178 169.412 290.195 168.882 289.507 168.053C288.819 167.224 288.479 166.16 288.559 165.086L290.778 124.559C290.911 122.138 292.955 120.299 295.275 120.513L298.507 120.812C300.827 121.026 302.488 123.206 302.164 125.61L296.712 165.838C296.586 166.91 296.052 167.892 295.221 168.579C294.39 169.267 293.325 169.608 292.249 169.531Z"
        fill="#3538CD"
      />
      <path
        d="M273.091 173.025C272.758 173.123 272.409 173.154 272.063 173.117C271.718 173.08 271.384 172.976 271.079 172.809C270.774 172.643 270.505 172.418 270.287 172.148C270.07 171.878 269.907 171.567 269.809 171.234L261.886 146.024C261.655 145.307 261.719 144.528 262.062 143.857C262.405 143.187 263.001 142.68 263.717 142.448L265.769 141.829C266.121 141.727 266.49 141.698 266.854 141.745C267.217 141.793 267.567 141.915 267.881 142.104C268.195 142.293 268.467 142.546 268.678 142.845C268.89 143.145 269.037 143.485 269.11 143.844L274.983 169.673C275.147 170.368 275.043 171.098 274.692 171.719C274.341 172.34 273.77 172.807 273.091 173.025Z"
        fill="#3538CD"
      />
      <path
        d="M262.399 187.563C260.979 189.322 258.124 189.301 255.926 187.516L216.904 155.812C214.574 153.918 213.886 150.783 215.392 148.918L217.491 146.318C218.997 144.452 222.091 144.605 224.296 146.654L261.219 180.959C263.298 182.891 263.819 185.804 262.399 187.563Z"
        fill="#3538CD"
      />
      <path
        d="M280.436 269.451C281.441 269.84 282.257 270.603 282.71 271.58C283.164 272.557 283.221 273.673 282.869 274.691L270.373 313.306C269.627 315.613 267.181 316.868 264.992 316.07L261.942 314.956C259.754 314.156 258.704 311.623 259.632 309.383L275.178 271.882C275.572 270.877 276.339 270.063 277.319 269.611C278.299 269.158 279.416 269.101 280.436 269.451Z"
        fill="#3538CD"
      />
      <path
        d="M299.852 270.966C300.199 270.956 300.545 271.015 300.869 271.139C301.193 271.263 301.49 271.45 301.742 271.688C301.994 271.927 302.197 272.213 302.338 272.53C302.48 272.847 302.558 273.189 302.567 273.536L303.79 299.933C303.829 300.685 303.569 301.423 303.066 301.983C302.563 302.543 301.858 302.881 301.106 302.923L298.964 302.997C298.598 303.007 298.233 302.94 297.894 302.801C297.554 302.663 297.247 302.455 296.992 302.192C296.736 301.929 296.539 301.616 296.41 301.272C296.282 300.928 296.227 300.562 296.248 300.196L297.167 273.724C297.186 273.011 297.473 272.331 297.971 271.82C298.468 271.309 299.14 271.004 299.852 270.966Z"
        fill="#3538CD"
      />
      <path
        d="M313.902 259.64C315.724 258.302 318.479 259.052 320.148 261.339L349.779 301.957C351.549 304.383 351.414 307.588 349.48 309.008L346.786 310.985C344.853 312.405 341.902 311.467 340.293 308.922L313.354 266.325C311.839 263.928 312.08 260.978 313.902 259.64Z"
        fill="#3538CD"
      />
    </svg>
  );
};
