import { Box } from '@chakra-ui/react';
import React from 'react';

interface TemplateContentProps {
  children?: React.ReactNode;
}

export const TemplateContent: React.FC<TemplateContentProps> = ({
  children,
}) => {
  return (
    <Box
      className="Template-content"
      h="100%"
      maxWidth="98.5rem"
      mx={{ xl: 'auto' }}
      position="relative"
      pb={8}
      pt={{ base: 6, md: 8 }}
      px={{ base: 4, sm: 6, md: 8 }}
    >
      {children}
    </Box>
  );
};
