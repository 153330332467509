const getArrayFromRGB = (rgbString: string): number[] => {
  const rgbValueArray = rgbString
    .replace('rgba', 'rgb')
    .substring(4, rgbString.length - 1)
    .replace(/ /g, '')
    .split(',');
  return rgbValueArray.map((n) => parseFloat(n));
};

export const flattenAlpha = (
  [r, g, b, a]: number[],
  background = [255, 255, 255]
): string => {
  const red = Math.round((1 - a) * background[0] + a * r);
  const green = Math.round((1 - a) * background[1] + a * g);
  const blue = Math.round((1 - a) * background[2] + a * b);
  return `rgb(${red},${green},${blue})`;
};

export const hexToRgbA = (hex) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)'
    );
  }
  throw new Error('Bad Hex');
};

export const RGBAToRGB = (
  color: string | number[],
  background: number[]
): string => {
  const rgba: number[] =
    typeof color === 'string' ? getArrayFromRGB(color) : color;
  return flattenAlpha(rgba, background);
};

export const createShades = (color) => {
  //['0.95','0.85','0.75','0.65','0.55','0.45','0.35','0.25','0.15','0.05']
  const shades = [color];
  for (let i = 0.05; i < 1; i = i + 0.1) {
    shades.push();
  }
};
