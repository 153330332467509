import { addMessage } from 'actions/messages';
import { defaultMessageTimeout } from 'reducers/messages';
import { getBrandAdapterShopifyAuthorizeFull } from 'api/brand/adapter/get-brand-adapter-shopify-authorize-full';
import { useMutation } from 'api/useMutation';
import { useStateValue } from 'providers';
import { useTranslation } from 'react-i18next';

interface ConnectShopifyResponse {
  redirect: string;
}

export function useGetBrandAdapterShopifyAuthorizeFull() {
  const [, dispatch] = useStateValue();
  const { t } = useTranslation('translation', {
    keyPrefix: 'connectShopifyAdapter',
  });

  const postMutation = useMutation<ConnectShopifyResponse, Error, string>(
    (shopifyInstanceId) => {
      return getBrandAdapterShopifyAuthorizeFull(shopifyInstanceId);
    },
    {
      onSuccess: (data) => {
        if (data.redirect) {
          window.location.href = data.redirect;
        }
        dispatch(
          addMessage({
            ephemeral: defaultMessageTimeout,
            message: t('upgradeSuccessMessage.body'),
            title: t('upgradeSuccessMessage.title'),
            type: 'success',
          })
        );
      },
      onSuccessEvent: () => ({
        category: 'SHOPIFY_ADAPTER',
        type: 'UPGRADE_SUCCESS',
      }),
    }
  );

  return postMutation;
}
