import { Dd, DescriptionList, Dt, Flex } from 'bam';
import React from 'react';

export const AppInfoList: React.FC = () => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  // disabling no-undef because usage doesn't require export from webpack.DefinePlugin()
  // eslint-disable-next-line no-undef
  const currentBranch = BRANCH;
  // eslint-disable-next-line no-undef
  const shortCommitHash = COMMITHASH.slice(0, 8);
  // eslint-disable-next-line no-undef
  const lastCommitDateTime = new Date(Date.parse(LASTCOMMITDATETIME));

  // eslint-disable-next-line no-undef
  const ciCommitRefName = CI_COMMIT_REF_NAME;

  return (
    <DescriptionList spacing={4}>
      <Flex align="stort" width="100%">
        <Dt flex="1 0 30%" mr={2}>
          Branch:
        </Dt>
        <Dd flex="2 0 70%">{currentBranch}</Dd>
      </Flex>

      <Flex align="stort" width="100%">
        <Dt flex="1 0 30%" mr={2}>
          Ref Name:
        </Dt>
        <Dd flex="2 0 70%">{ciCommitRefName}</Dd>
      </Flex>

      <Flex align="stort" width="100%">
        <Dt flex="1 0 30%" mr={2}>
          Commit Hash:
        </Dt>
        <Dd flex="2 0 70%">{shortCommitHash}</Dd>
      </Flex>

      <Flex align="stort" width="100%">
        <Dt flex="1 0 30%" mr={2}>
          Last Commit Date/Time:
        </Dt>
        <Dd flex="2 0 70%">
          {lastCommitDateTime.toLocaleDateString(undefined, dateOptions)},{' '}
          {lastCommitDateTime.toLocaleTimeString()}
        </Dd>
      </Flex>
    </DescriptionList>
  );
};
