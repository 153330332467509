import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { accordionAnatomy as parts } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleContainer = defineStyle({
  mb: 1,
  _last: {
    mb: 0,
  },
});

const baseStyleButton = defineStyle(({ theme }) => {
  return {
    bg: 'neutral.25',
    borderColor: 'neutral.300',
    borderRadius: 'lg',
    borderStyle: 'solid',
    borderWidth: '1px',
    color: 'text.primary',
    cursor: 'pointer',
    py: '.4375rem',
    ...theme.textStyles.labelLargeRegular,
    _focusVisible: {
      border: 'none',
      boxShadow: 'none',
      ...theme.styleTokens.focusVisible,
    },
    _disabled: {
      color: 'text.disabled',
      cursor: 'not-allowed',
      opacity: 1,
    },
  };
});

const baseStylePanel = defineStyle(({ theme }) => {
  return {
    color: 'text.secondary',
    p: '4',
    ...theme.textStyles.labelSmallRegular,
  };
});

const baseStyleIcon = defineStyle({
  fontSize: '1.5em',
});

const baseStyle = definePartsStyle((props) => {
  return {
    container: baseStyleContainer,
    button: baseStyleButton(props),
    panel: baseStylePanel(props),
    icon: baseStyleIcon,
  };
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });
