import { DashboardLayout, DashboardLayoutContent } from 'bam';
import { Link } from 'react-router-dom';

const CorporateDashboardCustomerSuccess: React.FC = () => {
  return (
    <DashboardLayout>
      <DashboardLayoutContent>
        <Link to="/corporate/analytics/my-brand">View brand analytics</Link>
      </DashboardLayoutContent>
    </DashboardLayout>
  );
};

export default CorporateDashboardCustomerSuccess;
