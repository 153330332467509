import { Button } from './forms/Button';
import { Card } from './layout/Card';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import styled from '@emotion/styled';

export const CookieConsentContainer = styled.div`
  bottom: 1rem;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100vw;
  z-index: 9999;

  ${({ theme: { breakpoint } }) => `
    @media only screen and (min-width: ${breakpoint('sm')}) {
      bottom: 2rem;
    }
  `}
`;

const CookieConsentCard = styled(Card)`
  align-items: center;
  display: flex;

  ${({ theme: { breakpoint, colorsDeprecated } }) => `
    background-color: ${colorsDeprecated('black', 0.1, true)};

    @media only screen and (min-width: ${breakpoint('sm')}) {
      margin: 0 1rem;
    }
  `}
`;

const CookieConsentContent = styled.div`
  margin: 0 1rem 0 0;
`;

export const CookieConsent = ({ buttonValue, children, cookieName }) => {
  const [visible, setVisible] = useState(false);

  const handleOnPress = () => {
    Cookies.set(cookieName, true, { expires: 365 });
    setVisible(false);
  };

  useEffect(() => {
    setVisible(!Cookies.get(cookieName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {visible ? (
        <CookieConsentContainer>
          <CookieConsentCard>
            <CookieConsentContent>{children}</CookieConsentContent>

            <Button onClick={handleOnPress} value={buttonValue} />
          </CookieConsentCard>
        </CookieConsentContainer>
      ) : null}
    </>
  );
};
