import { Schemas } from 'types/leap-api';
import { addMessage } from 'actions/messages';
import { defaultMessageTimeout } from 'reducers/messages';
import { postBrandUsers } from 'api/brand/users/post-brand-users';
import { useMutation } from 'api/useMutation';
import { useStateValue } from 'providers';
import { useTranslation } from 'react-i18next';

export function usePostBrandUsers() {
  const [, dispatch] = useStateValue();
  const { t } = useTranslation('users-table');
  const postMutation = useMutation<
    Schemas['CreateUsersResponse'],
    Error,
    Schemas['UserCreateRequest']
  >(
    (data) => {
      return postBrandUsers(data);
    },
    {
      onSuccess: () => {
        dispatch(
          addMessage({
            ephemeral: defaultMessageTimeout,
            message: t('message.body'),
            title: t('message.title'),
            type: 'success',
          })
        );
      },
      onSuccessEvent: () => ({
        category: 'BRAND_USER',
        type: 'BRAND_USER_CREATED',
      }),
    }
  );

  return postMutation;
}
