import { DashboardLayoutTypes } from './dashboard-layout.types';
import { Flex } from 'bam';

/*
 * DashboardLayout Simple layout for Leap dasboard pages consisting of a content and aside area.
 *
 * ```tsx
 * <DashboardLayout>
 *    <DashboardLayoutHeading>Hello world.</ DashboardLayoutHeading>
 *    <DashboardLayoutContent>
 *       <LeapCard p="6">DashboardLayoutContent</LeapCard>
 *     </DashboardLayoutContent>
 *     <DashboardLayoutAside>
 *       <LeapCard p="6">DashboardLayoutAside</LeapCard>
 *     </DashboardLayoutAside>
 *   </DashboardLayout>
 * ```
 */

export const DashboardLayout: React.FC<DashboardLayoutTypes> = ({
  children,
  ...rest
}) => {
  return (
    <Flex
      gap="6"
      flexWrap="wrap"
      direction={{ base: 'colum', md: 'row' }}
      {...rest}
    >
      {children}
    </Flex>
  );
};
