import { Box, forwardRef, useStyleConfig } from '@chakra-ui/react';
import { NotificationProps } from './notification.types';
import { ThemeTypings } from '../../../../../types/theming.types';

/**
 * Notification is a wrapper for box that accepts a
 * color, size and contrast setting. It is used to call attention to
 * state of data/alert a user to something.
 *
 * ```tsx
 * <Notification color="primary" size="lg" variant="highcontrast">
 *   1
 * </Notification>
 * ```
 */
export const Notification = forwardRef<NotificationProps, 'div'>(
  (
    { children, color = 'neutral', size, variant = 'highContrast', ...rest },
    ref
  ) => {
    const colorScheme: ThemeTypings['colorSchemes'] = color;
    const styles = useStyleConfig('Notification', {
      variant,
      colorScheme,
      size: size ? size : { base: 'sm', md: 'lg' },
    });
    return (
      <Box role="status" {...rest} ref={ref} __css={styles}>
        {children}
      </Box>
    );
  }
);

Notification.displayName = 'Notification';
