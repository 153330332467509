export interface AddBrandUserTypes {
  email: string;
  full_name: string;
  phone_number?: string;
  title?: string;
}

export const defaultBrandUserValues: AddBrandUserTypes = {
  email: '',
  full_name: '',
  phone_number: '',
  title: '',
};
