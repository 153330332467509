import { types } from 'actions';

type MessageType = 'success' | 'danger' | 'error' | 'warning' | 'info';

export interface Message {
  type: MessageType;
  title: string;
  message?: string;
  ephemeral?: number;
}

interface MessagesState {
  messages: Array<Message>;
}

export const messagesInitialState: MessagesState = {
  messages: [],
};

export const defaultMessageTimeout = 5000;

export const messagesReducer = (
  state: MessagesState = messagesInitialState,
  action
): MessagesState => {
  switch (action.type) {
    case types.ADD_MESSAGE: {
      const messages = [...state.messages, action.data];
      return {
        messages,
      };
    }
    case types.REMOVE_MESSAGE: {
      return {
        messages: state.messages.filter((msg) => msg !== action.data),
      };
    }
    case types.REMOVE_ERRORS: {
      return {
        messages: state.messages.filter((msg) => msg.type !== 'error'),
      };
    }
    case types.SAVE_BRAND_ASSET_SUCCESS: {
      return {
        messages: [
          ...state.messages,
          {
            ephemeral: defaultMessageTimeout,
            message: `Succesfully uploaded ${action.data.filename}`,
            title: 'Upload complete',
            type: 'success',
          },
        ],
      };
    }
    case types.CREATE_BRAND_PROFILE_CONTACTS_SUCCESS: {
      return {
        messages: [
          ...state.messages,
          {
            ephemeral: defaultMessageTimeout,
            message: `Successfully created contact(s)`,
            title: 'Contacts created',
            type: 'success',
          },
        ],
      };
    }
    case types.DELETE_BRAND_USERS_SUCCESS: {
      return {
        messages: [
          ...state.messages,
          {
            ephemeral: defaultMessageTimeout,
            message: `User successfully removed`,
            title: 'User Removed',
            type: 'success',
          },
        ],
      };
    }
    case types.DELETE_BRAND_USERS_FAILURE: {
      return {
        messages: [
          ...state.messages,
          {
            ephemeral: defaultMessageTimeout,
            message: `Error removing User`,
            title: 'User Not Removed',
            type: 'error',
          },
        ],
      };
    }
    case types.DELETE_BRAND_ASSET_SUCCESS: {
      return {
        messages: [
          ...state.messages,
          {
            ephemeral: defaultMessageTimeout,
            message: 'Succesfully deleted file.',
            title: 'Success',
            type: 'success',
          },
        ],
      };
    }
    case types.COPY_TO_CLIPBOARD_SUCCESS: {
      return {
        messages: [
          ...state.messages,
          {
            ephemeral: defaultMessageTimeout,
            message: 'Successfully copied the data to the clipboard.',
            title: 'Success',
            type: 'success',
          },
        ],
      };
    }
    case types.COPY_TO_CLIPBOARD_FAILED: {
      return {
        messages: [
          ...state.messages,
          {
            ephemeral: defaultMessageTimeout,
            message: 'Failed to copy the data to the clipboard.',
            title: 'Error',
            type: 'error',
          },
        ],
      };
    }
    case types.LOADING:
    case types.REMOVE_ALL_MESSAGES: {
      return {
        ...messagesInitialState,
      };
    }
    default:
      return state;
  }
};
