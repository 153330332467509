import { AddBrandUserForm } from './add-brand-user-form';
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '../../../../../index';

import { ChevronDown } from '../../../../../icons';
import { useTranslation } from 'react-i18next';
import FocusLock from 'react-focus-lock';
import React from 'react';

export const AddBrandUserDesktop = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);
  const { t } = useTranslation('add-user');

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      initialFocusRef={firstFieldRef}
      placement="bottom"
      offset={[-50, 10]} // Just based on my visual opinion. I can change to whatever looks best
    >
      <PopoverTrigger>
        <Button size="sm" rightIcon={<ChevronDown />}>
          {t('triggerButton.label')}
        </Button>
      </PopoverTrigger>

      <PopoverContent p={5}>
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <AddBrandUserForm onClose={onClose} firstFieldRef={firstFieldRef} />
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};
