import {
  UserRoleGrouping,
  useUserRoleGrouping,
} from 'hooks/use-user-role-grouping';
import { jitsu } from './jitsu';
import { useMemo } from 'react';

export interface BaseEvent {
  /** maybe will become a type like role, for now a string */
  subject?: string;
  action?: string;
  data?: any;
}

export interface Event extends BaseEvent {
  /** will add to this as we further define roles  */
  role: UserRoleGrouping;
}

interface EventData {
  event_data: Event;
}

export const track = (data: EventData) => {
  const {
    event_data: { action, role, subject },
  } = data;
  jitsu.track(`${role}_${action}_${subject}`, data);
};

function createTrack(role: UserRoleGrouping) {
  return (data: BaseEvent) => {
    track({
      event_data: {
        role,
        ...data,
        data: JSON.stringify(data.data),
      },
    });
  };
}

/**
 * returns a track function that will fill in the
 * role based on session.
 */
export const useTrack = () => {
  const userRoleType = useUserRoleGrouping();

  const trackPartial = useMemo(() => {
    return createTrack(userRoleType);
  }, [userRoleType]);

  return {
    track: trackPartial,
  };
};
