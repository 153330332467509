import { BaseEvent, useTrack } from 'analytics/use-track';
import { EventTrackerParams, eventTracker } from 'analytics/analytics-utils';
import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery as useRQQuery,
} from '@tanstack/react-query';
import { useStateValue } from 'providers';

export type LeapUseQueryOptions<
  TQueryFnData = unknown,
  TError = Error,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
> & {
  initialData?: () => undefined;
  onSuccessEvent?: (data: TData) => BaseEvent | EventTrackerParams;
};

export function useQuery<
  TQueryFnData = unknown,
  TError = Error,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: LeapUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) {
  const [, dispatch] = useStateValue();
  const { track } = useTrack();
  const effectiveOptions: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
    onSuccessEvent?: (data: TData) => BaseEvent | EventTrackerParams;
  } = {
    ...options,
    onSuccess: (data) => {
      if (options?.onSuccessEvent) {
        const successEvent = options.onSuccessEvent(data);
        // new format event
        if ('subject' in successEvent) {
          track(successEvent);
        } else if ('type' in successEvent) {
          eventTracker(successEvent);
        }
      }
      if (options?.onSuccess) {
        options.onSuccess(data);
      }
    },
    onError: (error) => {
      if ((error as any) && (error as any).cause) {
        dispatch((error as any).cause);
      }
      if (options?.onError) {
        options.onError(error);
      }
    },
  };

  const queryResult = useRQQuery(queryKey, queryFn, effectiveOptions);

  return queryResult;
}
