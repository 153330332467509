import { Route } from 'react-router-dom';
import { RouteLoadingFallback } from 'routes/route-loading-fallback';
import { Routes } from '../Routes';
import { lazy } from 'react';
import CorporateDashboardCustomerSuccess from 'pages/corporate/dashboards/corporate-dashboard-customer-success';

const Account = lazy(() => import('../../pages/admin/account'));
const CorporateAnalyticsRouter = lazy(
  () => import('./corporate-analytics-router')
);

export const CorporateRouter = () => {
  return (
    <RouteLoadingFallback>
      <Routes>
        <Route path={'account'} element={<Account />} />
        <Route path="analytics/*" element={<CorporateAnalyticsRouter />} />
        <Route index element={<CorporateDashboardCustomerSuccess />} />
      </Routes>
    </RouteLoadingFallback>
  );
};
