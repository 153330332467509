import auth0 from 'auth0-js';

const auth = new auth0.Authentication({
  domain: process.env.AUTH0_DOMAIN,
  clientID: process.env.AUTH0_CLIENT_ID,
});

export const getToken = (login) => {
  return new Promise((resolve, reject) => {
    auth.loginWithDefaultDirectory(
      {
        username: login.email,
        password: login.password,
        scope: 'openid profile email offline_access',
      },
      (err, res) => {
        if (err) {
          return reject(err);
        }
        return resolve(res);
      }
    );
  });
};

export const getAuthZeroUserInfo = (token) => {
  return new Promise((resolve, reject) => {
    auth.userInfo(token, (err, res) => {
      if (err) {
        return reject(err);
      }
      return resolve(res);
    });
  });
};

export { passwordReset } from './auth-passwordReset';
