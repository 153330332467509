import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { menuAnatomy as parts } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const baseStyleList = defineStyle(({ theme }) => {
  return {
    bg: 'base.white',
    boxShadow: 'lg',
    color: 'inherit',
    minW: theme.pxToRem(200),
    py: '2',
    zIndex: 1,
    borderRadius: 'lg',
    borderWidth: '1px',
  };
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const baseStyleItem = defineStyle(({ theme }) => {
  return {
    borderWidth: 0,
    color: 'text.secondary',
    py: theme.pxToRem(10),
    px: '4',
    height: theme.pxToRem(40),
    transitionProperty: 'background',
    transitionDuration: 'ultra-fast',
    transitionTimingFunction: 'ease-in',
    backgroundColor: 'base.white',
    outline: 'none',
    _focus: {
      bg: 'primary.100',
    },
    _visited: {
      color: 'inherit',
    },
    _hover: {
      bg: 'primary.100',
    },
    _focusVisible: {
      outlineColor: 'primary.300',
      outlineStyle: 'solid',
      outlineWidth: '0.125rem',
    },
    _active: {
      bg: 'primary.300',
    },
    _expanded: {
      bg: 'neutral.100',
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  };
});

const baseStyleGroupTitle = defineStyle({
  mx: 4,
  my: 2,
  fontWeight: 'semibold',
  fontSize: 'md',
});

const baseStyleCommand = defineStyle({
  opacity: 0.6,
});

const baseStyleDivider = defineStyle({
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'inherit',
  my: '2',
  opacity: 0.6,
});

const baseStyleButton = defineStyle({
  transitionProperty: 'common',
  transitionDuration: 'normal',
});

const baseStyle = definePartsStyle((props) => ({
  button: baseStyleButton,
  list: baseStyleList(props),
  item: baseStyleItem(props),
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider,
}));

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
});
