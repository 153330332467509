import { calc, cssVar } from '@chakra-ui/theme-tools';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { switchAnatomy as parts } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');
const $diff = cssVar('switch-track-diff');
const diffValue = calc.subtract($width, $height);
const $translateX = cssVar('switch-thumb-x');
const $bg = cssVar('switch-bg');

const baseStyleTrack = defineStyle((props) => {
  const { colorScheme: c } = props;

  return {
    borderRadius: 'full',
    p: '2px',
    width: [$width.reference],
    height: [$height.reference],
    transitionProperty: 'common',
    transitionDuration: 'fast',
    [$bg.variable]: 'colors.neutral.600',
    _dark: {
      [$bg.variable]: 'colors.whiteAlpha.400',
    },
    _focusVisible: {
      outlineWidth: '2px',
      outlineColor: 'primary.300',
      outlineOffset: '1px',
      borderWidth: 0,
    },
    _disabled: {
      opacity: 0.6,
      cursor: 'not-allowed',
    },
    _checked: {
      [$bg.variable]: `colors.${c}.600`,
      _dark: {
        [$bg.variable]: `colors.${c}.200`,
      },
    },
    bg: $bg.reference,
  };
});

const baseStyleThumb = defineStyle({
  bg: 'white',
  transitionProperty: 'transform',
  transitionDuration: 'normal',
  borderRadius: 'inherit',
  width: [$height.reference],
  height: [$height.reference],
  _checked: {
    transform: `translateX(${$translateX.reference})`,
  },
});

const baseStyle = definePartsStyle((props) => ({
  container: {
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
    _rtl: {
      [$translateX.variable]: calc($diff).negate().toString(),
    },
  },
  track: baseStyleTrack(props),
  thumb: baseStyleThumb,
}));

const sizes = {
  sm: definePartsStyle({
    container: {
      [$width.variable]: '1.5rem',
      [$height.variable]: '0.875rem',
    },
  }),
  // this size is not actually used
  md: definePartsStyle({
    container: {
      [$width.variable]: '1.75rem',
      [$height.variable]: '1rem',
    },
  }),
  lg: definePartsStyle({
    container: {
      [$width.variable]: '2rem',
      [$height.variable]: '1.125rem',
    },
  }),
};

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'primary',
  },
});
