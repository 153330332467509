import { defineStyleConfig } from '@chakra-ui/react';

export const leapCardContentTheme = defineStyleConfig({
  variants: {
    vertical: {
      pt: '6',
      px: '6',
      pb: '4',
    },
    horizontal: {
      flex: '4',
      py: 4,
      px: 6,
    },
  },
});
