import { ComponentWithAs } from '@chakra-ui/react';
import { IconProps } from '../icon-props';
import { createIcon } from '@chakra-ui/icons';
/**
 * All icons are auto generated from the raw svg files exported from untitled ui.
 * They are then processed with svgr, and using a custom template created as a Chakra Icon.
 * See [Chakra ui Icon usage](https://chakra-ui.com/docs/components/icon/usage) and [svgr](https://react-svgr.com/) for more.
 * Do not update these icon files manually, as your change will be lost the next time the icons are overwritten.
 * @see https://chakra-ui.com/docs/components/icon/usage for more documentation
 * @see https://react-svgr.com/ for more on the tool used to generate the components
 * @see svgr-icon-template.js for the template
 */
export const ChartBreakoutSquare: ComponentWithAs<'svg', IconProps> =
  createIcon({
    displayName: 'ChartBreakoutSquare',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
      <>
        <path
          fill="none"
          d="M11 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V13M12 8H16V12M15.5 3.5V2M19.4393 4.56066L20.5 3.5M20.5103 8.5H22.0103M3 13.3471C3.65194 13.4478 4.31987 13.5 5 13.5C9.38636 13.5 13.2653 11.3276 15.6197 8"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
  });
export default ChartBreakoutSquare;
