import { NotAuthorized } from 'pages/error/not-authorized';
import { Outlet } from 'react-router-dom';
import { useGetBrand } from 'hooks/api/brand/use-get-brand';
import React from 'react';

export const ContractNotSigned: React.FC = () => {
  const { data, isFetched } = useGetBrand();

  if (isFetched) {
    return (
      <>
        {!data?.brand.brand_details?.contract_signed_status ? (
          <Outlet />
        ) : (
          <NotAuthorized />
        )}
      </>
    );
  } else {
    return null;
  }
};
