import { onCLS, onFCP, onFID, onLCP, onTTFB } from 'web-vitals';
import { trackCoreWebVital } from 'analytics/analytics-utils';
import { useEffect } from 'react';

/**
 * sets up reporting core web vitals for app, and
 * sends to jitsu.
 * initializes event listeners only 1 time per app load per instructions
 * @see https://www.npmjs.com/package/web-vitals
 */
export function useCoreWebVitals() {
  useEffect(() => {
    onCLS(trackCoreWebVital);
    onFID(trackCoreWebVital);
    onLCP(trackCoreWebVital);
    onFCP(trackCoreWebVital);
    onTTFB(trackCoreWebVital);
  }, []);
}
