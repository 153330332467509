import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/react';
import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const $size = cssVar('checkbox-size');

const baseStyleControl = defineStyle(({ theme }) => {
  return {
    ...theme.styleTokens.baseInputControl,
    borderRadius: 'sm',
    _checked: {
      bg: 'primary.600',
      borderColor: 'primary.600',
      _disabled: {
        bg: 'icon.disabled',
        borderColor: 'icon.disabled',
        _hover: {
          bg: 'icon.disabled',
          borderColor: 'icon.disabled',
        },
      },
      _hover: {
        backgroundColor: 'primary.600',
      },
    },
    _disabled: {
      ...theme.styleTokens.disabledInputControl,
      _hover: {
        ...theme.styleTokens.disabledInputControl,
      },
    },
    _focusVisible: {
      ...theme.styleTokens.focusVisible,
    },
    _hover: {
      ...theme.styleTokens.hoverInputControl,
    },
    _indeterminate: {
      backgroundColor: 'primary.600',
      borderColor: 'primary.600',
      _hover: {
        backgroundColor: 'primary.600',
        borderColor: 'primary.600',
      },
    },
    _invalid: {
      borderColor: 'error.600',
    },
  };
});

const baseStyleIcon = defineStyle({
  color: 'base.white',
});

const baseStyleLabel = defineStyle(({ theme }) => {
  return {
    ...theme.styleTokens.baseInputLabel,
  };
});

const baseStyle = definePartsStyle((props) => {
  return {
    control: baseStyleControl(props),
    icon: baseStyleIcon,
    label: baseStyleLabel(props),
  };
});

const sizes = {
  sm: definePartsStyle(({ theme }) => {
    return {
      control: { [$size.variable]: 'sizes.4' },
      icon: {
        h: '4',
        w: '4',
      },
      label: { ...theme.textStyles.labelMediumRegular },
    };
  }),
  lg: definePartsStyle(({ theme }) => {
    return {
      control: { [$size.variable]: 'sizes.5' },
      label: { ...theme.textStyles.labelLargeRegular },
    };
  }),
};

export const checkboxTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle,
  sizes,
});
