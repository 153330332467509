import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { shadows } from './shadows';
import { spacing } from './spacing';
import { typography } from './typography';
import { zIndices } from './zIndices';

export const foundations = {
  breakpoints,
  colors,
  shadows,
  space: spacing,
  ...typography,
  zIndices,
};
