import { Box, chakra, forwardRef, useStyleConfig } from '@chakra-ui/react';
import { SubheadingProps } from './subheading-types';

/**
 * Variants as defined by [BAM Design System](https://www.figma.com/file/BOiwVLO9uF9CPdDDkykzyr/BAM-Design-System?node-id=33727%3A61436) for Subheading.
 **/
export const Subheading = forwardRef<SubheadingProps, 'h3'>((props, ref) => {
  const {
    as = 'h3',
    children,
    textStyle,
    variant = 'regular',
    ...rest
  } = props;
  const styles = useStyleConfig('Subheading', {
    variant,
  });

  if (textStyle) {
    return (
      <Box {...rest} as={as ? as : 'h4'} ref={ref} textStyle={textStyle}>
        {children}
      </Box>
    );
  } else {
    return (
      <chakra.h3 {...rest} as={as} ref={ref} __css={styles}>
        {children}
      </chakra.h3>
    );
  }
});

Subheading.displayName = 'Subheading';
