import { defineStyleConfig } from '@chakra-ui/react';

export const leapCardImageTheme = defineStyleConfig({
  baseStyle: {
    borderTopLeftRadius: 'lg',
    ml: '-1px',
    mt: '-1px',
  },
  variants: {
    vertical: {
      borderTopRightRadius: 'lg',
      mr: '-1px',
      width: 'calc(100% + 2px) !important', //offsets border shifting to stretch image to cover right border, aspect ratio from chakra overrides this so keep at important
    },
    horizontal: {
      borderBottomLeftRadius: 'lg',
      height: 'calc(100% + 2px) !important',
    },
  },
});
