import { useFlags } from 'showroom/use-flags';
import React from 'react';

interface FeatureFlagProps {
  flag: string;
  enabled?: React.ReactNode;
  disabled?: React.ReactNode;
}

export const FeatureFlag: React.FC<FeatureFlagProps> = ({
  enabled,
  disabled,
  flag,
}) => {
  const flags = useFlags();

  const flagStatus = flags[flag];

  if (flagStatus && enabled) {
    return <>{enabled}</>;
  }

  if (!flagStatus) {
    if (disabled) {
      return <>{disabled}</>;
    } else {
      return null;
    }
  }

  return null;
};
