import { Icon } from 'components/Icon/Main';
import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

const AccordionContainer = styled.div`
  overflow: hidden;
  transition: height 0.3s ease-out;

  ${({ theme: { colorsDeprecated } }) => `
    & + & {
      border-top: 1px solid ${colorsDeprecated('foreground', 0.25)};
    }
  `}

  ${({ buttonHeight, contentHeight, open }) => `
    ${
      open
        ? `
          height: ${buttonHeight + contentHeight}px;
        `
        : `
          height: ${buttonHeight}px;
        `
    }
  `}
`;
export const AccordionContent = styled.div`
  padding: 1rem;
  ${({ open }) => (open ? 'visibility: visible' : 'visibility: hidden')};

  ${({ theme: { colorsDeprecated } }) => `
      background-color: ${colorsDeprecated('background')};
  `}
`;
const TriggerButton = styled.button`
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  font-family: InterVariable, sans-serif;
  min-height: 2.875rem;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  text-align: left;
  width: 100%;

  ${({ theme: { colorsDeprecated } }) => `
    background-color: ${colorsDeprecated('black', 0.1, true)};
  `}

  ${({ open }) => open && 'svg {transform: rotate(180deg)}'};
`;

const TriggerButtonTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 1.25rem 0 0;
`;

export const Accordion = ({ children, handleClick, title }) => {
  const buttonRef = useRef();
  const contentRef = useRef();
  const [open, setOpen] = useState(false);
  const [buttonHeight, setButtonHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (children) {
      setButtonHeight(buttonRef.current.scrollHeight);
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [children]);

  useEffect(() => {
    if (handleClick) {
      handleClick({ buttonHeight, contentHeight, open });
    }
  }, [buttonHeight, contentHeight, handleClick, open]);

  const handleTriggerButtonClick = () => {
    setOpen(!open);
  };

  return (
    <AccordionContainer
      buttonHeight={buttonHeight}
      contentHeight={contentHeight}
      open={open}
    >
      <TriggerButton
        aria-label={open ? 'collapse accordion' : 'expand accordion'}
        onClick={(event) => handleTriggerButtonClick(event)}
        open={open}
        ref={buttonRef}
      >
        <TriggerButtonTitle>{title}</TriggerButtonTitle>

        <Icon fill="black" height="12" icon="caret" width="12" />
      </TriggerButton>

      <AccordionContent open={open} ref={contentRef}>
        {children}
      </AccordionContent>
    </AccordionContainer>
  );
};
