export const textStyles = {
  headingLargeBold: {
    fontSize: '2xl',
    fontWeight: 'bold',
    lineHeight: 'largeHeading',
  },
  headingLargeRegular: {
    fontSize: '2xl',
    fontWeight: 'regular',
    lineHeight: 'largeHeading',
  },
  headingSmallBold: {
    fontSize: 'xl',
    fontWeight: 'bold',
    lineHeight: 'smallHeading',
  },
  headingSmallRegular: {
    fontSize: 'xl',
    fontWeight: 'regular',
    lineHeight: 'smallHeading',
  },
  labelLargeBold: {
    fontSize: 'lg',
    fontWeight: 'bold',
    lineHeight: 'none',
  },
  labelLargeMedium: {
    fontSize: 'lg',
    fontWeight: 'medium',
    lineHeight: 'none',
  },
  labelLargeRegular: {
    fontSize: 'lg',
    fontWeight: 'regular',
    lineHeight: 'none',
  },
  labelMediumBold: {
    fontSize: 'md',
    fontWeight: 'bold',
    lineHeight: 'none',
  },
  labelMediumMedium: {
    fontSize: 'md',
    fontWeight: 'medium',
    lineHeight: 'none',
  },
  labelMediumRegular: {
    fontSize: 'md',
    fontWeight: 'regular',
    lineHeight: 'none',
  },
  labelSmallBold: {
    fontSize: 'sm',
    fontWeight: 'bold',
    lineHeight: 'none',
  },
  labelSmallMedium: {
    fontSize: 'sm',
    fontWeight: 'medium',
    lineHeight: 'none',
  },
  labelSmallRegular: {
    fontSize: 'sm',
    fontWeight: 'regular',
    lineHeight: 'none',
  },
  labelXsmallRegular: {
    fontSize: 'xs',
    fontWeight: 'regular',
    lineHeight: 'none',
  },
  paragraphBold: {
    fontSize: 'lg',
    fontWeight: 'bold',
    lineHeight: 'base',
  },
  paragraphRegular: {
    fontSize: 'lg',
    fontWeight: 'regular',
    lineHeight: 'base',
  },
  subheadingBold: {
    fontSize: 'lg',
    fontWeight: 'bold',
    lineHeight: 'base',
    color: 'text.secondary',
  },
  subheadingMedium: {
    fontSize: 'lg',
    fontWeight: 'medium',
    lineHeight: 'base',
    color: 'text.secondary',
  },
  subheadingRegular: {
    fontSize: 'lg',
    fontWeight: 'regular',
    lineHeight: 'base',
    color: 'text.secondary',
  },
};
