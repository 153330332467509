import { types } from 'actions';

const Global = (state, action) => {
  switch (action.type) {
    case types.CLEAR_LOADING:
      return {
        ...state,
        loading: false,
      };
    case types.CLOSE_SIDEBAR:
      return {
        ...state,
        open_sidebar: false,
      };
    case types.OPEN_SIDEBAR:
      return {
        ...state,
        open_sidebar: true,
      };
    case types.TOGGLE_HELP_MODAL:
      return {
        ...state,
        helptip: {
          show: action.show,
          tip: action.message,
          position: action.position,
        },
      };
    case types.LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return {
        ...state,
        helptip: {
          tip: '',
          position: {},
        },
      };
  }
};

export default Global;
