import 'analytics/analytics-init';
import { AppOffline } from './AppOffline';
import { GlobalProvider } from 'providers';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StateSync } from 'providers/state-sync';
import { UserRoleGrouping } from './hooks/use-user-role-grouping';
import { apiClient } from './api/apiClient';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { bootstrapState } from 'providers/stateProvider';
import { buildAdminRetailUserIdentify } from './utils/session/build-admin-retail-user-identify';
import { buildBrandUserIdentify } from 'utils/session/build-brand-user-identify';
import { buildUnauthUserIdentify } from 'utils/session/build-unauth-user-identify';
import { createRoot } from 'react-dom/client';
import { getSessionUserId } from './utils/session/get-session-user-id';
import { i18n } from 'utils/i18n';

const queryCache = new QueryCache({
  onError: (error: Error) => {
    if (window.TrackJS) {
      window.TrackJS.console.error(error.message, { error });
    }
  },
});

const defaultStaleTime = 1000 * 60 * 60;
const defaultCacheTimeOffset = 1000 * 60 * 5;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: defaultStaleTime + defaultCacheTimeOffset,
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: defaultStaleTime,
    },
  },
  queryCache,
});

i18n();

if (process.env.INTERCOM_APP_ID === 'false') {
  window.Intercom = () => {};
}

(async () => {
  const bootstrappedState = bootstrapState();

  const sessionState = bootstrappedState.session;

  const sessionUserId = getSessionUserId(sessionState);

  let context;

  if (sessionUserId) {
    apiClient.setAuthToken(sessionState.token);
    if (sessionState.user_role_grouping) {
      const userRoleGrouping: UserRoleGrouping =
        sessionState.user_role_grouping;

      if (userRoleGrouping === 'ADMIN' || userRoleGrouping === 'RETAIL') {
        context = buildAdminRetailUserIdentify(sessionUserId, sessionState);
      }

      if (userRoleGrouping === 'BRAND') {
        context = buildBrandUserIdentify(sessionUserId, sessionState);
      }
    }

    bootstrappedState.session.launchDarklyIdentified = true;
  } else {
    context = buildUnauthUserIdentify();
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.LD_CLIENT_ID || '',
    context: context ?? undefined,
  });

  const container = document.getElementById('root');
  const root = createRoot(container as Element);

  root.render(
    <QueryClientProvider client={queryClient}>
      <LDProvider>
        <HelmetProvider>
          <GlobalProvider initialState={bootstrappedState}>
            <Helmet defaultTitle="Leap" titleTemplate="%s - Leap" />
            <AppOffline />
            <StateSync />
          </GlobalProvider>
        </HelmetProvider>
      </LDProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
})();
