import { Loader } from '@googlemaps/js-api-loader';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

/**
 * loader class for official options see google docs
 * @see https://www.npmjs.com/package/@googlemaps/js-api-loader
 * @see https://developers.google.com/maps/documentation/javascript/overview
 */
const loader = new Loader({
  apiKey: process.env.GOOGLE_API_KEY,
  version: 'quarterly',
  libraries: ['places'],
});

interface MapsLoader {
  /**
   * status of loading the google maps api
   */
  isLoaded: boolean;
  /**
   * reference to google object that was loaded
   */
  google?: typeof google;
}

export const MapsContext = createContext<MapsLoader>({
  isLoaded: false,
  google: undefined,
});

/**
 * Shortcut hook to use the maps context without having to import it.
 * returns a Mapsloader object to check if google maps is loaded and a
 * reference to a google object itself. Use this reference instead of
 * the reference to window.
 */
export const useMapsContext = (): MapsLoader => {
  return useContext(MapsContext);
};

interface MapsProviderProps {
  children: React.ReactNode;
}

/**
 * Wrapper around [https://www.npmjs.com/package/@googlemaps/js-api-loader](@googlemaps/js-api-loader)
 * Initializes loading google maps and places api, and sets the MapsContext.
 * To use the maps context values, import the `useMapsContext` hook. See docs there for usage :)
 * The loader object itself is configured one time at the start of this file.
 */
export const MapsProvider: React.FC<MapsProviderProps> = ({ children }) => {
  const googleRef = useRef<undefined | typeof google>(undefined);
  const [googleIsLoaded, setGoogleIsLoaded] = useState(false);

  useEffect(() => {
    loader.load().then((google) => {
      googleRef.current = google;
      setGoogleIsLoaded(true);
    });
  }, []);

  return (
    <MapsContext.Provider
      value={{ isLoaded: googleIsLoaded, google: googleRef.current }}
    >
      {children}
    </MapsContext.Provider>
  );
};
