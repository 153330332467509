import { LDUser } from 'launchdarkly-js-client-sdk';

export function buildUnauthUserIdentify() {
  const unauthUserObject: LDUser = {
    anonymous: false,
    key: window.localStorage.getItem('ld:$anonUserId') || 'UNDEFINED',
  };

  return unauthUserObject;
}
