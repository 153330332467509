import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/react';
import { inputAnatomy as parts } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleElement = defineStyle({
  height: '100%',
  mx: '1',
});

const baseStyleField = defineStyle(({ theme }) => {
  return {
    bg: 'base.white',
    color: 'text.primary',
    ...theme.textStyles.labelLargeRegular,
    _placeholder: {
      ...theme.styleTokens.placeholder,
    },
  };
});

export const baseStyle = definePartsStyle((props) => {
  return {
    element: baseStyleElement,
    field: baseStyleField(props),
  };
});

const sizes = {
  sm: definePartsStyle(({ theme }) => {
    return {
      field: {
        ...theme.styleTokens.fieldSizeSmall,
      },
    };
  }),
  lg: definePartsStyle(({ theme }) => {
    return {
      field: {
        ...theme.styleTokens.fieldSizeLarge,
      },
    };
  }),
};

const variantOutline = definePartsStyle(({ theme }) => {
  return {
    field: {
      ...theme.styleTokens.defaultBorder,
      _disabled: {
        ...theme.styleTokens.disabledInput,
        _placeholder: {
          color: 'text.disabled',
        },
      },
      _focusVisible: {
        _placeholder: {
          color: 'text.disabled',
        },
      },
      _invalid: {
        boxShadow: 'none',
        ...theme.styleTokens.invalidBorder,
        _disabled: {
          borderColor: 'neutral.300',
        },
      },
    },
  };
});

const variants = {
  outline: variantOutline,
};

export const inputTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
});
