import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';
import { LeapCardLayoutVariant } from './leap-card.types';
import React from 'react';

type LeapCardContentVariant = LeapCardLayoutVariant;

interface LeapCardContentProps extends BoxProps {
  /**
   * overall layout of the card contents
   * @default 'vertical'
   */
  variant?: LeapCardContentVariant;
  children?: React.ReactNode;
}

/**
 * CardContent is a wrapper for box, that handles standard padding for the content area of a card
 */
export const LeapCardContent: React.FC<LeapCardContentProps> = ({
  variant = 'vertical',
  ...rest
}) => {
  const styles = useStyleConfig('LeapCardContent', { variant });
  return <Box __css={styles} {...rest} />;
};
