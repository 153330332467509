import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['imageContainer', 'topCallout', 'bottomCallout'];

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const imageCardTheme = defineMultiStyleConfig({
  baseStyle: {
    imageContainer: {
      position: 'relative',
      zIndex: 'base',
    },
    topCallout: {
      position: 'absolute',
      top: '4',
      zIndex: 'banner',
    },
    bottomCallout: {
      position: 'absolute',
      bottom: '4',
      zIndex: 'banner',
    },
  },
  variants: {
    vertical: {
      topCallout: {
        right: '4',
      },
      bottomCallout: {
        right: '4',
      },
    },
    horizontal: {
      imageContainer: {
        flex: '3',
        alignSelf: 'stretch',
      },
      topCallout: {
        left: '4',
      },
      bottomCallout: {
        left: '4',
      },
    },
  },
});
