import {
  BarChartSquare02,
  Building02,
  CodeSquare02,
  FileLock03,
  Lightning02,
  MarkerPin01,
  MessageQuestionSquare,
  Users02,
} from '../../../../icons';
import { Nav } from '../nav/nav';
import { NavItem } from '../nav-item/nav-item';
import { NavList } from '../nav-list/nav-list';
import { SubNavItem } from '../sub-nav-item/sub-nav-item';
import {
  formatAnalyticsPath,
  formatAnalyticsTitle,
  showNavOption,
} from 'utils/analytics-nav-menu';
import { useFlags } from '../../../../../showroom/use-flags';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const AdminUsersNavigation: React.FC = () => {
  const { t } = useTranslation('primary-template');
  const { analyticsPages, appInfoPage, leapbotAdmin } = useFlags();

  return (
    <Nav>
      <NavList>
        <NavItem
          icon={<Lightning02 />}
          label={t('sidebar.brands')}
          to="/admin/brands"
        />

        <NavItem
          icon={<Users02 />}
          label={t('sidebar.users')}
          to="/admin/users"
        />

        <NavItem
          icon={<MarkerPin01 />}
          label={t('sidebar.spaces')}
          to="/admin/spaces"
        />

        <NavItem
          icon={<Building02 />}
          label={t('sidebar.stores')}
          to="/admin/stores"
        />

        <NavItem icon={<BarChartSquare02 />} label={t('sidebar.analytics')}>
          {analyticsPages && Array.isArray(analyticsPages)
            ? analyticsPages.map((page) =>
                showNavOption(page, true) ? (
                  <SubNavItem
                    key={page.title}
                    label={formatAnalyticsTitle(page.title)}
                    to={formatAnalyticsPath(page.path, 'ADMIN')}
                  />
                ) : null
              )
            : null}
        </NavItem>

        <NavItem
          icon={<MessageQuestionSquare />}
          label={t('sidebar.insights')}
          to="/admin/insights"
        />

        {leapbotAdmin ? (
          <NavItem
            icon={<FileLock03 />}
            label={t('sidebar.leapbotAdmin')}
            to="/admin/leapbotAdmin"
          />
        ) : null}

        {appInfoPage ? (
          <NavItem
            icon={<CodeSquare02 />}
            label={t('sidebar.appInfo')}
            to="/admin/app_info"
          />
        ) : null}
      </NavList>
    </Nav>
  );
};
