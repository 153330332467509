import { ComponentWithAs } from '@chakra-ui/react';
import { IconProps } from '../icon-props';
import { createIcon } from '@chakra-ui/icons';
/**
 * All icons are auto generated from the raw svg files exported from untitled ui.
 * They are then processed with svgr, and using a custom template created as a Chakra Icon.
 * See [Chakra ui Icon usage](https://chakra-ui.com/docs/components/icon/usage) and [svgr](https://react-svgr.com/) for more.
 * Do not update these icon files manually, as your change will be lost the next time the icons are overwritten.
 * @see https://chakra-ui.com/docs/components/icon/usage for more documentation
 * @see https://react-svgr.com/ for more on the tool used to generate the components
 * @see svgr-icon-template.js for the template
 */
export const User02: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'User02',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path
        fill="none"
        d="M12 15C8.82993 15 6.0108 16.5306 4.216 18.906C3.82971 19.4172 3.63656 19.6728 3.64288 20.0183C3.64776 20.2852 3.81536 20.6219 4.02537 20.7867C4.29719 21 4.67387 21 5.42724 21H18.5728C19.3261 21 19.7028 21 19.9746 20.7867C20.1846 20.6219 20.3522 20.2852 20.3571 20.0183C20.3634 19.6728 20.1703 19.4172 19.784 18.906C17.9892 16.5306 15.1701 15 12 15Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        fill="none"
        d="M12 12C14.4853 12 16.5 9.98528 16.5 7.5C16.5 5.01472 14.4853 3 12 3C9.51472 3 7.5 5.01472 7.5 7.5C7.5 9.98528 9.51472 12 12 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
export default User02;
