import {
  StyleFunctionProps,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const baseStyleItem = defineStyle(
  ({ theme, isSelected }: StyleFunctionProps) => {
    return {
      borderWidth: 0,
      color: 'text.secondary',
      py: theme.pxToRem(10),
      px: '4',
      height: theme.pxToRem(36),
      transitionProperty: 'background',
      transitionDuration: 'ultra-fast',
      transitionTimingFunction: 'ease-in',
      backgroundColor: isSelected ? 'primary.100' : 'base.white',
      borderRadius: 'md',
      outline: 'none',
      _focus: {
        bg: 'primary.100',
      },
      _visited: {
        color: 'inherit',
      },
      _hover: {
        bg: 'primary.100',
      },
      _focusVisible: {
        outlineColor: 'primary.300',
        outlineStyle: 'solid',
        outlineWidth: '0.125rem',
      },
      _active: {
        bg: 'primary.300',
      },
      _expanded: {
        bg: 'neutral.100',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    };
  }
);

export const autocompleteOptionTheme = defineStyleConfig({
  baseStyle: baseStyleItem,
});
