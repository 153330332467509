import { NotFound } from 'pages/error/not-found';
import { Route, Routes as RouterRoutes } from 'react-router-dom';

export const Routes = ({ children }) => {
  return (
    <RouterRoutes>
      {children}
      <Route path="*" element={<NotFound />} />
    </RouterRoutes>
  );
};
