export const fileTypes = {
  FINANCIAL: 'FINANCIAL',
  STORE_DATA: 'STORE_DATA',
  COMMON: 'COMMON',
};

export const SurveyStatusEnums = {
  RED: 'RED',
  YELLOW: 'YELLOW',
  GREEN: 'GREEN',
};
