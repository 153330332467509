export const colors = {
  base: {
    black: '#000000',
    overlayBackground: 'rgba(102, 112, 133, .9)',
    white: '#FFFFFF',
  },
  cardsDialog: {
    primaryLight: '#FEFEFE',
  },
  error: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#FA7066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
  },
  feature: {
    25: '#FDF9FF',
    50: '#F8F1FB',
    100: '#E4CBF1',
    200: '#D7ADEB',
    300: '#BD80DA',
    400: '#AE63D2',
    500: '#A047CA',
    600: '#8E35B8',
    700: '#782D9C',
    800: '#62257F',
    900: '#4C1D63',
  },
  icon: {
    primaryDark: '#4A505C',
    primaryLight: '#FEFEFE',
    secondary: '#444CE7',
    disabled: '#A9ADB6',
    success: '#039855',
    warning: '#DC6803',
    error: '#D92D20',
    feature: '#8E35B8',
  },
  neutral: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#10182B',
  },
  primary: {
    25: '#F5F8FF',
    50: '#EEF4FF',
    100: '#E0EAFF',
    200: '#C7D7FE',
    300: '#A4BCFD',
    400: '#8098F9',
    500: '#6172F3',
    600: '#444CE7',
    700: '#3538CD',
    800: '#2D31A6',
    900: '#2D3282',
  },
  success: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
  },
  text: {
    primary: '#1D2433',
    secondary: '#4A505C',
    disabled: '#8D9198',
  },
  textWhite: {
    primary: '#FEFEFE',
    secondary: '#C3C5C9',
    disabled: '#888C94',
  },
  warning: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
};
