import { Alert, AlertDescription, Link } from 'bam';
import { Outlet, Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { useFlags } from 'showroom/use-flags';

export const TargetedBanner = () => {
  const { pathname } = useLocation();

  const { targetedBanner } = useFlags();

  if (
    !targetedBanner ||
    JSON.stringify(targetedBanner) === '{}' ||
    (targetedBanner?.path && pathname.indexOf(targetedBanner?.path) === -1)
  ) {
    return <Outlet />;
  }

  return (
    <>
      <Alert borderRadius="0" status={targetedBanner.status} gap="2">
        <AlertDescription>{targetedBanner.body}</AlertDescription>
        <AlertDescription alignItems="space-between">
          {targetedBanner.actionType == 'link' ? (
            <Link as={ReactRouterLink} to={targetedBanner.link}>
              {targetedBanner.cta || "let's go!"}
            </Link>
          ) : (
            <Link href={targetedBanner.link} target="_leap">
              {targetedBanner.cta}
            </Link>
          )}
        </AlertDescription>
      </Alert>
      <Outlet />
    </>
  );
};
