import { BaseEvent, useTrack } from 'analytics/use-track';
import { EventTrackerParams, eventTracker } from 'analytics/analytics-utils';
import {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
  useMutation as useRQMutation,
} from '@tanstack/react-query';
import { useStateValue } from 'providers';

export function useMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationFn'
  > & {
    onSuccessEvent?: (
      data: TData,
      variables: TVariables,
      context: TContext | undefined
    ) => BaseEvent | EventTrackerParams;
  }
): UseMutationResult<TData, TError, TVariables, TContext> {
  const [, dispatch] = useStateValue();

  const { track } = useTrack();

  const effectiveOptions: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationFn'
  > & {
    onSuccessEvent?: (
      data: TData,
      variables: TVariables,
      context: TContext | undefined
    ) => BaseEvent | EventTrackerParams;
  } = {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccessEvent) {
        const successEvent = options.onSuccessEvent(data, variables, context);
        // new format event
        if ('subject' in successEvent) {
          track(successEvent);
        } else if ('type' in successEvent) {
          eventTracker(successEvent);
        }
      }
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      if ((error as any) && (error as any).cause) {
        dispatch((error as any).cause);
      }
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  };

  const mutation = useRQMutation<TData, TError, TVariables, TContext>(
    mutationFn,
    effectiveOptions
  );

  return mutation;
}
