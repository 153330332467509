import {
  ComponentStyleConfig,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const getBorderRadius = (shape) => {
  switch (shape) {
    case 'round':
      return '50%';
    case 'square':
    default:
      return 'lg';
  }
};

const baseStyle = defineStyle(({ shape }) => {
  return {
    borderRadius: getBorderRadius(shape),
    borderStyle: 'solid',
    borderWidth: '1px',
    color: 'textWhite.primary',
    cursor: 'pointer',
    fontFamily: 'inherit',
    letterSpacing: '.02em',
    py: 0,
    _visited: {
      color: 'textWhite.primary',
      _disabled: {
        color: 'text.disabled',
      },
    },
    _disabled: {
      bg: 'neutral.200',
      borderColor: 'neutral.200',
      color: 'text.disabled',
      opacity: 1,
    },
    _focusVisible: {
      outlineColor: 'primary.300',
      outlineOffset: 0,
      outlineStyle: 'solid',
      outlineWidth: '0.125rem',
    },
  };
});

const sizes = {
  xs: defineStyle(({ padding }) => {
    return {
      h: 6,
      minW: 6,
      px: padding ? padding : 2,
    };
  }),
  sm: defineStyle(({ theme, padding }) => {
    return {
      h: 8,
      minW: 8,
      px: padding ? padding : 3,
      ...theme.textStyles.labelSmallBold,
      '.chakra-button__group > &': {
        ...theme.textStyles.labelSmallMedium,
      },
    };
  }),
  lg: defineStyle(({ theme, padding }) => {
    return {
      h: 10,
      minW: 10,
      px: padding ? padding : 4,
      ...theme.textStyles.labelLargeBold,
      '.chakra-button__group > &': {
        ...theme.textStyles.labelLargeMedium,
      },
    };
  }),
  none: defineStyle({}),
};

const primaryVariant = defineStyle({
  bg: 'primary.600',
  borderColor: 'primary.600',
  _hover: {
    bg: 'primary.700',
    borderColor: 'primary.700',
    _disabled: {
      bg: 'neutral.200',
      borderColor: 'neutral.200',
    },
  },
  _active: {
    bg: 'primary.800',
    borderColor: 'primary.800',
  },
});

const secondaryVariant = defineStyle({
  bg: 'base.white',
  borderColor: 'neutral.300',
  color: 'text.primary',
  '.chakra-button__group > &': {
    _hover: {
      bg: 'primary.100',
      _disabled: {
        bg: 'neutral.200',
        borderColor: 'neutral.300',
      },
    },
    _active: {
      bg: 'primary.600',
      color: 'textWhite.primary',
      _disabled: {
        bg: 'primary.600',
        borderColor: 'neutral.300',
        cursor: 'default',
      },
    },
    _disabled: {
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
  },
  '.chakra-button__group[data-attached][data-orientation=horizontal] > &:not(:last-of-type)':
    {
      marginEnd: '-1px',
    },
  _disabled: {
    borderColor: 'neutral.300',
  },
  _visited: {
    color: 'text.primary',
  },
  _hover: {
    bg: 'neutral.200',
    _disabled: {
      bg: 'neutral.200',
      borderColor: 'neutral.300',
    },
  },
  _active: {
    bg: 'neutral.300',
  },
  _focusVisible: {
    borderColor: 'base.white',
  },
});

const dangerVariant = defineStyle({
  bg: 'error.600',
  borderColor: 'error.600',
  _visited: {
    color: 'textWhite.primary',
  },
  _hover: {
    bg: 'error.700',
    borderColor: 'error.700',
    _disabled: {
      bg: 'neutral.200',
      borderColor: 'neutral.200',
    },
  },
  _active: {
    bg: 'error.800',
    borderColor: 'error.800',
  },
});

const tertiaryVariant = defineStyle({
  bg: 'transparent',
  borderColor: 'transparent',
  color: 'primary.600',
  fontWeight: 'medium',
  _visited: {
    color: 'primary.600',
  },
  _hover: {
    bg: 'transparent',
    borderColor: 'transparent',
    color: 'primary.700',
    textDecoration: 'none',
    _disabled: {
      bg: 'transparent',
      borderColor: 'transparent',
      color: 'neutral.200',
    },
  },
  _active: {
    bg: 'transparent',
    borderColor: 'transparent',
    color: 'primary.800',
  },
});

const variants = {
  primary: primaryVariant,
  secondary: secondaryVariant,
  danger: dangerVariant,
  tertiary: tertiaryVariant,
};

export const buttonTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    variant: 'primary',
    size: 'lg',
  },
});
