import { Box } from '@chakra-ui/react';
import { type NavProps } from './nav.types';
import React from 'react';

/**
 * Top level component for app navigation. Used to compose subcomponents to build
 * sidenav.
 *
 * ```tsx
 * <Nav>
 *   <NavList>
 *     <NavItem icon={<Users02 />} label="Users" to="/users" />
 *     <NavItem icon={<MarkerPin01 />} label="Spaces" notification={<Notification>10</Notification>} to="/spaces" />
 *     <NavItem icon={<Building02 />} label="Stores" to="/stores">
 *       <NavList>
 *         <SubNavItem label="123 Main Street" to="/stores/LXX-001" />
 *         <SubNavItem label="123 Elm Street" to="/stores/LXX-002" />
 *       </NavList>
 *    </NavList
 * </Nav>
 * ```
 * */
export const Nav: React.FC<NavProps> = ({ children, ...rest }) => {
  return (
    <Box as="nav" {...rest}>
      {children}
    </Box>
  );
};
