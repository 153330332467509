import ReactGA from 'react-ga4';

export const code = process.env.GA_TRACKING_CODE || 'STUB';

const config = code.split(',').map((code) => ({
  trackingId: code,
  testMode: process.env.NODE_ENV === 'test',
}));

ReactGA.initialize(config);

export default ReactGA;
