import {
  MenuItem as ChakraMenuItem,
  MenuItemProps as ChakraMenuItemProps,
  forwardRef,
} from '@chakra-ui/react';

type MenuItemProps = ChakraMenuItemProps;

/**
 * Wrapper for Chakra ui MenuItem component, [view documentation](https://chakra-ui.com/docs/components/menu). Accepts all props that the chakra component does
 */
export const MenuItem = forwardRef<MenuItemProps, 'button'>((props, ref) => {
  return <ChakraMenuItem {...props} ref={ref} />;
});
