import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/react';
import { formErrorAnatomy as parts } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const $fg = cssVar('form-error-color');
const $bg = cssVar('form-error-background-color');

const baseStyleIcon = defineStyle({
  [$fg.variable]: 'colors.icon.error',
  color: $fg.reference,
  mr: 4,
});

const baseStyleText = defineStyle(({ theme }) => {
  return {
    [$bg.variable]: 'colors.error.50',
    bg: $bg.reference,
    borderRadius: 'base',
    color: 'text.primary',
    mt: 1,
    p: 1,
    ...theme.textStyles.labelSmallRegular,
  };
});

const baseStyle = definePartsStyle((props) => {
  return {
    icon: baseStyleIcon,
    text: baseStyleText(props),
  };
});

export const formErrorTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'error',
  },
});
