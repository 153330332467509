import {
  ComponentStyleConfig,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const baseStyle = defineStyle({
  color: 'text.primary',
  fontSize: 'lg',
  letterSpacing: 'normal',
  lineHeight: 'base',
  mt: 0,
  strong: {
    fontWeight: 'medium',
  },
});

const variants = {
  bold: defineStyle(({ theme }) => {
    return {
      ...theme.textStyles.paragraphBold,
    };
  }),
  regular: defineStyle(({ theme }) => {
    return {
      ...theme.textStyles.paragraphRegular,
    };
  }),
};

export const textTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    size: 'lg',
    variant: 'regular',
  },
});
