import { DialogHeaderProps, DialogSize } from './dialog.types';
import {
  FeaturedIcon,
  FeaturedIconSizes,
} from '../../media-icons/featured-icon';
import { ModalCloseButton, ModalHeader, forwardRef } from '@chakra-ui/react';
import { Text } from '../../typography/text';
import { textStyles } from '../../../theme/text-styles';

const sizeToTextStyle: Record<'sm' | 'lg', keyof typeof textStyles> = {
  sm: 'headingSmallBold',
  lg: 'headingLargeBold',
};

const sizeToFeaturedIconSize: Record<DialogSize, FeaturedIconSizes> = {
  sm: 'xs',
  lg: 'md',
  '4xl': 'md',
};

const sizeToPaddingTop = {
  sm: '4',
  md: '6',
};
const sizeToPaddingX = {
  sm: '4',
  md: '6',
};

/**
 * DialogHeader is a wrapper for [Chakra UI Modal Header](https://chakra-ui.com/docs/components/modal/usage)
 *
 */
export const DialogHeader = forwardRef<DialogHeaderProps, 'div'>(
  (props, ref) => {
    const {
      children,
      closeButton,
      featuredIcon,
      featuredIconVariant = 'lightCircle',
      size,
      ...rest
    } = props;

    return (
      <ModalHeader
        {...rest}
        ref={ref}
        pt={size ? sizeToPaddingTop[size] : { base: '4', md: '6' }}
        px={size ? sizeToPaddingX : { base: '4', md: '6' }}
      >
        {featuredIcon ? (
          <FeaturedIcon
            size={
              size ? sizeToFeaturedIconSize[size] : { base: 'xs', md: 'md' }
            }
            color="primary"
            variant={featuredIconVariant}
            icon={featuredIcon}
            mr="2"
          />
        ) : null}
        <Text
          textStyle={
            size
              ? sizeToTextStyle[size]
              : { base: 'headingSmallBold', md: 'headingLargeBold' }
          }
        >
          {children}
        </Text>
        {closeButton ? <ModalCloseButton ml="auto" /> : null}
      </ModalHeader>
    );
  }
);
