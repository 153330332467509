import {
  CloseButton as ChakraCloseButton,
  CloseButtonProps as ChakraCloseButtonProps,
  forwardRef,
} from '@chakra-ui/react';
import { XClose } from '../../../icons';

export type CloseButtonProps = Omit<ChakraCloseButtonProps, 'size'>;

/**
 * Themed version of Chakra's [`Close Button`](https://chakra-ui.com/docs/components/close-button).
 * */
export const CloseButton = forwardRef<CloseButtonProps, 'button'>(
  ({ ...rest }, ref) => {
    return (
      <ChakraCloseButton {...rest} className={'bam-close'} ref={ref}>
        <XClose h={6} w={6} />
      </ChakraCloseButton>
    );
  }
);

CloseButton.displayName = 'CloseButton';
