import { ProtectedRoute } from '../ProtectedRoute';
import { Route } from 'react-router-dom';
import { RouteLoadingFallback } from 'routes/route-loading-fallback';
import { Routes } from '../Routes';
import { lazy } from 'react';

const BrandsPresentRouter = lazy(() => import('./brands-present-router'));

export const AdminPresentRouter = () => {
  return (
    <RouteLoadingFallback>
      <Routes>
        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ subject: 'admin:brands', action: 'get' }}
            />
          }
        >
          <Route path="brands/*" element={<BrandsPresentRouter />} />
        </Route>
      </Routes>
    </RouteLoadingFallback>
  );
};
