import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['root', 'target', 'icon', 'input', 'item', 'items'];

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle(() => {
  return {
    root: {
      alignItems: 'center',
      color: 'text.secondary',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      p: '6',
      position: 'relative',
    },
    target: {
      alignItems: 'center',
      alignSelf: 'stretch',
      background: 'primary.50',
      borderRadius: 'lg',
      borderWidth: '2px',
      borderStyle: 'dashed',
      borderColor: 'primary.200',
      color: 'primary.700',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      p: '6',
      position: 'relative',
      '&.over': {
        borderColor: 'primary.700',
      },
    },
    icon: {
      borderRadius: '50%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'primary.300',
      display: 'flex',
      p: '6',
    },
    input: {
      position: 'absolute',
      left: '0',
      top: '0',
      bottom: '0',
      right: '0',
      height: '100%',
      minHeight: '100%',
      maxHeight: '100%',
      opacity: 0,
    },
    items: {
      alignSelf: 'stretch',
      listStyle: 'none',
      p: 0,
      m: 0,
    },
    item: {
      borderRadius: 'lg',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'primary.200',
      listStyle: 'none',
      p: '6',
      pt: '3',
      pb: '3',
      m: 0,
      mt: '3',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      userSelect: 'none',
      svg: {
        cursor: 'pointer',
      },
    },
  };
});

const active = definePartsStyle(() => {
  return {
    target: {
      borderColor: 'primary.700',
    },
  };
});

export const fileUploadTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    active,
  },
});
