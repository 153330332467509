import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = [
  'container',
  'tooltip',
  'content',
  'heading',
  'subtitle',
  'callToAction',
];

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle(() => {
  return {
    container: {
      alignItems: 'center',
      background: 'primary.200',
      borderRadius: 'lg',
      border: 'none',
      borderColor: 'warning.300',
      boxShadow: '200',
      color: 'text.secondary',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      p: '6',
      position: 'relative',
    },
    tooltip: {
      position: 'absolute',
      color: 'primary.50',
      display: 'flex',
    },
    content: {
      flexDirection: 'column',
      alignSelf: 'stretch',
      color: 'primary.50',
      display: 'flex',
      width: '100%',
    },
    heading: {
      color: 'text.secondary',
      mb: '1',
    },
    subtitle: {
      color: 'text.secondary',
      lineHeight: '1.25',
    },
    callToAction: {
      color: 'primary.300',
      display: 'flex',
    },
  };
});

const horizontal = definePartsStyle(() => {
  return {
    container: {
      flexDirection: 'row',
      p: '3',
      gap: '4',
      justifyContent: 'flex-start',
    },
    tooltip: {
      top: 2,
      right: 2,
    },
    content: {
      justifyContent: 'flex-start',
    },
    heading: {
      color: 'primary.50',
      textAlign: 'left',
    },
    subtitle: {
      textAlign: 'left',
    },
    callToAction: {
      justifyContent: 'flex-end',
    },
  };
});

const vertical = definePartsStyle(() => {
  return {
    container: {},
    tooltip: {
      top: 3,
      right: 3,
    },
    content: {
      justifyContent: 'flex-start',
    },
    heading: {
      textAlign: 'left',
    },
    subtitle: {
      textAlign: 'left',
    },
    callToAction: {
      justifyContent: 'center',
      mt: '4',
      alignSelf: 'flex-end',
    },
  };
});

export const promoCardTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    horizontal,
    vertical,
  },
});
