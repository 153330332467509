import {
  StyleFunctionProps,
  createMultiStyleConfigHelpers,
  cssVar,
} from '@chakra-ui/styled-system';
import { alertAnatomy as parts } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const $fg = cssVar('alert-fg');
const $bg = cssVar('alert-bg');

const baseStyle = definePartsStyle((props: StyleFunctionProps) => ({
  container: {
    borderRadius: 'lg',
    px: '4',
    py: '4',
  },
  description: {
    lineHeight: 'normal',
    ...props.theme.textStyles.labelMediumRegular,
  },
}));

function getBackgroundColor(props: StyleFunctionProps): string {
  const { colorScheme: c, variant } = props;

  const isSubtle = variant === 'subtle';

  switch (c) {
    case 'info': {
      return isSubtle ? 'colors.primary.100' : 'colors.primary.600';
    }
    case 'error':
    case 'warning':
    case 'success':
      return isSubtle ? `colors.${c}.100` : `colors.${c}.600`;
    default:
      break;
  }
  return isSubtle ? 'primary.100' : 'primary.600';
}

function getIconColor(props: StyleFunctionProps) {
  const { colorScheme: c, variant } = props;

  const isSubtle = variant === 'subtle';

  switch (c) {
    case 'info':
      return isSubtle ? 'colors.icon.secondary' : 'colors.icon.primaryLight';
    case 'error':
    case 'warning':
    case 'success':
      return isSubtle ? `colors.icon.${c}` : 'colors.icon.primaryLight';
    default:
      break;
  }
  return 'colors.icon.secondary';
}

function getTextColor(props: StyleFunctionProps) {
  const { colorScheme: c, variant } = props;

  const isSubtle = variant === 'subtle';

  switch (c) {
    case 'info':
      return isSubtle ? 'colors.text.secondary' : 'colors.textWhite.primary';
    case 'error':
    case 'warning':
    case 'success':
      return isSubtle ? 'colors.text.secondary' : 'colors.textWhite.primary';
    default:
      break;
  }
  return 'colors.text.secondary';
}

const variantSubtle = definePartsStyle((props: StyleFunctionProps) => {
  return {
    container: {
      [$bg.variable]: getBackgroundColor(props),
      [$fg.variable]: getTextColor(props),
      borderWidth: 0,
    },
    description: {
      lineHeight: 'normal',
      ...props.theme.textStyles.labelMediumRegular,
    },
    icon: {
      [$fg.variable]: getIconColor(props),
    },
  };
});

const variantSolid = definePartsStyle((props: StyleFunctionProps) => {
  return {
    container: {
      [$bg.variable]: getBackgroundColor(props),
      [$fg.variable]: getTextColor(props),
      borderWidth: 0,
    },
    description: {
      lineHeight: 'normal',
      ...props.theme.textStyles.labelMediumRegular,
    },
    icon: {
      [$fg.variable]: getIconColor(props),
    },
  };
});

const variants = {
  subtle: variantSubtle,
  solid: variantSolid,
};

const sizes = {
  sm: definePartsStyle(() => {
    return {
      container: {
        py: '0.625rem', // 10px
      },
    };
  }),
};

export const alertTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    colorScheme: 'primary',
    variant: 'subtle',
  },
});
