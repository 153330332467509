import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/react';
import { radioAnatomy as parts } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleControl = defineStyle(({ theme }) => {
  return {
    ...theme.styleTokens.baseInputControl,
    borderRadius: 'full',
    _checked: {
      bg: 'base.white',
      borderColor: 'primary.600',
      _before: {
        bg: 'primary.600',
        h: '62.5%',
        w: '62.5%',
      },
      _disabled: {
        bg: 'base.white',
        borderColor: 'icon.disabled',
        _before: {
          backgroundColor: 'icon.disabled',
        },
      },
      _hover: {
        ...theme.styleTokens.hoverInputControl,
      },
    },
    _disabled: {
      ...theme.styleTokens.disabledInputControl,
      _hover: {
        ...theme.styleTokens.disabledInputControl,
      },
    },
    _focusVisible: {
      ...theme.styleTokens.focusVisible,
    },
    _hover: {
      ...theme.styleTokens.hoverInputControl,
    },
    _invalid: {
      borderColor: 'error.600',
    },
  };
});

const baseStyleLabel = defineStyle(({ theme }) => {
  return {
    ...theme.styleTokens.baseInputLabel,
  };
});

const baseStyle = definePartsStyle((props) => {
  return {
    control: baseStyleControl(props),
    label: baseStyleLabel(props),
  };
});

const sizes = {
  sm: definePartsStyle(({ theme }) => {
    return {
      control: { width: 4, height: 4 },
      label: { ...theme.textStyles.labelMediumRegular },
    };
  }),
  lg: definePartsStyle(({ theme }) => {
    return {
      control: { w: 5, h: 5 },
      label: { ...theme.textStyles.labelLargeRegular },
    };
  }),
};

export const radioTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle,
  sizes,
});
