import { Alert, AlertDescription, IFrame } from 'bam';
import { useGetAdminBrandsReportsEmbed } from 'hooks/api/admin/brands/reports/use-get-admin-brands-reports-embed';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface RetailReportEmbedProps {
  embed: string;
  height?: string;
  id?: string;
}

export const RetailReportEmbed: React.FC<RetailReportEmbedProps> = ({
  embed,
  height,
  id,
}) => {
  const { data: reportEmbed, error } = useGetAdminBrandsReportsEmbed(
    { embed },
    {
      enabled: !!embed,
      onSuccessEvent: () => {
        return {
          subject: 'RETAIL_REPORT_EMBED',
          action: 'REQUESTED',
        };
      },
    }
  );
  const { t } = useTranslation('analytics');

  if (error) {
    return <Alert status="error">{error.message}</Alert>;
  }

  if (!reportEmbed?.url) {
    return (
      <Alert status="error">
        <AlertDescription>{t('dataError.message')}</AlertDescription>
      </Alert>
    );
  }

  return <IFrame height={height} id={id} src={reportEmbed?.url ?? ''} />;
};
