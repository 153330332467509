import { ErrorTemplate } from 'bam';
import { ServerErrorIllustration } from 'components/images/illustrations/ServerErrorIllustration';
import { useTranslation } from 'react-i18next';

export const ServerError = () => {
  const { t } = useTranslation('error');

  return (
    <ErrorTemplate
      cover
      image={<ServerErrorIllustration />}
      message={t('500.message')}
      subtitle={t('500.subtitle')}
      title={t('500.title')}
    />
  );
};

export const renderServerError = () => {
  return <ServerError />;
};
