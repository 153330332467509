import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['imageContainer', 'banner'];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts);

function getBackgroundColor(colorScheme: string) {
  // rgba tokens or some way to convert tokens to rgba
  switch (colorScheme) {
    case 'error':
      return 'rgba(254, 243, 242, 0.8)';
    case 'feature':
      return 'rgba(248, 241, 251, 0.8)';
    case 'neutral':
      return 'rgba(249, 250, 251, 0.8)';
    case 'primary':
      return 'rgba(238, 244, 255, 0.8)';
    case 'success':
      return 'rgba(236, 253, 243, 0.8)';
    case 'warning':
      return 'rgba(255, 250, 235, 0.8)';
    default:
      return 'rgba(249, 250, 251, 0.8)';
  }
}

const baseStyle = definePartsStyle(({ theme, bannerColor: c }) => ({
  banner: {
    backgroundColor: getBackgroundColor(c),
    color: `${c}.600`,
    left: 0,
    right: 0,
    px: 2,
    py: '.625rem',
    position: 'absolute',
    top: 0,
    zIndex: 'banner',
    ...theme.textStyles.labelMediumMedium,
  },
  imageContainer: {
    overflow: 'hidden',
    position: 'relative',
    zIndex: 'base',
  },
}));

const horizontalVariant = definePartsStyle({
  imageContainer: {
    flex: '1 1 50%',
    alignSelf: 'stretch',
  },
});

export const miniCardTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    horizontal: horizontalVariant,
  },
});
