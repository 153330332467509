import { Metric } from 'web-vitals';
import { categories } from 'utils';
import { jitsu } from './jitsu';
import ReactGA from 'analytics/react-ga';

export const statusEnum = { RED: 0, YELLOW: 1, GREEN: 2 };

interface EventParams {
  category: string;
  action: string;
  label?: string;
  value?: number;
}

export interface EventTrackerParams {
  category?: string;
  type: string;
  label?: string;
  status?: keyof typeof statusEnum;
  data?: any;
  value?: number;
}

const session = () => {
  const sessionId = Math.random().toString(36).substring(2);
  return { id: sessionId, start: new Date() };
};
export const eventTracker = ({
  category,
  type,
  label,
  status,
  value,
}: EventTrackerParams) => {
  const params: EventParams = {
    category: category || categories(type),
    action: type,
    label,
  };

  if (value) {
    params.value = value;
  } else if (status) {
    params.value = statusEnum[status];
  }

  jitsu.track(type, { ...params });
  ReactGA.event(params);
};

export const identify = ({ data }) => {
  jitsu.identify(data.id, {
    brand: {
      name: data?.brands?.[0]?.name || 'leap-admin',
      id: data?.brands?.[0]?.id || 'leap',
    },
    email: data.email,
    internal_id: data.sub,
    userId: btoa(data.email),
    session: session(),
  });
  jitsu.group(data?.brands?.[0]?.handle, {
    name: data?.brands?.[0]?.name || 'leap-admin',
    id: data?.brands?.[0]?.id || 'leap',
  });
};

export const setDimension = (key, val) => {
  jitsu.track(key, val);
  ReactGA.set({
    [key]: val,
  });
  ReactGA.gtag('set', { user_properties: { [key]: val } });
};

/**
 * sends core webvitals metrics to jitsu
 * see useCoreWebVitals.tsx for more info
 * @param metric
 */
export const trackCoreWebVital = (metric: Metric) => {
  jitsu.track('core-web-vital', metric);
};
