import {
  ComponentStyleConfig,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const baseStyle = defineStyle(({ theme, textStyle }) => {
  const textStyleStyles = textStyle
    ? theme.textStyles[textStyle]
    : theme.textStyles.labelLargeMedium;
  return {
    color: 'text.primary',
    mb: 2,
    ...textStyleStyles,
    _disabled: {
      color: 'text.disabled',
      opacity: 1,
    },
  };
});

export const formLabelTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle,
});
