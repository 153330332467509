export const typography = {
  fonts: {
    body: 'InterVariable, sans-serif',
    heading: 'InterVariable, sans-serif',
    mono: 'InterVariable, sans-serif',
  },
  fontSizes: {
    xs: '.625rem' /* 10px */,
    sm: '.75rem' /* 12px */,
    md: '.875rem' /* 14px */,
    lg: '1rem' /* 16px */,
    xl: '1.375rem' /* 22px */,
    '2xl': '1.75rem' /* 28px */,
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  letterSpacings: {
    normal: '0',
    tight: '-.02em',
    wide: '.02em',
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    base: 1.5,
    smallHeading: 1.272727272727273 /* 22/28 */,
    largeHeading: 1.428571428571429 /* 28/40 */,
  },
};
