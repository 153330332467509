import {
  Flag,
  convertSearchParamFlags,
  mergeFlags,
  sessionStorageKey,
} from './use-flags-utils';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

/**
 * used by EndcapProvider to provide central management of flags and overrides
 */
export const useManageFlags = (
  LDFLags: LDFlagSet
): {
  flags: LDFlagSet;
  setFlag: (flag: Flag) => void;
  localFlags: LDFlagSet;
  removeFlag: (flag: string) => void;
} => {
  const [searchParams] = useSearchParams();

  const [localFlags, setFlags] = useSessionStorage<LDFlagSet>(
    sessionStorageKey,
    {}
  );

  const queryStringFlags = useMemo(
    () => convertSearchParamFlags(searchParams),
    [searchParams]
  );

  const [ldFlags, setLdFlags] = useState<LDFlagSet>(LDFLags);

  useEffect(() => {
    setLdFlags(LDFLags);
  }, [LDFLags]);

  const setFlag = ({ name, enabled }: Flag) => {
    const newLocalFlags: LDFlagSet = {
      ...localFlags,
      [name]: enabled,
    };
    setFlags(newLocalFlags);
  };

  const removeFlag = (flag: string) => {
    const newLocalFlags: LDFlagSet = {
      ...localFlags,
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [flag]: removeKey, ...rest } = newLocalFlags;

    setFlags(rest);
  };

  const mergedFlags = mergeFlags(ldFlags, localFlags, queryStringFlags);

  return { flags: mergedFlags, setFlag, localFlags, removeFlag };
};
