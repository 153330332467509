import { Global, css, useTheme } from '@emotion/react';

export const GlobalStyles = () => {
  const theme = useTheme();

  const GlobalCSS = css`
    *,
    :after,
    :before {
      box-sizing: border-box;
    }

    html,
    body {
      background-color: #fff;
    }

    body {
      font-family: 'InterVariable', sans-serif;
      font-weight: 300;
      margin: 0;
      min-height: 100vh;
      @supports (-webkit-touch-callout: none) {
        /* mobile viewport bug fix */
        min-height: -webkit-fill-available;
      }
    }

    a {
      color: ${theme.colorsDeprecated('link')};
      text-decoration: none;
      &:visited {
        color: ${theme.colorsDeprecated('link', 0.85, true)};
      }
    }

    ::placeholder {
      color: ${theme.colorsDeprecated('background', 0.15, true)};
      font-family: 'InterVariable', sans-serif;
      font-weight: 400;
    }

    .leaping_logo {
      animation: leap 4s infinite linear both;
      stroke-dasharray: 200;
      stroke-dashoffset: 420;
    }

    .bouncing_logo {
      animation: bounce 3s infinite linear both;
      stroke-dasharray: 200;
      stroke-dashoffset: 420;
    }

    @keyframes leap {
      0% {
        stroke-dashoffset: 420;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes bounce {
      0% {
        stroke-dashoffset: 420;
      }
      50% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: 420;
      }
    }

    .stop-animation * {
      animation: none !important;
      transition: none !important;
    }

    b {
      font-weight: 400;
    }
    ::-webkit-datetime-edit {
      border-color: #369;
      font-family: 'InterVariable', sans-serif;
      font-weight: 400;
    }

    ::-webkit-datetime-edit-fields-wrapper {
      border-color: #369;
      padding: 0.5rem;
      border-radius: 2px;
      &:focus-within {
        border-color: ${theme.colorsDeprecated('text')};
      }
    }
    ::-webkit-datetime-edit-text {
    }
    ::-webkit-datetime-edit-month-field {
    }
    ::-webkit-datetime-edit-day-field {
    }
    ::-webkit-datetime-edit-year-field {
    }
    ::-webkit-inner-spin-button {
    }
    ::-webkit-calendar-picker-indicator {
      opacity: 0.25;
      margin-right: 0.25rem;
    }
  `;

  return <Global styles={GlobalCSS} />;
};
