export const sumArray = (array, valueKey = null, initialVal = 0) => {
  if (valueKey) {
    return array.reduce((a, b) => a + b[valueKey], initialVal);
  }
  return array.reduce((a, b) => a + b, initialVal);
};

export function fnNumSorter(fn) {
  return (a, b) => {
    const aval = fn(a);
    const bval = fn(b);
    return aval > bval ? 1 : aval < bval ? -1 : 0;
  };
}

export function fnStrSorter(fn) {
  return (a, b) => fn(a).localeCompare(fn(b));
}

/*
 *   Sorts an array of objects based on a number and string data type property
 * */
export function sortObjectArray(array, sortKey, sortOrder = 'ASC') {
  return array.sort((a, b) => {
    if (typeof a[sortKey] === 'number' && typeof b[sortKey] === 'number') {
      return sortOrder === 'ASC'
        ? a[sortKey] - b[sortKey]
        : b[sortKey] - a[sortKey];
    } else {
      return sortOrder === 'ASC'
        ? a[sortKey].localeCompare(b[sortKey])
        : b[sortKey].localeCompare(a[sortKey]);
    }
  });
}

export function scoreSorter(score_name) {
  return fnNumSorter((s) => s.scores[score_name]);
}

export const calculateMedians = (data) => {
  const medians = {
    brand: 0,
    composite: 0,
    brandloc: 0,
    location: 0,
  };
  if (data.length > 0) {
    medians.composite = data.sort(scoreSorter('total'))[
      Math.floor(data.length / 2)
    ].scores.total;
    medians.brand = data.sort(scoreSorter('client'))[
      Math.floor(data.length / 2)
    ].scores.client;
    medians.brandloc = data.sort(scoreSorter('market'))[
      Math.floor(data.length / 2)
    ].scores.market;
    medians.location = data.sort(scoreSorter('location'))[
      Math.floor(data.length / 2)
    ].scores.location;
  }
  return medians;
};

export const filterUnique = (arry) => {
  let data = arry.map(JSON.stringify);
  return [...new Set(data)].map(JSON.parse);
};

export const removeKeys = (arrayOfKeys, oldObject) => {
  const arrayOfKeysCopy = [...arrayOfKeys];
  if (arrayOfKeysCopy.length === 0) {
    return oldObject;
  }
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  let { [arrayOfKeysCopy.pop()]: _, ...newObject } = oldObject;
  return removeKeys(arrayOfKeysCopy, newObject);
};
