import {
  ComponentStyleConfig,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

const baseStyle = defineStyle({
  color: 'text.secondary',
  letterSpacing: 'normal',
  mb: '.5em',
  mt: 0,
});

const variants = {
  bold: defineStyle(({ theme }) => {
    return {
      ...theme.textStyles.subheadingBold,
    };
  }),
  medium: defineStyle(({ theme }) => {
    return {
      ...theme.textStyles.subheadingMedium,
    };
  }),
  regular: defineStyle(({ theme }) => {
    return {
      ...theme.textStyles.subheadingRegular,
    };
  }),
};

export const subheadingTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'regular',
  },
});
