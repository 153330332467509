import * as React from 'react';
import { LeapIcons } from './LeapIcons';
import { ThemePaletteKeys } from '../../../types/styled';
import { createId } from 'utils/createId';
import { makeCamelCased } from 'utils/string-utils';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

interface StyledSvgProps {
  padding?: string;
}

export const StyledSvg = styled.svg<StyledSvgProps>`
  vertical-align: text-top;

  ${({ padding }) => `
    ${padding ? `padding: ${padding};` : ''}
  `}
`;

export interface IBaseIcon {
  className?: string;
  fill?: ThemePaletteKeys | 'currentColor';
  customFill?: string;
  height?: string | number;
  padding?: string;
  stroke?: string;
  strokeWidth?: string;
  title?: string;
  transform?: string;
  width?: string | number;
}

export type IconType = keyof typeof LeapIcons; // uses keys from LeapIcons obj to create a union type of strings

export interface IIconProps extends IBaseIcon {
  colorizeFill?: boolean;
  icon: IconType;
}

export const Icon: React.FC<IIconProps> = ({
  className,
  colorizeFill = false,
  customFill,
  fill,
  height = 24,
  icon,
  padding = '0',
  stroke = 'none',
  strokeWidth = '1',
  title,
  transform,
  width = 24,
}) => {
  const theme = useTheme();

  let fillColor;
  if (colorizeFill) {
    fillColor = undefined;
  } else if (customFill) {
    fillColor = customFill;
  } else if (fill && fill !== 'currentColor') {
    fillColor = theme.colorsDeprecated(fill);
  } else if (fill === 'currentColor') {
    fillColor = fill;
  } else {
    fillColor = theme.colorsDeprecated('text');
  }

  const iconId = title ? createId(title) : undefined;

  return (
    <StyledSvg
      aria-labelledby={iconId}
      className={className}
      fill="none"
      height={height}
      padding={padding}
      transform={transform}
      viewBox="0 0 24 24"
      width={width}
    >
      {title && <title id={iconId}>{title}</title>}
      <path
        className={colorizeFill ? 'colorize-fill' : undefined}
        clipRule="evenodd"
        d={LeapIcons[makeCamelCased(icon)]}
        fill={fillColor}
        fillRule="evenodd"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </StyledSvg>
  );
};
