import { addMessage } from 'actions/messages';
import { defaultMessageTimeout } from 'reducers/messages';
import { getBrandAdapterShopifyInstall } from 'api/brand/adapter/get-brand-adapter-install';
import { useMutation } from 'api/useMutation';
import { useSearchParams } from 'react-router-dom';
import { useStateValue } from 'providers';
import { useTranslation } from 'react-i18next';

export function useGetBrandAdapterShopifyInstall() {
  const [searchParams] = useSearchParams();
  const [, dispatch] = useStateValue();
  const { t } = useTranslation('translation', {
    keyPrefix: 'connectShopifyAdapter',
  });

  const getMutation = useMutation(
    () => {
      return getBrandAdapterShopifyInstall(searchParams.toString());
    },
    {
      onSuccess: () => {
        dispatch(
          addMessage({
            ephemeral: defaultMessageTimeout,
            message: t('connectionSuccessMessage.body'),
            title: t('connectionSuccessMessage.title'),
            type: 'success',
          })
        );
      },
      onSuccessEvent: () => ({
        category: 'SHOPIFY_ADAPTER',
        type: 'CONNECT_SUCCESS',
      }),
    }
  );

  return getMutation;
}
