import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers([
    'chakraSwiper',
    'chakraSwiperSlide',
    'center',
    'image',
  ]);

const baseChakraSwiper = defineStyle({
  w: '100%',
  h: '100%',
});

// Adding it anyway in case we need to modify look later
const baseChakraSwiperSlide = defineStyle({
  height: 'auto',
});

const baseCenterStyle = defineStyle({
  h: 'auto',
});

const baseImageStyle = defineStyle({
  h: { base: 'auto', sm: 'auto', md: 'auto', lg: '75vh' },
  objectFit: 'contain',
  overflow: 'hidden',
  marginX: '3rem',
});

const baseStyle = definePartsStyle({
  chakraSwiper: baseChakraSwiper,
  chakraSwiperSlide: baseChakraSwiperSlide,
  center: baseCenterStyle,
  image: baseImageStyle,
});

export const lightboxTheme = defineMultiStyleConfig({
  baseStyle,
});
