import { IllustrationProps } from './illustration.types';

export const Magnascope = ({
  height = '100%',
  width = '100%',
  transform,
}: IllustrationProps) => {
  return (
    <svg
      fill="none"
      height={height}
      transform={transform}
      viewBox="0 0 1024 1024"
      width={width}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M214.959 836.045L187.807 808.893L405.062 591.636L432.214 618.79L214.959 836.045Z"
        fill="#A3B5D6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M629.252 639.4C494.051 639.4 384.452 529.799 384.452 394.6C384.452 259.4 494.051 149.8 629.252 149.8C764.45 149.8 874.052 259.4 874.052 394.6C874.052 529.799 764.45 639.4 629.252 639.4ZM629.252 68.1997C448.985 68.1997 302.852 214.333 302.852 394.6C302.852 574.865 448.985 721 629.252 721C809.517 721 955.652 574.865 955.652 394.6C955.652 214.333 809.517 68.1997 629.252 68.1997Z"
        fill="#597EF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M456.197 394.599C456.197 299.025 533.676 221.546 629.252 221.546C724.826 221.546 802.305 299.025 802.305 394.599C802.305 490.175 724.826 567.654 629.252 567.654C533.676 567.654 456.197 490.175 456.197 394.599Z"
        fill="#597EF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M629.252 490.6C576.231 490.6 533.252 447.619 533.252 394.6C533.252 341.58 576.231 298.6 629.252 298.6C682.272 298.6 725.252 341.58 725.252 394.6C725.252 447.619 682.272 490.6 629.252 490.6Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.729 941.275L82.5769 914.122C75.0791 906.624 75.0792 894.467 82.577 886.969L273.338 696.207C280.836 688.709 292.993 688.708 300.491 696.207C300.491 696.207 300.491 696.207 300.491 696.207L327.644 723.36C335.142 730.858 335.142 743.015 327.644 750.513L136.882 941.275C129.384 948.773 117.227 948.773 109.729 941.275C109.729 941.275 109.729 941.275 109.729 941.275Z"
        fill="#597EF7"
      />
    </svg>
  );
};
