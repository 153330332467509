import { AlertCircle } from 'bam/icons';
import {
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
} from '@chakra-ui/react';
import { FormControlProps } from './form-control-types';
import { Text } from '../../typography/text';
import { useTranslation } from 'react-i18next';

export const FormControl = forwardRef<FormControlProps, 'div'>(
  (
    {
      children,
      disabled,
      errorText,
      hintText,
      id,
      invalid,
      label,
      required,
      optional,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation('bam', { keyPrefix: 'formControl' });
    return (
      <ChakraFormControl
        ref={ref}
        {...rest}
        isDisabled={disabled}
        isInvalid={invalid}
        isRequired={required}
      >
        {label ? (
          <FormLabel htmlFor={id}>
            {label}
            {optional ? (
              <Text
                as="span"
                textStyle="labelSmallRegular"
                color="text.secondary"
              >
                {' '}
                ({t('optional')})
              </Text>
            ) : null}
          </FormLabel>
        ) : null}
        {children}
        {hintText && <FormHelperText>{hintText}</FormHelperText>}
        {errorText && (
          <FormErrorMessage>
            <AlertCircle boxSize={4} color={'error.600'} mr={1} />
            {errorText}
          </FormErrorMessage>
        )}
      </ChakraFormControl>
    );
  }
);
