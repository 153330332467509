import { apiClient } from 'api/apiClient';

export const getBrandAdapterShopifyAuthorizeFull = async (
  instance: string
): Promise<any> => {
  return await apiClient.callFetch(
    `/brand/adapter/oauth/shopify/authorize?instance=${instance}&tier=FULL&redirect_uri=${document.location.href}%3Fadapter%3Dshopify`,
    {},
    true
  );
};
