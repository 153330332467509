import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = [
  'container',
  'icon',
  'tooltip',
  'content',
  'heading',
  'subtitle',
  'callToAction',
];

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle(() => {
  return {
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      p: '6',
      position: 'relative',
    },
    icon: {},
    tooltip: {
      position: 'absolute',
      display: 'flex',
    },
    content: {
      flexDirection: 'column',
      alignSelf: 'stretch',
      display: 'flex',
      width: '100%',
    },
    heading: {
      mb: '1',
    },
    subtitle: {
      color: 'text.secondary',
      lineHeight: '1.25',
    },
    callToAction: {
      display: 'flex',
    },
  };
});

const horizontal = definePartsStyle(() => {
  return {
    container: {
      flexDirection: 'row',
      p: '3',
      gap: '4',
      justifyContent: 'flex-start',
    },
    icon: {
      alignSelf: 'flex-start',
    },
    tooltip: {
      top: 2,
      right: 2,
    },
    content: {
      justifyContent: 'flex-start',
    },
    heading: {
      textAlign: 'left',
    },
    subtitle: {
      textAlign: 'left',
    },
    callToAction: {
      justifyContent: 'flex-end',
    },
  };
});

const vertical = definePartsStyle(() => {
  return {
    container: {},
    icon: {
      mb: '4',
    },
    tooltip: {
      top: 3,
      right: 3,
    },
    content: {
      justifyContent: 'center',
    },
    heading: {
      textAlign: 'center',
    },
    subtitle: {
      textAlign: 'center',
    },
    callToAction: {
      justifyContent: 'center',
      mt: '4',
    },
  };
});

export const graphicCardTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    horizontal,
    vertical,
  },
});
