import { Schemas } from 'types/leap-api';
import { filterAdaptersByService } from 'utils/filter-adapters-by-service';

export const getMostRecentlyConnectedShopify = (
  adapters: Array<Schemas['AdapterConfigResponse']>
): Schemas['AdapterConfigResponse'] | undefined => {
  const shopifys = filterAdaptersByService(adapters, 'shopify');
  const connectedShopifys = shopifys.filter(
    (shopify) => shopify.state?.connected
  );
  const mostRecentlyConnectedShopify = connectedShopifys?.sort(
    (a, b) =>
      Date.parse(b.created_at as string) - Date.parse(a.created_at as string)
  );
  return mostRecentlyConnectedShopify?.[0];
};
