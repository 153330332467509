export const styleTokens = {
  baseInputControl: {
    alignSelf: 'flex-start',
    backgroundColor: 'base.white',
    border: '.125rem solid',
    borderColor: 'icon.primaryDark',
  },
  baseInputLabel: {
    color: 'text.secondary',
    lineHeight: 'none',
    _disabled: {
      color: 'text.disabled',
      opacity: 1,
    },
  },
  defaultBorder: {
    border: '1px solid',
    borderColor: 'neutral.300',
  },
  disabledInput: {
    bg: 'neutral.200',
    color: 'text.disabled',
    opacity: 1,
  },
  disabledInputControl: {
    bg: 'base.white',
    borderColor: 'icon.disabled',
    opacity: 1,
  },
  fieldSizeLarge: {
    borderRadius: 'lg',
    height: '10',
    px: '2',
    py: '3',
  },
  fieldSizeSmall: {
    borderRadius: 'lg',
    height: '8',
    px: '2',
    py: '2',
  },
  focusVisible: {
    outlineColor: 'primary.300',
    outlineStyle: 'solid',
    outlineWidth: '.125rem',
    outlineOffset: '-.125rem',
  },
  hoverInputControl: {
    bg: 'primary.100',
    borderColor: 'primary.600',
  },
  invalidBorder: {
    border: '1px solid',
    borderColor: 'error.600 !important', // adding important to address a regression introduced by Chakra 2.5.1 where this value gets overridden with `initial` in the Input theme only
  },
  placeholder: {
    color: 'text.secondary',
    opacity: 1,
  },
};
