import { denyList } from './deny-list';
import { storageKey } from './storage-key';
import { storeData } from './store-data';
import { useEffect } from 'react';
import { useStateValue } from './stateProvider';

export function StateSync() {
  const [state] = useStateValue();

  useEffect(() => {
    storeData(storageKey, state, denyList);
  }, [state]);

  return null;
}
