import { RGBAToRGB } from './utils';
import { Theme } from '@emotion/react';
import { ThemeColorsFunction, ThemePalette } from '../../types/styled';
import { baseTheme } from './baseTheme';

const LeapLightColors: ThemeColorsFunction = (key, alpha = 1, flatten) => {
  const palette: ThemePalette = {
    brand: `rgba(041,046,061, ${alpha})`, //rgb(41,46,61)
    background: `rgba(252,252,253, ${alpha})`, //rgb(252,252,253)
    chrome: `rgba(255,255,255, ${alpha})`, //rgb(255,255,255)
    foreground: `rgba(041,046,061, ${alpha})`, //rgb(41,46,61)
    link: `rgba(68,76,231, ${alpha})`, //rgb(68,76,231)
    accent: `rgba(97,114,243, ${alpha})`, //rgb(97,114,243)
    highlight: `rgba(68,76,231, ${alpha})`, //rgb(68,76,231)
    success: `rgba(18,183,106, ${alpha})`, //rgb(18,183,106)
    warning: `rgba(247,144,009, ${alpha})`, //rgb(247,144,009)
    danger: `rgba(240,068,056, ${alpha})`, //rgb(255,120,117)
    text: `rgba(029,036,051, ${alpha})`, //rgb(029,036,051)
    black: `rgba(000,000,000, ${alpha})`, //rgb(0,0,0)
    shadow: `rgba(029,036,051, ${alpha})`, //rgb(029,036,051)
    darkGray: `rgba(071,085,103,${alpha})`, //rgba(071,085,103, 1)
    dividerGray: `rgba(201,213, 221, ${alpha})`, //rgb(201,213,221)
    helperText: `rgba(052,064,084, ${0.55})`, //rgb(52,64,84)`
  };
  const color = (key && palette[key]) ?? palette.foreground;
  return flatten ? RGBAToRGB(color, [255, 255, 255]) : color;
};

export const leapLightTheme: Theme = {
  breakpoint: baseTheme.breakpoint,
  button: {
    main: {
      background: {
        default: LeapLightColors('link'),
        disabled: LeapLightColors('foreground', 0.15),
      },
      border: {
        default: 'transparent',
        disabled: LeapLightColors('foreground', 0.25),
      },
      text: {
        default: LeapLightColors('background'),
        disabled: LeapLightColors('foreground', 0.25),
      },
      hover: LeapLightColors('link', 0.5),
      radius: '0.5rem',
    },
  },
  colorsDeprecated: (key, alpha = 1, flatten) =>
    LeapLightColors(key, alpha, flatten),
  defaults: (defaultKey) => {
    const attributes = {
      radius: '2px',
      padding: '0.5rem',
      margin: '0.5rem',
      height: '2',
      shadow: `box-shadow: 5px 5px 10px ${LeapLightColors(
        'shadow'
      )}, -2px -2px 10px ${LeapLightColors('foreground', 0.05)};`,
    };
    return attributes[defaultKey];
  },
  font: baseTheme.font,
  shade: baseTheme.shade,
  title: baseTheme.title,
};
