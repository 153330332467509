import { Schemas } from 'types/leap-api';
import { useStateValue } from 'providers';

export type UserRoles = Schemas['UserRole'][];

/**
 * Returns the full `roles` array from the User object.
 * Users may be assigned multiple roles. It is up to the consumer to determine
 * which role to use.
 * @returns {{ isRetailStoreAssocicate, UserRoles[] }}
 */
export const useUserRoles = (): {
  isRetailStoreAssociate: boolean;
  userRoles: UserRoles;
} => {
  const [
    {
      session: { roles },
    },
  ] = useStateValue();

  const userRoles: UserRoles = roles ? roles : [];

  return {
    isRetailStoreAssociate:
      userRoles.length === 1 && userRoles[0] === 'Retail: Store Associate',
    userRoles,
  };
};
