import { Button, Flex, Heading, Link, Loading, Text, VStack } from 'bam';
import { LinkExternal } from 'bam/icons';
import { ShopifyLogo } from 'bam/illustrations';
import { useBrandStatus } from 'hooks/use-brand-status';
import { useEffect } from 'react';
import { useGetBrand } from 'hooks/api/brand/use-get-brand';
import { useGetBrandAdapterShopifyAuthorize } from 'hooks/api/brand/adapter/use-get-brand-adapter-shopify-authorize';
import { useGetBrandAdapterShopifyAuthorizeFull } from 'hooks/api/brand/adapter/use-get-brand-adapter-shopify-authorize-full';
import { useGetBrandAdapterShopifyInstall } from 'hooks/api/brand/adapter/use-get-brand-adapter-shopify-install';
import { useSearchParams } from 'react-router-dom';
import { useTrack } from 'analytics/use-track';
import { useTranslation } from 'react-i18next';

interface ConnectShopifyAdapterProps {
  /**
   * hide buttons and disable input
   * @default false
   */
  viewOnly?: boolean;
}

export const ConnectShopifyAdapter: React.FC<ConnectShopifyAdapterProps> = ({
  viewOnly = false,
}) => {
  const [searchParams] = useSearchParams();
  const { error: connectError, mutateAsync: mutateAsyncConnect } =
    useGetBrandAdapterShopifyAuthorize();
  const { error: upgradeError, mutateAsync: mutateAsyncUpgrade } =
    useGetBrandAdapterShopifyAuthorizeFull();
  const {
    mutateAsync: getShopifyInstall,
    isLoading,
    isSuccess,
  } = useGetBrandAdapterShopifyInstall();
  const { connectedShopify, isShopifyConnected, shopifyConnectionType } =
    useBrandStatus();
  const { data, refetch } = useGetBrand();
  const { track } = useTrack();
  const { t } = useTranslation('translation');

  let notConnected =
    (!connectedShopify && data && data.adapters.length > 0) || false;

  let connectionTypeLabel: string | undefined;
  switch (shopifyConnectionType) {
    case 'ro':
      connectionTypeLabel = `${t('connectShopifyAdapter.readOnly')}`;
      break;
    case 'full':
      connectionTypeLabel = `${t('connectShopifyAdapter.readWrite')}`;
      break;
    case 'n/a':
    default:
      connectionTypeLabel = undefined;
  }

  const handleShopifyInstall = async (shop) => {
    getShopifyInstall(shop);
    return shop;
  };

  const handleReconnectButtonClick = async () => {
    track({
      action: 'ConnectRequest',
      data: 'read only',
      subject: 'ShopifyAdapter',
    });
    await mutateAsyncConnect(data ? data.adapters[0].instance_id : '');
    track({
      action: 'ConnectSuccess',
      data: 'read only',
      subject: 'ShopifyAdapter',
    });
    if (connectError) {
      notConnected = true;
      track({
        action: 'ConnectFailure',
        data: 'read only',
        subject: 'ShopifyAdapter',
      });
    }
  };

  const handleUpgradeButtonClick = async () => {
    track({
      action: 'ConnectRequest',
      data: 'read/write',
      subject: 'ShopifyAdapter',
    });
    await mutateAsyncUpgrade(
      connectedShopify ? connectedShopify?.instance_id : ''
    );
    track({
      action: 'ConnectSuccess',
      data: 'read/write',
      subject: 'ShopifyAdapter',
    });
    if (upgradeError) {
      track({
        action: 'ConnectFailure',
        data: 'read/write',
        subject: 'ShopifyAdapter',
      });
      notConnected = true;
    }
  };
  /* eslint-disable */
  useEffect(() => {
    const adapter = data?.adapters?.find(
      (a) => a.instance_id === searchParams.get('shop')?.split('.')[0] && a.sink
    );
    const automateSubmission =
      data?.adapters &&
      searchParams.has('shop') &&
      data &&
      !isLoading &&
      !adapter;
    if (automateSubmission) {
      handleShopifyInstall(searchParams.get('shop'));
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [isSuccess]);
  /* eslint-enable */

  return (
    <>
      <Flex justify="center" mb={2}>
        <ShopifyLogo />
      </Flex>

      {isShopifyConnected || notConnected ? (
        <>
          <Heading
            color={notConnected ? 'error.600' : 'success.600'}
            size="sm"
            textAlign="center"
            variant="regular"
          >
            {notConnected
              ? `${t('connectShopifyAdapter.failureAlert')}`
              : `${t(
                  'connectShopifyAdapter.successAlert'
                )} ${connectionTypeLabel}`}
          </Heading>
          <Text
            color="text.secondary"
            mb={6}
            textAlign="center"
            textStyle="labelMediumRegular"
          >
            {t('connectShopifyAdapter.adapterUrl', {
              shopifyInstanceId: connectedShopify
                ? connectedShopify?.instance_id
                : data?.adapters[0].instance_id,
            })}
          </Text>
        </>
      ) : null}

      {/* connect adapter */}
      {!isShopifyConnected && !notConnected && !searchParams.get('shop') ? (
        <VStack spacing={6}>
          {!viewOnly ? (
            <Link href={process.env.SHOPIFY_INSTALL_URL}>
              <Button type="submit">
                {t('connectShopifyAdapter.reconnectButtonLabel')}
              </Button>
            </Link>
          ) : null}
        </VStack>
      ) : null}
      {!isShopifyConnected && !notConnected && searchParams.get('shop') ? (
        <Loading loading={true} />
      ) : null}

      {/* upgrade adapter  */}
      {isShopifyConnected && shopifyConnectionType === 'ro' && !notConnected ? (
        <VStack gap={3} justify="center">
          <Text
            color="text.secondary"
            textAlign="center"
            textStyle="labelXsmallRegular"
          >
            {t('connectShopifyAdapter.readOnlyHelpText')}
          </Text>

          <Button
            disabled={viewOnly}
            onClick={handleUpgradeButtonClick}
            rightIcon={<LinkExternal />}
            variant="secondary"
          >
            {t('connectShopifyAdapter.upgradeButtonLabel')}
          </Button>
        </VStack>
      ) : null}

      {/* reconnect adapter */}
      {notConnected ? (
        <VStack gap={3} justify="center">
          <Button
            disabled={viewOnly}
            onClick={handleReconnectButtonClick}
            rightIcon={<LinkExternal />}
            variant="secondary"
          >
            {t('connectShopifyAdapter.reconnectButtonLabel')}
          </Button>
        </VStack>
      ) : null}
    </>
  );
};
