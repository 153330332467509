import { Icon, IconProps } from '@chakra-ui/react';

/**
 * Simplest form of the Leap Logo. This is the Leap "L" dropped out from solid square.
 * */
export const LeapLogoIcon = (props: IconProps) => (
  <Icon
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 0H0V32H32V18.0645C32 18.0645 26.3055 20.1109 22.0903 22.8129C18.4219 25.1645 13.1638 29.7559 11.8616 30.9074C11.78 31.0034 11.6851 31.0877 11.5799 31.1576L11.5613 31.1742L11.5618 31.1694C11.3531 31.3031 11.105 31.3806 10.8387 31.3806C10.0976 31.3806 9.49677 30.7798 9.49677 30.0387V1.96129C9.49677 1.22016 10.0976 0.619355 10.8387 0.619355C11.5798 0.619355 12.1806 1.22016 12.1806 1.96129V26.7384C12.9236 26.0226 15.6832 23.4986 20.6452 20.4387C26.529 16.8103 32 15.2774 32 15.2774V0Z"
      fill="currentColor"
    />
  </Icon>
);
