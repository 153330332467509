import { categories } from './';
if (process.env.INTERCOM_APP_ID === 'false' || !window.Intercom) {
  window.Intercom = () => {};
}

export const intercomEvent = ({ category, type, label, status }) => {
  const params = {
    category: category || categories(type),
    action: type,
    label: label,
  };
  if (status) {
    params.state = status;
  }
  window.Intercom('trackEvent', type, params);
  return null;
};
