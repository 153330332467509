import { AddBrandUserDesktop, AddBrandUserMobile } from './add-brand-user';
import {
  Box,
  DesktopView,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MobileView,
} from 'bam';
import { Can } from 'providers';
import { LeapLogoIcon } from 'bam/logos';
import { Lightning02, LogOut01, User02, UserCircle } from 'bam/icons';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserRoleGrouping } from 'hooks/use-user-role-grouping';
import React from 'react';

export const AppTopNavigation: React.FC = () => {
  const userRoleType = useUserRoleGrouping();
  const { t } = useTranslation('primary-template');

  return (
    <>
      <LeapLogoIcon
        display={{ md: 'none' }}
        boxSize="8"
        justifySelf="center"
        color="primary.600"
      />

      <Box display="flex" ml="auto" gap="4" color="text.primary">
        {userRoleType === 'BRAND' ? (
          <Can I="post" a="brand:users">
            <DesktopView>
              <AddBrandUserDesktop />
            </DesktopView>
          </Can>
        ) : null}

        <Menu>
          <MenuButton
            as={IconButton}
            variant="secondary"
            iconBoxSize="8"
            size="sm"
            icon={<UserCircle color="icon.primaryDark" />}
            display="flex"
            backgroundColor="base.white"
            _hover={{
              backgroundColor: 'base.white',
            }}
            _active={{
              backgroundColor: 'base.white',
            }}
            height="8"
            width="8"
            minWidth="auto"
            borderRadius="50%"
            borderWidth="2px"
            borderColor="transparent"
            aria-label="Toggle user menu"
          />

          <MenuList>
            <MenuItem
              icon={<User02 boxSize="5" />}
              as={ReactRouterLink}
              to={`/${userRoleType.toLowerCase()}/account`}
            >
              {t('userMenu.account')}
            </MenuItem>

            {userRoleType === 'BRAND' ? (
              <>
                <MenuItem
                  icon={<Lightning02 boxSize="5" />}
                  as={ReactRouterLink}
                  to="/brand/profile"
                >
                  {t('userMenu.brandProfile')}
                </MenuItem>

                <Can I="post" a="brand:users">
                  <MobileView>
                    <AddBrandUserMobile />
                  </MobileView>
                </Can>
              </>
            ) : null}

            <MenuDivider />

            <MenuItem
              icon={<LogOut01 color="icon.error" boxSize="5" />}
              color="error.500"
              as={ReactRouterLink}
              to="/logout"
            >
              <Box as="span" color="error.500">
                {t('userMenu.logout')}
              </Box>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </>
  );
};
