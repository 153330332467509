const Auth = (state, action) => {
  switch (action.type) {
    case 'AUTH_INIT':
      return {
        ...state,
        ...action.data,
        isLoading: false,
        loggedIn: action.loggedIn,
      };
    default:
      return state;
  }
};

export default Auth;
