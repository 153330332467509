import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { types } from 'actions';
import { useEffect } from 'react';
import { useStateValue } from 'providers';

export const AuthenticatedRoute = () => {
  const [{ session }, dispatch] = useStateValue();

  const location = useLocation();

  useEffect(() => {
    if (session.loggedIn && session.redirect) {
      dispatch({
        category: 'AUTH',
        type: types.CLEAR_REDIRECT_PATH,
      });
    }

    if (session.logOut) {
      dispatch({
        category: 'AUTH',
        type: types.SET_REDIRECT_PATH,
        data: window.location.href.replace(window.location.origin, ''),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, session.loggedIn, session.redirect, session.logOut]);

  if (session.loggedIn && session.redirect) {
    return <Navigate to={session.redirect} state={{ from: location }} />;
  }

  if (session.logOut) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
};
